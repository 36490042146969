import React from "react"
import { Outlet } from "react-router-dom"
import VerticalLayout from "components/VerticalLayout/"
import { withTranslation } from "react-i18next"

import { useSelector } from "react-redux"
import { selectCurrentUser } from "store/auth/login/loginSlice"

const TeacherMiddleware = (props) => {
  const user = useSelector(selectCurrentUser)
  const {t} =props
  
   return (
     <>   
       <VerticalLayout>
         <Outlet context={{ t ,user}} />
       </VerticalLayout>
     </>
   )
}


export default withTranslation()(TeacherMiddleware);