import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { readGrades } from "store/store-admin/grades/gradesSlice"
import { useSelector, useDispatch } from "react-redux"
const AdminDashboard = props => {
  const dispatch=useDispatch();
  //meta title
  document.title = "Dashboard | CCMB Tareas"
useEffect(() => {
  
dispatch(readGrades());
}, [])



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          Admin
<button onClick={()=>dispatch(readGrades()) }>
Get grade test
</button>
        </Container>
      </div>
    </React.Fragment>
  )
}

AdminDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(AdminDashboard)
