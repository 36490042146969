import { createSlice } from "@reduxjs/toolkit"


const schoolGradeSlice = createSlice({
  name: "coordinator/schoolGrade",
  initialState: {
    dataTable: null,
    filterby: "",
    isLoading: false,
    selectedRow: null,
    isEditing: false,
    isSaveConfirmationModal: false,
    unsavedData: null,
    visibleColumns: [],

    selectedGrade: null,
    gradeOptions: [],
    subjectOptions: [],
    selectedSubject: null,
    subjects: [],
    subject: null,
  },
  reducers: {
    onReadData: state => {
      state.isLoading = true
    },
    onReadDataSuccess: (state, action) => {
      state.dataTable = action.payload
      state.visibleColumns = action.payload.columns.map(column => column.id)
      state.isLoading = false
      state.isEditing = false
    },
    onFilterChange: (state, action) => {
      state.filterby = action.payload
    },
    onEditStart: (state, action) => {
      state.selectedRow = action.payload
    },
    onEdit: (state, action) => {
      state.isEditing = true
    },
    onSaveConfirmationModalStart: (state, action) => {
      state.unsavedData = action.payload
      state.isSaveConfirmationModal = true
    },
    onSaveConfirmationCancel: (state, action) => {
      ;(state.unsavedData = null), (state.isSaveConfirmationModal = false)
    },
    onToggleColumnVisibility: (state, action) => {
      const { columnId } = action.payload
      if (state.visibleColumns.includes(columnId)) {
        // Column is currently visible, hide it
        state.visibleColumns = state.visibleColumns.filter(
          id => id !== columnId
        )
      } else {
        // Column is currently hidden, show it
        state.visibleColumns = [...state.visibleColumns, columnId]
      }
    },
    setSelectedGrade: (state, action) => {
      state.selectedSubject = null
      state.subjectOptions=[]
      state.selectedGrade = action.payload
    },
    setSelectedSubject: (state, action) => {
      state.selectedSubject = action.payload.option
      state.subject = action.payload.subject
    },
    setSubjectOptions: (state, action) => {
      state.subjectOptions = action.payload
    },
    onReadSubjects: (state, action) => {},
    onReadSubjectsSuccess: (state, action) => {
      state.subjects = action.payload.subjects
      state.gradeOptions = action.payload.gradeOptions
    },
  },
})



export const {
  onReadData,
  onReadDataSuccess,
  onFilterChange,
  onEditStart,
  onEdit,
  onSaveConfirmationModalStart,
  onSaveConfirmationCancel,
  onToggleColumnVisibility,
  setSelectedGrade,
  setSubjectOptions,
  setSelectedSubject,
  onReadSubjects,
  onReadSubjectsSuccess,
} = schoolGradeSlice.actions
export default schoolGradeSlice.reducer



