
import React from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap"
import Select from 'react-select'
import FormFieldError from "components/Common/FormFieldError"



const PlanningForm = (props) => {

const {
  state,
  t,
  handleFieldChangePlanForm,
  handleSavePlanning,
  handleCancelPlanning,
} = props


const { indicatorOptions, laesOptions, fieldsPlannings } = state

	// shool_year_id Índice	
	// 3	name	
	// 4	indicator_id Índice	
	// 5	begins	
	// 6	modeling	
	// 7	group	
	// 8	close		
	// 9	resources		
	// 10	date_apply



    return (
      <div>
        <Row>
          <Col>
            <Form>
              <FormGroup>
                <Label for="textarea1">{t("Beginning/Warming up")}</Label>
                <Input
                  type="textarea"
                  name="textarea1"
                  id="textarea1"
                  rows={4}
                  value={fieldsPlannings?.begins?.value}
                  onChange={e =>
                    handleFieldChangePlanForm("begins", e.target.value)
                  }
                />
                <FormFieldError
                  errors={fieldsPlannings?.begins?.errors}
                  t={t}
                />
              </FormGroup>
              <FormGroup>
                <Label for="textarea2"> {t("Modeling/Development")}</Label>
                <Input
                  type="textarea"
                  name="textarea2"
                  id="textarea2"
                  rows={4}
                  value={fieldsPlannings?.modeling?.value}
                  onChange={e =>
                    handleFieldChangePlanForm("modeling", e.target.value)
                  }
                />
                <FormFieldError
                  errors={fieldsPlannings?.modeling?.errors}
                  t={t}
                />
              </FormGroup>
              <FormGroup>
                <Label for="textarea3">{t("Group Activity")}</Label>
                <Input
                  type="textarea"
                  name="textarea3"
                  id="textarea3"
                  rows={4}
                  value={fieldsPlannings?.group?.value}
                  onChange={e =>
                    handleFieldChangePlanForm("group", e.target.value)
                  }
                />
                <FormFieldError errors={fieldsPlannings?.group?.errors} t={t} />
              </FormGroup>
              <FormGroup>
                <Label for="textarea4">{t("Resources")}</Label>
                <Input
                  type="textarea"
                  name="textarea4"
                  id="textarea4"
                  rows={4}
                  value={fieldsPlannings?.resources?.value}
                  onChange={e =>
                    handleFieldChangePlanForm("resources", e.target.value)
                  }
                />
                <FormFieldError
                  errors={fieldsPlannings?.resources?.errors}
                  t={t}
                />
              </FormGroup>
            </Form>
          </Col>
          <Col>
            <Form>
              <FormGroup>
                <Label for="textarea5">{t("Closure")}</Label>
                <Input
                  type="textarea"
                  name="textarea5"
                  id="textarea5"
                  rows={4}
                    value={fieldsPlannings?.close?.value}
                    onChange={e =>
                        handleFieldChangePlanForm("close", e.target.value)
                        }
                />
                <FormFieldError errors={fieldsPlannings?.close?.errors} t={t} />
              </FormGroup>
              <FormGroup>
                <FormGroup>
                  <Label htmlFor="activity_id">{t("Indicators")}</Label>

                  <Select
                    name="week"
                    className="form-controll"
                    options={indicatorOptions}
                    value={fieldsPlannings?.indicator_id?.value}
                    onChange={option => {
                      handleFieldChangePlanForm("indicator_id", option)
                      //   handleFieldChange("biblical_integration", "")
                      //   handleFecthPlanningWeeks(option.value)
                    }}
                  />
                  <FormFieldError
                    errors={fieldsPlannings?.indicator_id?.errors}
                    t={t}
                  />
                </FormGroup>
              </FormGroup>
              <FormGroup>
                <FormGroup>
                  <Label htmlFor="activity_id">{t("LAES")}</Label>

                  <Select
                    name="week"
                    className="form-controll"
                    options={laesOptions}
                    value={fieldsPlannings?.laes_id?.value}
                    onChange={option => {
                      handleFieldChangePlanForm("laes_id", option)
                      //   handleFieldChange("biblical_integration", "")
                      //   handleFecthPlanningWeeks(option.value)
                    }}
                  />
                  <FormFieldError
                    errors={fieldsPlannings?.laes_id?.errors}
                    t={t}
                  />
                </FormGroup>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <div className="text-center mt-4 ">
          <Button
            color="primary"
            className="mx-2"
            onClick={() => {
              handleSavePlanning()
            }}
          >
            {t("Save")}
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              handleCancelPlanning()
            }}
          >
            {t("Cancel")}
          </Button>
        </div>
      </div>
    )
}

export default PlanningForm 