import React from 'react'
import { Outlet } from 'react-router-dom'
import VerticalLayout from "components/VerticalLayout/"
const AdminMiddleware = () => {
  return (
    <>
     
        <VerticalLayout>
            <Outlet/>
        </VerticalLayout>
    
    </>
  )
}

export default AdminMiddleware