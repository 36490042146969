import React from 'react'
import { Row, Col, Card, CardBody, FormGroup, Label } from "reactstrap"
import Select from "react-select"
import { selectStyles } from "common/Constants"


const FilterSubjectByGrade = (props) => {

    const {
      selectedGrade,
      handleSelectGrades,
      gradeOptions,
      handleSelectSubjecs,
      subjectOptions,
      selectedSubject,
      t,
    } = props
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-2">
              <Row>
                <Col lg="6">
                  <FormGroup className="select2-container">
                    <Label>{t("Grades")}</Label>
                    <Select
                      styles={selectStyles}
                      maxMenuHeight={220}
                      value={selectedGrade}
                      onChange={handleSelectGrades}
                      options={gradeOptions}
                      className="form-controll"
                      classNamePrefix="select"
                      placeholder={"Selecciona un grado"}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="select2-container">
                    <Label>{t("Subjects")}</Label>
                    <Select
                      //loadingMessage={() => "Cargando..."}
                      //isLoading={loading}
                      styles={selectStyles}
                      maxMenuHeight={220}
                      value={selectedSubject}
                      onChange={handleSelectSubjecs}
                      options={subjectOptions}
                      className="form-controll"
                      classNamePrefix="select"
                      placeholder={"Debes seleccionar un grado primero"}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default FilterSubjectByGrade