import { createSlice } from "@reduxjs/toolkit"
import { Fields } from "./fieldSchemaView"

const assignmentViewSlice = createSlice({
  name: "student/activity/assignmentView",
  initialState: {
    isLoading: false,
    assignment: null,
    submissions: [],
    fields: { ...Fields },
    isSubmitting: false,
    showSubmission: false,
    selectedSubmission: null,
  },
  reducers: {
    onCreateStart: state => {
      state.isModal = !state.isModal
      state.mode = "create"
    },

    onCreateCancel: state => {
      state.isModal = !state.isModal
      state.mode = null
    },

    onReadData: state => {
      state.isLoading = true
      //reset state
       state.isSubmitting= false,
       state.showSubmission= false,
       state.selectedSubmission= null
    },
    onReadDataSuccess: (state, action) => {
      state.isLoading = false
      state.assignment = action.payload.assignment

      state.submissions = action.payload.submissions
    },
    onFieldChange: (state, action) => {
      const { field, value } = action.payload
      state.fields[field].value = value
      state.fields[field].errors = []
    },
    onSubmit: state => {
      state.isSubmitting = true
    },
    onSubmitSuccess: state => {
      state.isSubmitting = false
      state.fields = { ...Fields }
    },
    onReadSubmissionSuccess: (state, action) => {
      state.submissions = action.payload.submissions
    },
    setValidationErrors: (state, action) => {
      state.fields = { ...action.payload.fieldErrors }
       state.isSubmitting = false

    },
    onSelectSubmission: (state, action) => {
      state.showSubmission = true
      state.selectedSubmission = action.payload
    },
  },
})

export const {
  onCreateStart,
  onCreateCancel,
  onReadData,
  onReadDataSuccess,
  onFieldChange,
  onSubmit,
  setValidationErrors,
  onSubmitSuccess,
  onReadSubmissionSuccess,
  onSelectSubmission,
} = assignmentViewSlice.actions
export default assignmentViewSlice.reducer
