import PropTypes from "prop-types"
import React, { useEffect } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import {
  onResetPassword,
  getEmailRequest,
} from "../../store/auth/resetpwd/resetPassowrdSlice"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"
import ErrorMessage from "components/Common/ErrorMessage"

const ResetPassword = props => {
    const { token } = useParams()
  //meta title
  document.title = "Reset Password | CCMB Tareas"

  const dispatch = useDispatch()

   const selecResetPasswordState = state => state.ResetPassword
   const ResetPasswordProperties = createSelector(
     selecResetPasswordState,
     resetPassword => ({
       error: resetPassword.error,
       email: resetPassword.email,
       resetPwdSuccessMsg: resetPassword.resetPwdSuccessMsg,
       isRedirecting: resetPassword.resetPwdSuccessMsg,
       seconds: resetPassword.seconds,
     })
   )

   const { error, email, resetPwdSuccessMsg, isRedirecting, seconds } =
     useSelector(ResetPasswordProperties)
   useEffect(() => {
    const data = {
      token: token,
      navigate: props.router.navigate,
    }
     dispatch(getEmailRequest(data))
   }, [dispatch, token])

const validation = useFormik({
  enableReinitialize: true,

  initialValues: {
    password: "",
    password_confirmation: "",
    token: token,
    email: email
  },
  validationSchema: Yup.object({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Please Enter Your Password"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm your password"),
  }),
  onSubmit: values => {
     const data = {
       user: values,
       navigate: props.router.navigate,
     }
    //  password_confirmation
     dispatch(onResetPassword(data))
    // You can make an API call to reset the password here
  },
})
 


  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4"></div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <div>
                      <p>{email}</p>
                    </div>

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error && (
                        <Alert color="danger">
                          <ErrorMessage error={error} />
                        </Alert>
                      )}

                      {resetPwdSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {resetPwdSuccessMsg}
                        </Alert>
                      ) : null}

                      {email ? (
                        <div>
                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              className="form-control"
                              placeholder="Enter Password"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">
                              Confirm Password
                            </Label>
                            <Input
                              name="password_confirmation"
                              className="form-control"
                              placeholder="Confirm Password"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.password_confirmation || ""
                              }
                              invalid={
                                validation.touched.password_confirmation &&
                                validation.errors.password_confirmation
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password_confirmation &&
                            validation.errors.password_confirmation ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password_confirmation}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mt-3 d-grid">
                            {isRedirecting ?? (
                              <>
                               
                                <button
                                  className="btn btn-primary btn-block"
                                  type="submit"
                                >
                                  Reset Password
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <Spinner
                          style={{ width: "0.7rem", height: "0.7rem" }}
                          type="grow"
                          color="light"
                        />
                      )}
                    </Form>
                {isRedirecting && (
                  <p>You'll be redirected in {seconds} seconds.</p>
                )}
                  </div>
                </CardBody>
             
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} MC Digital Solutions </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ResetPassword)

ResetPassword.propTypes = {
  history: PropTypes.object,
}
