import React  from "react"
import CKeditorReadOnly from 'components/Common/CKeditor/CKeditorReadOnly'
import { useOutletContext } from "react-router-dom"

const Submission = () => {
//   const { selectedSubmission } = props.state

const { state } = useOutletContext()

  const createMarkup = () => {
      return { __html: state.selectedSubmission?.text_entry_student }
  }
    

    return (
    <div style={{ wordWrap: "break-word", display: "inline-block", border: "1px solid #ccc", padding: "10px", borderRadius: "5px", backgroundColor: "#f5f5f5" }}>
        <div
            dangerouslySetInnerHTML={createMarkup()}
            className="ck-content"
        ></div>
    </div>
    )
}

export default Submission
