import AdminMiddleware from 'middleware/AdminMiddleware'
import AdminDashboard from 'pages/AdminPages/AdminDashboard/AdminDashboard'
// import React from 'react'
import { Route, Routes } from "react-router-dom"


// const AdminRoutes = () => {
//   return (
//     <React.Fragment>
//        <Routes>
//           <Route path="/dashboard" element={<AdminDashboard />} />
//        </Routes>
    
//     </React.Fragment>
//   )
  
// }

// export default AdminRoutes

export default [
  <Route element={<AdminMiddleware />}>
    <Route path="/dashboard" element={<AdminDashboard />} />
  </Route>

]