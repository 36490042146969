import { createSlice } from "@reduxjs/toolkit"

const academicStandarSlice = createSlice({
  name: "teacher/academicStandar",
  initialState: {
    isModal: false,
    mode: null,
    isModalDelete: false,
    currentActiveTab: "1",
    isLoading: false,
    subjects: [],
    subjectsOptions: [],
  },
  reducers: {
    onCreateStart: state => {
      state.isModal = !state.isModal
      state.mode = "create"
    },

    onCreateCancel: state => {
      state.isModal = !state.isModal
      state.mode = null
    },
    onReadData: state => {
      state.isLoading = true
    },
    onReadDataSuccess: (state, action) => {
      state.isLoading = false
      state.subjects = action.payload.subjects
      state.subjectsOptions = action.payload.options
    },
    setCurrentActiveTab: (state, action) => {
      state.currentActiveTab = action.payload
    },
  },
})

export const {
  onCreateStart,
  onCreateCancel,
  setCurrentActiveTab,
  onReadData,
  onReadDataSuccess,
} = academicStandarSlice.actions
export default academicStandarSlice.reducer
