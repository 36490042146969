import React, { useState } from "react"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document"
import { MyUploadAdapter } from "helpers/file_helper"

function MyCKEditor({ editorData, handleEditorChange }) {
  const [editorInstance, setEditorInstance] = useState(null)

  return (
    <>
      <CKEditor
        onReady={editor => {
          editor.ui
            .getEditableElement()
            .parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.getEditableElement()
            )
          editor.plugins.get("FileRepository").createUploadAdapter = loader => {
            // Initialize the custom upload adapter with your custom logic
            return new MyUploadAdapter(loader)
          }
          setEditorInstance(editor) // Store the editor instance using useState
        }}
        onError={(error, { willEditorRestart }) => {
          if (willEditorRestart) {
            editorInstance.ui.view.toolbar.element.remove()
          }
        }}
        onChange={(e, editor) => {
          const rawData = editor.getData()
          handleEditorChange(rawData)
        }}
        editor={DecoupledEditor}
        data={editorData}
      />
    </>
  )
}

export default MyCKEditor
