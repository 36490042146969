import { takeLatest, call, put } from "redux-saga/effects"
import {
  onCreate,
  onCreateSuccess,
  onRead,
  onReadSuccess,
  setFieldError,
} from "./privilegeRemovalSlice"


import {formatFieldValues} from "helpers/form_helper"
import { toast } from "react-toastify"
import { apiClient } from "api/axios"
import {privilegeRemovalEndpoints, subjectEndpoints} from "api/endpoints"

function*  workerCreate({payload:{fields,t,subject}}) {
    try {
       
        
        const valuesToSend = formatFieldValues(fields)
        valuesToSend.teacher_id = subject.teacher_id
        valuesToSend.subject_id = subject.id
        const response = yield call(apiClient.post, privilegeRemovalEndpoints.create, valuesToSend)
        const {data} = response
        data.reasons = data.reasons.join(", ");
        yield put(onCreateSuccess(response.data))
        toast.success(t("Created successfully"))
    } catch (error) {
       const { status, data } = error?.response
      const messages = data?.messages
      if (status === 400) {
        for (const fieldKey in messages) {
          if (messages.hasOwnProperty(fieldKey)) {
            yield put(setFieldError({ fieldKey, errors: [messages[fieldKey]] }))
          }
        }
      }
    }
    }

function* workerRead({payload:{subject}}){

    try {
        const response = yield call(apiClient.get, subjectEndpoints.privilege_removals(subject.id))
        const {data} = response
       
        yield put(onReadSuccess(data))
    } catch (error) {
        console.log(error)
    }
}

export function* watchPrivilegeRemoval() {
  yield takeLatest(onCreate.type, workerCreate)
    yield takeLatest(onRead.type, workerRead)
}

export default watchPrivilegeRemoval
