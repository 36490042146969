import React from 'react'
import TableHeader from './gradebookTable/TableHeader'
import TableData from './gradebookTable/TableData'
import Filter from './gradebookTable/Filter'
import { Col, Row,FormGroup, Label } from 'reactstrap'

import Select from "react-select"


import "./FixedColumnTable.css"
const GradeBook = (props) => {
  const {
    handleDropdownHeader,
    handleRowUpdate,
    handleSelectedRow,
    handleConfimationModalStart,

    handleFilter,
    handleToggleColumnVisibility,
    schoolGradeState,
    t,
    editable,

   SubjectTeacher,
    handlePeriodChange,
  } = props

  const {
    isEditing,
    selectedRow,
    filterby,
    dataTable,
    visibleColumns,
    period,
  } = schoolGradeState

  // Filter columns based on visibility
  const filteredColumns = dataTable?.columns.filter(column =>
    visibleColumns.includes(column.id)
  )

  return (
    <>
      <Row className="mb-2">
        <Col sm="4">
          <Filter
            searchTerm={filterby}
            handleOnChange={handleFilter}
            placeholder={t("Student filter")}
            t={t}
          />
        </Col>
        <Col sm="4">
          <FormGroup>
            <Label htmlFor="period">{t("Periods")}</Label>
            <Select
              name="period_id"
              // className="form-control"
              value={period}
              options={SubjectTeacher.periodsOptions}
              onChange={option => handlePeriodChange(option)}
            />
            {/* <FormFieldError errors={fields.period_id.errors} t={t} /> */}
          </FormGroup>
        </Col>
        {/* <div>
    
          {dataTable?.columns
            .filter(column => !isNaN(column.id))
            .map(column => (
              <label key={column.id}>
                <input
                  type="checkbox"
                  checked={visibleColumns.includes(column.id)}
                  onChange={() => handleToggleColumnVisibility(column.id)}
                />
                {column.title}
              </label>
            ))}
        </div> */}
      </Row>

      <div className={isEditing ? "view disabled-div" : "view"}>
        <div className="wrapper">
          <table id="gradebook_table">
            <thead>
              <tr>
                <TableHeader
                  columns={filteredColumns}
                  handleDropdownHeader={handleDropdownHeader}
                />
              </tr>
            </thead>
            <tbody>
              <TableData
                editable={editable}
                isEditing={isEditing}
                handleConfimationModalStart={handleConfimationModalStart}
                columns={filteredColumns}
                data={dataTable?.rows}
                rowUpdate={handleRowUpdate}
                filterBy={filterby}
                handleSelectedRow={handleSelectedRow}
                selectedRow={selectedRow}
              />
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default GradeBook