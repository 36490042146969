import { createSlice } from "@reduxjs/toolkit"

const attendanceSlice = createSlice({
  name: "teacher/attendance",
  initialState: {
    dataTable: null,
    filterby: "",
    isLoading: false,
    selectedRow: null,
    isEditing: false,
    students: [],

    attendanceDate: new Date(),
    isEdit: false,
    attendanceId: null,
  },
  reducers: {
    onReadData: state => {
      state.isLoading = true
    },
    onReadDataSuccess: (state, action) => {
        state.isLoading = false
        state.isEdit = action.payload.isEdit
        state.attendanceId = action.payload.attendanceId

    },
    setAttendanceDate: (state, action) => {
      state.attendanceDate = action.payload
    },
    setStudents: (state, action) => {
      state.students = action.payload

    },
    onSaveData: state => {
      state.isLoading = true
    },
    onSaveDataSuccess: (state,action) => {
      state.isLoading = false
      state.isEdit = true
      state.attendanceId= action.payload.attendanceId
    },
  },
})

export const {
  onReadData,
  onReadDataSuccess,
  setAttendanceDate,
  setStudents,
  onSaveData,
  onSaveDataSuccess,
} = attendanceSlice.actions
export default attendanceSlice.reducer
