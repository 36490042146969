import React from 'react'
import { FormGroup, Label, Button, Spinner, Input } from "reactstrap"
import Select from 'react-select'
import FormFieldError from 'components/Common/FormFieldError'


const CompetenceForm = (props) => {
const { fields, subjectsOptions, mode, benchmarkOptions } = props

const {
  handleFieldChange,
  FecthBenchmark,
  FecthCompetence,
  t,
  handleForm,
  handleReset,
  isSubmitting,
  isEditLoading,
  isBenchmarkLoading,
} = props

  return (
    <div>
      <FormGroup>
        <Label for="subject">{t("Subjects")}</Label>
        <Select
          //styles={selectStyles}
          name="selectSubject"
          className="form-controll"
          value={fields.subject_id.value}
          options={subjectsOptions}
          onChange={option => {
            handleFieldChange("subject_id", option)
            FecthBenchmark(option.value)
          }}
        />
        <FormFieldError errors={fields.subject_id.errors} t={t} />
      </FormGroup>
      <FormGroup>
        <Label for="Benchmark">{t("Benchmark")}</Label>
        <Select
          //styles={selectStyles}
          isDisabled={
            isBenchmarkLoading || !fields.subject_id.value || isEditLoading
          }
          isLoading={isBenchmarkLoading}
          name="selectBenchmark"
          className="form-controll"
          value={fields.benchmark_id.value}
          options={benchmarkOptions}
          onChange={option => {
            handleFieldChange("benchmark_id", option)
            FecthCompetence(option.value)
          }}
        />
        <FormFieldError errors={fields.benchmark_id.errors} t={t} />
      </FormGroup>
      <FormGroup>
        <Label for="Code">{t("Code")}</Label>
        <Input
          //styles={selectStyles}
          disabled={
            isBenchmarkLoading || !fields.subject_id.value || isEditLoading
          }
          name="code"
          className="form-controll"
          value={fields.code.value}
          onChange={e => {
            handleFieldChange("code", e.target.value)
          }}
        />
        <FormFieldError errors={fields.code.errors} t={t} />
      </FormGroup>
      <div
        className={
          fields.subject_id.value || isEditLoading ? "" : "disabled-div"
        }
      >
        <FormGroup>
          <Label for="name">{t("Description")}</Label>
          <textarea
            name="name"
            value={fields.name.value}
            className="form-control"
            onChange={e => handleFieldChange("name", e.target.value)}
            rows="6"
            disabled={isSubmitting || !fields.subject_id.value || isEditLoading}
          />
          <FormFieldError errors={fields.name.errors} t={t} />
        </FormGroup>
      </div>
      <div className="text-center mt-4">
        <Button
          color={mode === "create" || mode === "delete" ? "success" : "primary"}
          type="submit"
          className="mr-2 waves-effect waves-light"
          onClick={handleForm}
          disabled={isSubmitting || !fields.subject_id.value || isEditLoading}
        >
          {!isSubmitting ? (
            mode === "create" || mode === "delete" ? (
              t("Add")
            ) : (
              t("Update")
            )
          ) : (
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              {t("Sending...")}
            </>
          )}
        </Button>
        <Button
          disabled={isSubmitting || !fields.subject_id.value || isEditLoading}
          color="secondary"
          type="submit"
          className="waves-effect ms-1"
          onClick={handleReset}
        >
          {t("Cancel")}
        </Button>
      </div>
    </div>
  )
}

export default CompetenceForm