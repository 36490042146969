// loginSlice.js
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  error: "",
  loading: false,
  user:null,
  token:null,
}

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    onLoginUser: state => {
      state.loading = true
    },
    onLoginSuccess: (state, action) => {
      state.loading = false
      state.user = action.payload.LoggedUser
      state.token = action.payload.token

      state.error = ""
    },
    onLogoutUser: state => {
      state.error = ""
      state.user = null
      state.token = null
      state.loading = false
    },
    onLoginError: (state, action) => {
      state.error = action.payload
      state.loading = false
    },

    refreshTokenRequested: state => {

    },
    refreshTokenSuccess: (state, action) => {
      state.token = action.payload
    },
    refreshTokenFailure: (state, action) => {
      // Handle token refresh failure, e.g., log the user out
      state.isAuthenticated = false
      state.token = null
    },
  },
})

export const {
  onLoginUser,
  onLoginSuccess,
  onLogoutUser,
  onLoginError,
  refreshTokenRequested,
  refreshTokenSuccess,
  refreshTokenFailure,
} = loginSlice.actions

export default loginSlice.reducer

export const selectCurrentUser = state => state.login.user
export const selectCurrentToken = state => state.login.token