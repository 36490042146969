import React from "react"
import Skeleton from "react-loading-skeleton"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
} from "reactstrap"

const LoadingCard = () => {
  return (
    <Col sm={4} className="mb-4 d-flex justify-content-center">
      <Card
        style={{ width: "100%", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
      >
        <CardBody className="d-flex flex-column align-items-center">
          <div className="mb-3">
            <Skeleton circle={true} height={100} width={100} />
          </div>
          <CardText className="text-center">
            <Skeleton width={80} />
          </CardText>
          <CardText
            className="text-center"
            style={{ color: "red", fontWeight: "bold" }}
          >
            <Skeleton width={60} />
          </CardText>
        </CardBody>
      </Card>
    </Col>
  )
}

export default LoadingCard
