
import React, { useState } from "react"
import PropTypes from "prop-types"

const normalizeString = str => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
}

const DataTableSimple = ({ data, t = key => key, isLoading }) => {
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState("")

  // Paginate the data
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage

  const normalizedSearchTerm = normalizeString(searchTerm)

  const filteredData = data.rows.filter(item =>
    Object.values(item).some(
      value =>
        value &&
        typeof value === "string" &&
        normalizeString(value).includes(normalizedSearchTerm)
    )
  )

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber)

  // Handle change in items per page
  const handleItemsPerPageChange = e => {
    setItemsPerPage(parseInt(e.target.value, 10))
    setCurrentPage(1) // Reset to the first page when changing items per page
  }

  return (
    <div className="mt-4">
      {/* Search and Show entries in the same line */}
      <div className="d-flex justify-content-between mb-2">
        {/* Search input */}
        <input
          type="text"
          className="form-control"
          placeholder={t("Search") + "..."}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          style={{ width: "200px" }}
        />

        <div
          className="ms-2 d-flex align-items-center"
          style={{ maxWidth: "150px" }}
        >
          <label className="me-2">Show entries: </label>
          <select
            className="form-select"
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            style={{ width: "100px" }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            {/* Add more options as needed */}
          </select>
        </div>
      </div>

      {/* Table */}
      <table className="table">
        <thead>
          <tr>
            {data.columns.map(column => (
              <th key={column.field}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={data.columns.length} className="text-center">
                <div style={{ marginTop: "50px" }}>Loading...</div>
              </td>
            </tr>
          ) : filteredData.length === 0 ? (
            <tr>
              <td colSpan={data.columns.length} className="text-center">
                <div style={{ marginTop: "50px" }}>{t("No data found")}</div>
              </td>
            </tr>
          ) : (
            filteredData
              .map((item, index) => (
                <tr key={index}>
                  {data.columns.map(column => (
                    <td key={column.field}>{item[column.field]}</td>
                  ))}
                </tr>
              ))
              .slice(indexOfFirstItem, indexOfLastItem)
          )}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="d-flex justify-content-center">
        {Array.from({
          length: Math.ceil(filteredData.length / itemsPerPage),
        }).map((_, index) => (
          <button
            key={index}
            className="btn btn-outline-primary mx-1"
            onClick={() => paginate(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  )
}

DataTableSimple.propTypes = {
  data: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        field: PropTypes.string.isRequired,
      })
    ).isRequired,
    rows: PropTypes.array.isRequired,
  }).isRequired,
  t: PropTypes.func,
  isLoading: PropTypes.bool, // Added isLoading prop
}

export default DataTableSimple


// import React, { useState } from "react"
// import PropTypes from "prop-types"

// const normalizeString = str => {
//   return str
//     .normalize("NFD")
//     .replace(/[\u0300-\u036f]/g, "")
//     .toLowerCase()
// }

// const DataTableSimple = ({ data, t = key => key }) => {
//   const [itemsPerPage, setItemsPerPage] = useState(10)
//   const [currentPage, setCurrentPage] = useState(1)
//   const [searchTerm, setSearchTerm] = useState("")

//   // Paginate the data
//   const indexOfLastItem = currentPage * itemsPerPage
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage

//   const normalizedSearchTerm = normalizeString(searchTerm)

//   const filteredData = data.rows.filter(item =>
//     Object.values(item).some(
//       value =>
//         value &&
//         typeof value === "string" &&
//         normalizeString(value).includes(normalizedSearchTerm)
//     )
//   )

//   // Change page
//   const paginate = pageNumber => setCurrentPage(pageNumber)

//   // Handle change in items per page
//   const handleItemsPerPageChange = e => {
//     setItemsPerPage(parseInt(e.target.value, 10))
//     setCurrentPage(1) // Reset to the first page when changing items per page
//   }

//   return (
//     <div className="mt-4">
//       {/* Search and Show entries in the same line */}
//       <div className="d-flex justify-content-between mb-2">
//         {/* Search input */}
//         <input
//           type="text"
//           className="form-control"
//           placeholder={t("Search") + "..."}
//           value={searchTerm}
//           onChange={e => setSearchTerm(e.target.value)}
//           style={{ width: "200px" }}
//         />

//         <div
//           className="ms-2 d-flex align-items-center"
//           style={{ maxWidth: "150px" }}
//         >
//           <label className="me-2">Show entries: </label>
//           <select
//             className="form-select"
//             value={itemsPerPage}
//             onChange={handleItemsPerPageChange}
//             style={{ width: "100px" }}
//           >
//             <option value={10}>10</option>
//             <option value={20}>20</option>
//             <option value={50}>50</option>
//             {/* Add more options as needed */}
//           </select>
//         </div>
//       </div>

//       {/* Table */}
//       <table className="table">
//         <thead>
//           <tr>
//             {data.columns.map(column => (
//               <th key={column.field}>{column.label}</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {filteredData.length === 0 ? (
//             <tr>
//               <td colSpan={data.columns.length} className="text-center">
//                 <div style={{ marginTop: "50px" }}>{t("No data found")}</div>
//               </td>
//             </tr>
//           ) : (
//             filteredData
//               .map((item, index) => (
//                 <tr key={index}>
//                   {data.columns.map(column => (
//                     <td key={column.field}>{item[column.field]}</td>
//                   ))}
//                 </tr>
//               ))
//               .slice(indexOfFirstItem, indexOfLastItem)
//           )}
//         </tbody>
//       </table>

//       {/* Pagination */}
//       <div className="d-flex justify-content-center">
//         {Array.from({
//           length: Math.ceil(filteredData.length / itemsPerPage),
//         }).map((_, index) => (
//           <button
//             key={index}
//             className="btn btn-outline-primary mx-1"
//             onClick={() => paginate(index + 1)}
//           >
//             {index + 1}
//           </button>
//         ))}
//       </div>
//     </div>
//   )
// }

// DataTableSimple.propTypes = {
//   data: PropTypes.shape({
//     columns: PropTypes.arrayOf(
//       PropTypes.shape({
//         label: PropTypes.string.isRequired,
//         field: PropTypes.string.isRequired,
//       })
//     ).isRequired,
//     rows: PropTypes.array.isRequired,
//   }).isRequired,
//   t: PropTypes.func,
// }

// export default DataTableSimple
