import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import CardLoader from "components/Common/CardLoader"

import { onReadData } from "features/student/dashboard/dashboardSlice"
import { useSelector, useDispatch } from "react-redux"
import {selectCurrentSubjects,selectCurrentIsloading} from "features/student/dashboard/dashboardSlice"
import {
  handleSelectSubject,
  onReadData as onReadSubject,
} from "features/student/subject/subjectSlice"
import SubjectCardsStudent from "./SubjectCardStudent"
const StudentDashboard = props => {
 document.title = "Dashboard | CCMB Tareas"
 const dispatch = useDispatch()
 const subjects = useSelector(selectCurrentSubjects)
 const subjectIsLoading = useSelector(selectCurrentIsloading)
 console.log(subjects)


const handleSelect =(subject)=>{

    dispatch(handleSelectSubject(subject))
    dispatch(onReadSubject(subject.id))
  }


 useEffect(() => {
   dispatch(onReadData())
 }, [])




  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col md={8}>
              <Row>
                {subjects === null ? (
                  <CardLoader /> // Show loading component when subjects is null.
                ) : subjects.length > 0 ? (
                  subjects.map(subject => (
                    <SubjectCardsStudent 
                    key={subject.id} 
                    data={subject} 
                    handleSelectSubject={handleSelect}
                    
                    />
                  ))
                ) : (
                  <p>{props.t("No subjects available.")}</p>
                )}
              </Row>
            </Col>
            <Col md={4}></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

StudentDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(StudentDashboard)
