import { takeLatest, put, call, all } from "redux-saga/effects"

import { apiClient } from "api/axios"
import { onReadData, onReadDataSuccess, onEdit } from "./academicStandarSlice"
import { toast } from "react-toastify"
import { generateOptions } from "helpers/select_helper"
import { teacherEndpoints } from "api/endpoints"

function* workerFetch({ payload: { id }}) {
  try {
   
    const response = yield call(
    apiClient.get,teacherEndpoints.subjects(id))
   
    const options = generateOptions(
      response.data.subjects,
      "id",
      element => `${element.name} (${element.grade_name})`
    )
    yield put(onReadDataSuccess({subjects: response.data,options}))
    
  } catch (error) {
    toast.error(error.message)
  }
}


export function* watchAcadmicStandar() {
  yield takeLatest(onReadData.type, workerFetch)
//  yield takeLatest(onEdit.type, workerEditGradeBook)
}

export default watchAcadmicStandar
