import { takeLatest, call, put,all } from "redux-saga/effects"

import {
  onReadData,
  onReadDataSuccess,
  onSubmit,
  setValidationErrors,
  onSubmitSuccess,
  onReadSubmissionSuccess,
} from "./assignmentViewSlice"
import { apiClient } from "api/axios"
import { studentEndpoints,submissionEndpoints } from "api/endpoints"
import { validator } from "helpers/form_helper"
import { toast } from "react-toastify"

function* workerFetchAssignments({ payload }) {
  try {
   
    const assignmentID = payload.activity_id
    const user = payload.user

  
  const [assignment, submissions] = yield all([
      call(apiClient.get,
      studentEndpoints.student_assignment(user?.user_id, assignmentID)),
      call(apiClient.get,
      studentEndpoints.student_assignment_submissions(user?.user_id, assignmentID)),
      
    ])



    yield put( onReadDataSuccess({
   assignment:assignment?.data,
   submissions:submissions?.data,
    
  }))
  } catch (error) {
    console.log(error)
  }
}

function* workerSubmitAssignments({ payload }) {

  try {
    const { assignment, user, fields,t } = payload
  
    const studentID = user?.user_id
    const data = {
      student_id: studentID,
      student_assignment_id: assignment?.student_assignment_id,
      text_entry_student: fields.text_entry_student.value,
     
    }
   const validation = validator(fields) 
    if (validation.hasErrors) {
      const fieldErrors = { ...validation.fieldErrors }
       yield put(setValidationErrors({ fieldErrors }))
    }else{
      
      const response = yield call(apiClient.post,
      submissionEndpoints.create,data)  

      console.log(response)
      yield put(onSubmitSuccess({userID:user?.user_id, assignmentID:assignment?.id})) 
      
    toast.success(t("Assignment submitted successfully"))
    }


  } catch (error) {
    console.log(error)
  }

}

function* workerFechtSubmission({ payload }) {
try {
  const { userID, assignmentID } = payload
  const { data } = yield call(apiClient.get,
    studentEndpoints.student_assignment_submissions(userID, assignmentID))
  yield put(onReadSubmissionSuccess({ submissions: data }))
  
} catch (error) {
  
}

}


export function* assigmentViewSaga() {
  yield takeLatest(onReadData.type, workerFetchAssignments)
  yield takeLatest(onSubmit.type, workerSubmitAssignments)
  yield takeLatest(onSubmitSuccess.type, workerFechtSubmission)


}

export default assigmentViewSaga
