export const dataTable = {
  columns: [
    {
      label: "Estudiante",
      field: "student_name",
    },
    {
      label: "Fecha de la falta",
      field: "date",
    },
    {
      label: "Vista por tutor",
      field: "reviewed_by_tutor",
    },

    {
      label: "Días de sanción",
      field: "to_serve_for",
    },

    {
      label: "Opciones",
      field: "options",
    },
  ],
  rows: [],
}
