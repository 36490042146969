import React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"


const SaveConfirmationModal = ({ isOpen, handleSave,handleCancel, t = key => key }) => {
  return (
    <Modal
    backdrop="static"
     isOpen={isOpen} toggle={handleCancel}>
      <ModalHeader toggle={handleCancel}>{t("Save Confirmation")}</ModalHeader>
      <ModalBody>
        {t("The record has not been saved. Do you want to save it now?")}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>
          {t("Save")}
        </Button>{" "}
        <Button color="secondary" onClick={handleCancel}>
          {t("Cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
export default SaveConfirmationModal    