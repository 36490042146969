import React, { useEffect } from "react"
import Announcements from './Announcements/Announcements'
import { Card, CardBody, CardTitle, Col, Row, CardImg } from "reactstrap"
import { useOutletContext } from "react-router-dom"
import SubjectLetter from './Announcements/SubjectLetter'
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { validator } from "helpers/form_helper"


 
 import {
   //---------------subjectLetter-----------------
   onEditStartSubjectLetter,
   onEditCancelSubjectLetter,
   onSubjectLettherChange,
   onEditSubjectLetter,
   //---------------end subjectLetter-----------------
   setValidationErrors,
   onCreateStart,
   handleFieldChange,
   onCreateCancel,
   onCreateIsSubmitting,
   onEditIsSubmitting,

 } from "features/teacher/subject/announcement/announcementSlice" 
// Create a selector for the announcement state
const selectAnnouncementState = state => state.teacher.announcement

// Create a memoized selector for specific announcement properties
const selectAnnouncementProperties = createSelector(
  [selectAnnouncementState],
  announcementState => ({
    isEditingSubjectLetter: announcementState.isEditingSubjectLetter,
    subjectLetterData: announcementState.subjectLetterData,
    fields: announcementState.fields,
    isModal: announcementState.isModal,
    mode: announcementState.mode,
  })
)

const Start = () => {
  const announcementState = useSelector(selectAnnouncementProperties)
  const { t, subject } = useOutletContext()

  const dispatch = useDispatch()
  /*___________________________________________________________region subjectLetter____________________________________________________________________________ */

  const handleStartEditSubjectLetter = () => {
    dispatch(onEditStartSubjectLetter())
  }
  const handleCancelEditSubjectLetter = () => {
    dispatch(onEditCancelSubjectLetter())
  }
  const handleSubjectLettherChange = data => {
    dispatch(onSubjectLettherChange(data))
  }
  const handleSubmitSubjectLether = () => {
    dispatch(
      onEditSubjectLetter({
        subjectId: subject?.id,
        description: announcementState.subjectLetterData,
      })
    )
  }

  useEffect(() => {
    dispatch(onSubjectLettherChange(subject?.teacher_subject_description))
  }, [subject])
  /*___________________________________________________________region subjectLetter end____________________________________________________________________________ */
  const handleChange = (name, value) => {
    dispatch(
      handleFieldChange({
        fieldName: name,
        newValue: value,
      })
    )
  }
  /*____________________________________________________________________________________________________________________________________________________ */
  const handleCreateStart = () => {
    dispatch(onCreateStart())
  }
  /*____________________________________________________________________________________________________________________________________________________ */
  const handleCancel = () => {
    // use in edit and create
    dispatch(onCreateCancel())
  }
  /*____________________________________________________________________________________________________________________________________________________ */

  const handleSubmit = () => {
    const validation = validator(announcementState.fields)

    if (validation.hasErrors) {
      const fieldErrors = { ...validation.fieldErrors }
      dispatch(setValidationErrors({ fieldErrors }))
    } else if (announcementState.mode === "Create") {
      dispatch(
        onCreateIsSubmitting({ fields: announcementState.fields, subject })
      )
    } else if (announcementState.mode === "Edit") {
      dispatch(
        onEditIsSubmitting({
          fields: announcementState.fields,
          subject,
          selectedTableRow: announcementState.selectedTableRow,
        })
      )
    } else {
      console.log("No mode selected")
    }
  }

  return (
    <>
      <CardTitle>
        {" "}
        {t(`Welcome to`)}
        {` ${subject?.name}`}
      </CardTitle>
      <Row>
        <Col md={8}>
          <SubjectLetter
            subject={subject}
            t={t}
            isEditing={announcementState.isEditingSubjectLetter}
            subjectLetterData={announcementState.subjectLetterData}
            handleStartEdit={handleStartEditSubjectLetter}
            handleCancel={handleCancelEditSubjectLetter}
            handleChange={handleSubjectLettherChange}
            handleSubmit={handleSubmitSubjectLether}
          />
          <Announcements
            t={t}
            handleChange={handleChange}
            handleCreateStart={handleCreateStart}
            announcementState={announcementState}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
          />
        </Col>
        <Col md={4}>
          <CardImg
            top
            width="100%"
            src={subject?.image}
            alt="subject"
            // className="w-50"
          />
        </Col>
      </Row>
    </>
  )
}

export default Start