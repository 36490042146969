import React from 'react'
import { FormGroup, Label,Button,Input } from "reactstrap"
import FormFieldError from "components/Common/FormFieldError"




const BibllicalIntegrationForm = (props) => {

const {state,t,handleFieldChange,handleSavePlanningWeek,handleCancelPlanningWeek} = props
const { fields, isLoadingPlanningWeek } = state

  return (
    <>
      <FormGroup className="flex-grow-1 mr-2">
        <Label htmlFor="biblical_integration">{t("Bible Integration")}</Label>
        <Input
          type="textarea"
          name="biblical_integration"
          value={fields.biblical_integration.value}
          className="form-control"
          onChange={e =>
            handleFieldChange("biblical_integration", e.target.value)
          }
          rows="6"
          disabled={isLoadingPlanningWeek}
        />
        <FormFieldError errors={fields?.biblical_integration?.errors} t={t} />
      </FormGroup>
      <div className="d-flex flex-column pt-4 m-2">
        <Button
          color="primary"
          className="mb-2"
          onClick={handleSavePlanningWeek}
        >
          {t("Save")}
        </Button>
        <Button color="secondary" onClick={handleCancelPlanningWeek}>
          {t("Cancel")}
        </Button>
      </div>
    </>
  )
}

export default BibllicalIntegrationForm