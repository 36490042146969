
import { createSlice } from "@reduxjs/toolkit"
import { Fields, FieldsPlanning } from "./planningFormSchema"


const planningSlice = createSlice({
  name: "teacher/subject/planning",
  initialState: {
    isLoading: false,
    schoolYearOptions: [],
    schoolYear: null,
    weeksOptions: [],
    schoolYearSelected: null,
    fields: { ...Fields },
    isPlanningWeekSubmitting: false,
    isLoadingPlanningWeek: false,
    planningWeek: null,
    isEditBiblicalIntegration: false,
    indicatorOptions: [],
    fieldsPlannings: { ...FieldsPlanning },
    dateApply: null,
    isPlanningSubmitting: false,
    plannings: [],
    day: null,
    test: null,
  },
  reducers: {
    onReadData: state => {
      state.isLoading = true
      state.fields = { ...Fields }
      state.isEditBiblicalIntegration=true
      state.fieldsPlannings = { ...FieldsPlanning }

      
    },
   
    onReadDataSuccess: (state, action) => {
      state.isLoading = false
      state.fieldsPlannings = { ...FieldsPlanning }
      state.schoolYearOptions = action.payload.schoolYearOptions
      state.schoolYear = action.payload.schoolYear
      state.indicatorOptions = action.payload.indicatorOptions
      state.laesOptions = action.payload.laesOptions
      state.test = "changed test value"
    },
    onFieldChange: (state, action) => {
      const { field, value } = action.payload
      state.fields[field].value = value
      state.fields[field].errors = []
    },

    setFieldError: (state, action) => {
      const { fieldKey, errors } = action.payload
      state.fields[fieldKey].errors = errors
      state.isPlanningWeekSubmitting = false
    },
    setWeeksOptions: (state, action) => {
      state.weeksOptions = action.payload.weeksOptions
    },
    onAddPlanningWeek: state => {
      state.isPlanningWeekSubmitting = true
    },
    onAddPlanningWeekSuccess: (state, action) => {
      state.planningWeek = action.payload.planningWeek
      state.isPlanningWeekSubmitting = false
      state.isEditBiblicalIntegration = false
    },
    onFecthPlanningWeeks: state => {
      state.isLoadingPlanningWeek = true
      state.planningWeek = null
      state.isEditBiblicalIntegration = false
      state.fieldsPlannings = { ...FieldsPlanning }
      state.dateApply = null
    },
    onFecthPlanningWeeksSuccess: (state, action) => {
      state.isLoadingPlanningWeek = false
      state.planningWeek = action.payload.planningWeek
    },
    setIsEditBiblicalIntegration: (state, action) => {
      state.isEditBiblicalIntegration = true
      state.fields.biblical_integration.value =
        action.payload.biblical_integration
    },

    /// ----------------------------- Planning Week ends -----------------------------
    onFieldChangePlanForm: (state, action) => {
      const { field, value } = action.payload
      state.fieldsPlannings[field].value = value
      state.fieldsPlannings[field].errors = []
    },
    setDateApply: (state, action) => {
      state.dateApply = action.payload.dateApply
      state.day = action.payload.day
    },
    onSavePlanning: state => {
      state.isPlanningSubmitting = true
    },
    setValidationErrors: (state, action) => {
      state.fieldsPlannings = { ...action.payload.fieldErrors }
      state.isPlanningSubmitting = false
    },
    onFecthPlanningsSuccess: (state, action) => {
      state.plannings = action.payload.plannings
    },
    onResetPlanningForm: state => {
      state.fieldsPlannings = { ...FieldsPlanning }
      state.dateApply = null
    },
    onSavePlanningSuccess: (state, action) => {
        const updatedPlanning = action.payload.day;
        const existingPlanningIndex = state.plannings.findIndex(planning => planning.id === updatedPlanning.id);

        if (existingPlanningIndex !== -1) {
            state.plannings = [
                ...state.plannings.slice(0, existingPlanningIndex),
                updatedPlanning,
                ...state.plannings.slice(existingPlanningIndex + 1)
            ];
        } else {
            state.plannings = [
                ...state.plannings,
                updatedPlanning
            ];
        }
    },
  },
})

//  onSavePlanningSuccess: (state, action) => { // use on the update
//        state.plannings = state.plannings.map(planning => {
//          if (planning.id === action.payload.day.id) {
//            return action.payload.day
//          }
//          return planning
//        }) 
//     }, 


export const {
  onReadData,
  onReadDataSuccess,
  onFieldChange,
  setWeeksOptions,
  setFieldError,
  onAddPlanningWeek,
  onAddPlanningWeekSuccess,
  onFecthPlanningWeeks,
  onFecthPlanningWeeksSuccess,
  setIsEditBiblicalIntegration,
  onFieldChangePlanForm,
  setDateApply,
  onSavePlanning,
  setValidationErrors,
  onFecthPlanningsSuccess,
  onResetPlanningForm,
  onSavePlanningSuccess,
} = planningSlice.actions
export default planningSlice.reducer

