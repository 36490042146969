// dashboardSlice.js
import { createSlice } from "@reduxjs/toolkit"

const dashboardSlice = createSlice({
  name: "teacher/dashboard",
  initialState: {
    subjects: [],
    isLoading: false,
    selectedSubject: null,
  },
  reducers: {
    onReadData: state => {
      state.isLoading = true
    },
    onReadDataSuccess: (state, action) => {
      state.isLoading = false
      state.subjects = action.payload
    },
    handleSelectSubject: (state, action) => {
      state.selectedSubject = action.payload
    
    },
  },
})

export const { onReadData, onReadDataSuccess, handleSelectSubject } =
  dashboardSlice.actions
export default dashboardSlice.reducer

  
export const selectCurrentSubjects = state => state.teacher.dashboard.subjects
export const selectCurrentSubject = state => state.teacher.dashboard.selectedSubject
export const selectCurrentIsloading = state => state.teacher.dashboard.isLoading
