import { all, fork } from "redux-saga/effects"
import dashboarSaga from "./dashboard/dashboardSaga"
import subjectSaga from "./subject/subjectSaga"
import assignmentSaga from "./subject/assignment/assignmentSaga"
import assigmentViewSaga from "./subject/assignment/assigmentViewSaga"
import watchSchoolGrade from "./subject/schoolGrade/schoolGradeSaga"
export default function* studentSagas() {
  yield all([fork(dashboarSaga)])
  yield all([fork(subjectSaga)])
  yield all([fork(assignmentSaga)])
  yield all([fork(assigmentViewSaga)])
  yield all([fork(watchSchoolGrade)])

}
