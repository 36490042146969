import React from 'react'
import { CardTitle, Button, Col, FormGroup, Label } from "reactstrap"
import Moment from 'moment'
import { CKEditor } from "@ckeditor/ckeditor5-react"
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document"
import MyCKEditor from 'components/Common/CKeditor/MyCKEditor'
import FormFieldError from 'components/Common/FormFieldError'
import Submission from './Submission'
import { useOutletContext } from "react-router-dom"


const LeftSide = () => {

const { state, t, handleFieldChange, handleSubmission } = useOutletContext()
const {
  assignment,
  isLoading,
  fields,
  isSubmitting,
  showSubmission,
  selectedSubmission,
} = state

  return (
    <>
      <CardTitle>
        <h4>{assignment?.title}</h4>
        <hr></hr>
      </CardTitle>
      <p>
        <strong>{t("Due date")} : </strong>
        {Moment(assignment?.due_date).format("LLLL")}
        <br></br>
        <strong>{t("Required")} : </strong>{" "}
        <u> {assignment?.submission_type_name}</u>
      </p>
      <hr></hr>
      <CKEditor
        className="ck-read-only"
        onInit={editor => {
          // window.editor = editor
        }}
        editor={DecoupledEditor}
        disabled={true}
        data={assignment?.description}
      />
      {assignment?.score === null && (
        <>
          <div className="pt-4">
            <Col md="12">
              <FormGroup>
                <MyCKEditor
                  editorData={fields?.text_entry_student?.value}
                  handleEditorChange={rawData => {
                    handleFieldChange("text_entry_student", rawData)
                  }}
                />

                <FormFieldError
                  errors={fields?.text_entry_student?.errors}
                  t={t}
                />
              </FormGroup>
            </Col>
            <Button
              color="primary"
              className="mt-2 float-right"
              onClick={() => {
                handleSubmission()
              }}
              disabled={isSubmitting}
            >
              {t("Send")}
            </Button>
          </div>
        </>
      )}
    
    </>
  )
}

export default LeftSide