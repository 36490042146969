import { takeLatest, put, call, all } from "redux-saga/effects"
import {
  onReadData,
  onReadDataSuccess,
  onAddPlanningWeek,
  onAddPlanningWeekSuccess,
  onFecthPlanningWeeks,
  onFecthPlanningWeeksSuccess,
  onSavePlanning,
  onFecthPlanningsSuccess,
  onSavePlanningSuccess,
} from "./planningSlice"

import { apiClient } from "api/axios"
import {
  schoolYearEndpoints,
  planningWeekEndpoints,
  subjectEndpoints,
  laesEndpoints,
  planningEndpoints,
} from "api/endpoints"
import { generateOptions } from "helpers/select_helper"
import { toast } from "react-toastify"
function* fetchSchoolYears({ payload }) {
  try {
   
    
    const subject = payload.subject
   
 
    const [schoolYears, indicators, laes] = yield all([
        call(apiClient.get, schoolYearEndpoints.read),
        call(apiClient.get, subjectEndpoints.indicators(subject?.id)),
        call(apiClient.get, laesEndpoints.read),

        
        ])

    const schoolYearOptions = generateOptions(
      schoolYears?.data,
      "id",
      element => element.name
    )
    const indicatorOptions = generateOptions(
      indicators?.data,
      "id",
      element => element.name
    )
    const laesOptions = generateOptions(
      laes?.data,
      "id",
      element => element.code +" "+element.name
    )



    yield put(
      onReadDataSuccess({ schoolYearOptions: schoolYearOptions,schoolYear:schoolYears.data,indicatorOptions:indicatorOptions,laesOptions:laesOptions })
    )
  } catch (error) {
    console.log(error)
    // Handle errors, e.g., dispatch an error action
  }
}

function* addPlanningWeek({ payload: { json, t, isEditBiblicalIntegration } }) {
  try {


    if (isEditBiblicalIntegration) {
        const { data } = yield call(apiClient.post, planningWeekEndpoints.update(json.id),json )
           
           
          
       
        yield put(onAddPlanningWeekSuccess( {planningWeek : data}))
     
        toast.success(t("Record updated successfully"))

        return
        }
        
    const { data } = yield call(
      apiClient.post,
      planningWeekEndpoints.create,
      json
    )
  
   yield put(onAddPlanningWeekSuccess( {planningWeek : data}))
    toast.success(t("Record added successfully"))



  } catch (error) {
    console.log(error)
    // Handle errors, e.g., dispatch an error action
  }
}

function* fecthPlanningWeeks({ payload }) {
    try {
       
        const { school_year_id, week_no, subject_id } = payload;
       const {data}= yield call(apiClient.get,  subjectEndpoints.planning_weeks( subject_id,`school_year_id=${school_year_id}&week_no=${week_no}`) )
   
        yield put(onFecthPlanningWeeksSuccess({ planningWeek: data}));
    } catch (error) {
        if (error.response && error.response.status === 404) {
           yield put(onFecthPlanningWeeksSuccess({ planningWeek: null }));
            console.log("Error 404: Resource not found");
           
        } else {
            console.log(error);
            // Handle other errors here
            // e.g., dispatch an error action
        }
    }
}

function* fecthPlannings({ payload }) {
  try {
  
  const { school_year_id, subject_id, start_date, end_date } =payload
  const {data}= yield call(apiClient.get,  planningEndpoints.query(`start=${start_date}&end=${end_date}&subject_id=${subject_id}&school_year_id=${school_year_id}`) )  
     yield put(onFecthPlanningsSuccess({ plannings:data }));
    
  } catch (error) {
    if (error.response && error.response.status === 404) {
      yield put(
        onFecthPlanningsSuccess({  plannings: [] })
      )
    
    } else {
      console.log(error)
      // Handle other errors here
      // e.g., dispatch an error action
    }
  }
}




function* savePlanning({ payload }) {
    try {
        const { json, t,day } = payload;

        if (day) {
            const { data } = yield call(apiClient.post, planningEndpoints.update(day.id), json)
            yield put(onSavePlanningSuccess({ day: data }))
            toast.success(t("Record updated successfully"))
           
        }else{
            const { data } = yield call(  apiClient.post, planningEndpoints.create, json  );
         yield put(onSavePlanningSuccess({ day: data }))
        toast.success(t("Record added successfully"));
        }
       
    } catch (error) {
        console.log(error);
        // Handle errors, e.g., dispatch an error action
    }


}


export function* watchTeacherPlanning() {
  yield takeLatest(onReadData.type, fetchSchoolYears)
  yield takeLatest(onAddPlanningWeek.type, addPlanningWeek)
  yield takeLatest(onFecthPlanningWeeks.type, fecthPlanningWeeks)
  yield takeLatest(onSavePlanning.type, savePlanning)
  yield takeLatest(onFecthPlanningWeeks.type, fecthPlannings)
  

  
  
}

export default watchTeacherPlanning
