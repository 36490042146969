import { takeLatest, put, call, all, delay } from "redux-saga/effects"

import { apiClient } from "api/axios"
import {
  onReadData,
  onReadDataSuccess,
  onEditStart,
  onEditFetchSuccess,
  onEditIni,
  onCreateStart,
  onCreateSuccess,
  onEditSuccess,
  onDelete,
  onDeleteSuccess,
  setFieldError,
} from "./benchmarkSlice"
import { toast } from "react-toastify"
import { subjectEndpoints, benchmarkEndpoints } from "api/endpoints"


function* workerFetch({ payload: { id } }) {
  try {
    const response = yield call(
      apiClient.get,subjectEndpoints.benchmarks(id)
      
    )

    yield put(onReadDataSuccess(response.data))
   
  } catch (error) {
    toast.error(error.message)
  }
}


function* workerEdit({payload:{id}}) {
  try {

    const response = yield call(
      apiClient.get,benchmarkEndpoints.show(id))
    
 

    yield put(onEditFetchSuccess(response.data))
  
  } catch (error) {
    toast.error(error.message)
  }
}


function* workerEditIni({ payload: { fields,id,t } }) {
  try {
const data = {
  id: id,
  name: fields.name.value,
  subject_id: fields.subject_id.value.value,
}

    const response = yield call(apiClient.post, benchmarkEndpoints.update(id),data)

    yield put(onEditSuccess(response.data))
    toast.success(t("Updated successfully"))
   
  } catch (error) {
     const { status, data } = error?.response
      const messages = data?.messages
      if (status === 400) {
        for (const fieldKey in messages) {
          if (messages.hasOwnProperty(fieldKey)) {
            yield put(setFieldError({ fieldKey, errors: [messages[fieldKey]] }))
          }
        }
      }
  }
}

function* workerCreate({ payload: { fields,t } }) {

  try {
    const data = {
      name: fields.name.value,
      subject_id: fields.subject_id.value.value,
    }
    const response = yield call(
    apiClient.post,benchmarkEndpoints.store,data)
    toast.success(t("Created successfully"))
    yield put(onCreateSuccess(response.data))
  } catch (error) {
    const { status, data } = error?.response
      const messages = data?.messages
      if (status === 400) {
        for (const fieldKey in messages) {
          if (messages.hasOwnProperty(fieldKey)) {
            yield put(setFieldError({ fieldKey, errors: [messages[fieldKey]] }))
          }
        }
      }
  }
}

function* workerDelete({ payload: { id,t } }) {


  try {
    const response = yield call(apiClient.delete,benchmarkEndpoints.delete(id))
    yield put(onDeleteSuccess({ id: response.data.benchmark_id}))
  
    toast.success(t("Deleted successfully"))
  } catch (error) {
    toast.error(error.message)
  }
}

export function* watchBenchmark() {
  yield takeLatest(onReadData.type, workerFetch)
  yield takeLatest(onEditStart.type, workerEdit)
  yield takeLatest(onEditIni.type, workerEditIni)
  yield takeLatest(onCreateStart.type, workerCreate)
  yield takeLatest(onDelete.type, workerDelete)

}

export default watchBenchmark
