import React, { useEffect } from "react"

import { Outlet,
  useOutletContext

} from "react-router-dom"






const Activities = () => {
  const { t, subject, user } = useOutletContext()

return (<>
      
      <Outlet context={{ t, subject, user }} />
    </>
  )




}

export default Activities