import { combineReducers } from "@reduxjs/toolkit"

import assignmentReducer from "./assignment/assignmentSlice"
import schoolGradeReducer from "./schoolGrade/schoolGradeSlice"




const coordinatorReducers = combineReducers({
 
  assignment: assignmentReducer,
  schoolGrade: schoolGradeReducer,
  

  //assignments: assignmentReducer,
  // Add other reducers as needed
})

export default coordinatorReducers