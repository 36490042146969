import axios from "axios"
import store from "store"


const API_URL = "https://api2.ccmbtareas.com/"
import { refreshTokenSuccess, onLogoutUser } from "store/auth/login/loginSlice"

// for public routes
export default axios.create({
  baseURL: API_URL,
})

// for private routes
export const apiClient = axios.create({
  baseURL: API_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
})

apiClient.interceptors.request.use(
  config => {
    const accessToken = store.getState().login.token
        if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)


apiClient.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    const originalRequest = error?.config
   
  if (
    error?.response?.status === 401 && // Assuming 401 indicates an expired token
    !originalRequest?.sent
  ) {
      try {
        originalRequest.sent = true
        const response = await apiClient.get("api/refresh-token")
        const newAccessToken = response.data.token
        // Dispatch the new access token to the store
        store.dispatch(refreshTokenSuccess(newAccessToken))
        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`
        return apiClient(originalRequest)
      } catch (error) {
        const { status, data } = error?.response

       

        return Promise.reject(error)
      }

  }
  

    return Promise.reject(error)
  }
)