import { takeLatest, put, call, all } from "redux-saga/effects"

import { apiClient } from "api/axios"
import {
  onReadData,
  onReadDataSuccess,
  

} from "./schoolGradeSlice"
import { toast } from "react-toastify"
import { subjectEndpoints } from "api/endpoints"




function* workerFetchSchoolGrade({payload:{subject,periodId}}) {
    try {

        
        const response = yield call(apiClient.get, subjectEndpoints.school_grades(subject.id,`period=${periodId}`))
        console.log(response.data)
        yield put(onReadDataSuccess(response.data))
     

    } catch (error) {
        
        toast.error(error.message)
    }
}


export function* watchSchoolGrade() {
 
   yield takeLatest(onReadData.type, workerFetchSchoolGrade)
 

}

export default watchSchoolGrade
