import React from 'react'
import { useOutletContext } from 'react-router-dom'
import {  UncontrolledTooltip, Row,Container, Nav,NavItem, NavLink,TabContent, TabPane,Card, CardBody,Col,} from "reactstrap"
import PrivilegeRemovalForm from 'components/Common/Notifications/PrivilegeRemovalForm'
import DataTable from "components/Common/DataTable/DataTable"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { setCurrentActiveTab } from "features/teacher/subject/behaviorTrack/behaviorTrackSlice"
import classnames from "classnames"
import ExhortationNote from './Exhortation Note/ExhortationNote'
import PrivilegeRemoval from './Privilege Removal/PrivilegeRemoval'


 const selectState = state => state.teacher.behaviorTrack

 const selectProperties = createSelector([selectState], state => ({
   currentActiveTab: state.currentActiveTab,
   mode: state.mode,
 }))


const BehaviorTrack = (props) => {
const { t, subject } = useOutletContext()

const dispatch = useDispatch()
const State = useSelector(selectProperties)
 const { currentActiveTab } = State

 const toggle = tab => {
  if (currentActiveTab !== tab) {
    dispatch(setCurrentActiveTab(tab))
  }
}

return (
  <Container fluid>
    <Nav tabs>
      <NavItem>
        <NavLink
          className={classnames({
            active: currentActiveTab === "1",
          })}
          onClick={() => {
            toggle("1")
          }}
        >
          {t("Exhortation Note")}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={classnames({
            active: currentActiveTab === "2",
          })}
          onClick={() => {
            toggle("2")
          }}
        >
          {t("Privilege Removal")}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={classnames({
            active: currentActiveTab === "3",
          })}
          onClick={() => {
            toggle("3")
          }}
        >
          {t("Conduct grades")}
        </NavLink>
      </NavItem>
    </Nav>

    <TabContent activeTab={currentActiveTab}>
      <TabPane tabId="1">
        <Row>
          <Col sm="12">
            <ExhortationNote subject={subject} t={t} />
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId="2">
        
            <PrivilegeRemoval subject={subject} t={t} />
      
      </TabPane>
      <TabPane tabId="3">
        <Row>
          <Col sm="12">
            {/* <Indicator subjectsOptions={subjectsOptions} t={t} /> */}
          </Col>
        </Row>
      </TabPane>
    </TabContent>
  </Container>
)
  // return (
  //   <Container className='pt-4'>
  //     <Row>
  //       <Col xs="4">
  //         <div className="text-center">
  //           <Button className="text-center btn-lg" color="success"
            
  //           >
  //             + {t("Exhortation Note")}
  //           </Button>
  //         </div>
  //       </Col>
  //       <Col xs="4">

        

  //         <div className="text-center">
  //         <Button
  //           type="button"
  //           color="success"
  //           className="text-center btn-lg"
  //           onClick={() => handlePrivilegeRemovalCreateStart()}
  //         >
  //           <i className="text-center btn-lg"></i>
  //           {t("Privilege Removal")}
  //         </Button>
  //         </div>
  //       </Col>
  //       <Col xs="4">
  //         <div className="text-center">
  //           <Button className="text-center btn-lg" color="success">
  //             + {t("Uniform Color Code")}
  //           </Button>
  //         </div>
  //       </Col>
  //     </Row>


  //     <Row>
  //       {/* <Col>
  //         <DataTable
  //           data={dataTable}
  //           isLoading={isLoading}
  //           //customLoader={<TableSkeleton/>}
  //           paginationState={pagination}
  //           onPageChange={handlePageChange}
  //           getRow={getRow}
  //         />
  //       </Col> */}
  //     </Row>

  //     <Modal
  //       size={"xl"}
  //       isOpen={isModal}
  //       toggle={handleCancel}
  //       autoFocus={true}
  //       backdrop={"static"}
  //     >
  //       <ModalHeader toggle={handleCancel}> {t(mode)}</ModalHeader>
  //       <ModalBody>
  //         <PrivilegeRemovalForm {...props} />
  //       </ModalBody>
  //     </Modal>

  //     <Modal
  //       size={"s"}
  //       isOpen={isModalDelete}
  //       toggle={handleCancelDelete}
  //       autoFocus={true}
  //       backdrop={"static"}
  //     >
  //       <ModalHeader toggle={handleCancelDelete}>{t("Delete")}</ModalHeader>
  //       <ModalBody>
  //         <p>
  //           {t(
  //             "Do you really want to delete these records? This process cannot be undone."
  //           )}
  //         </p>
  //       </ModalBody>
  //       <ModalFooter>
  //         <Button
  //           color="danger"
  //           onClick={() => {
  //             handleConfirmToDelete()
  //           }}
  //         >
  //           {t("Delete")}
  //         </Button>
  //         <Button color="secondary" onClick={handleCancelDelete}>
  //           {t("Cancel")}
  //         </Button>
  //       </ModalFooter>
  //     </Modal>

  //   </Container>
  // )
}

export default BehaviorTrack