import { createSlice } from "@reduxjs/toolkit"
import { Fields } from "./fieldSchema"
import {dataTable} from "./tableSchema"

const competenceSlice = createSlice({
  name: "teacher/competence",
  initialState: {
    isModal: false,
    mode: null,
    isModalDelete: false,
    fields: { ...Fields },
    isLoading: false,
    dataTable: { ...dataTable },
    mode: "create",
    isSubmitting: false,
    isEditLoading: false,
    selectedRow: null,
    benchmarkOptions: [],
    isBenchmarkLoading: false,
  },
  reducers: {
    onCreateStart: state => {
      state.isSubmitting = true
    },

    onCreateCancel: state => {
      state.isModal = !state.isModal
      state.mode = null
    },
    onCreateSuccess: (state, action) => {
      state.isSubmitting = false
      state.mode = "create"
      state.fields.name.value = ""
      state.dataTable.rows = [action.payload, ...state.dataTable.rows]
    },
    onFieldChange: (state, action) => {
      const { field, value } = action.payload
      state.fields[field].value = value
    },
    onReadData: (state, action) => {
      state.isLoading = true
    },
    onReadDataSuccess: (state, action) => {
      state.isLoading = false
      state.dataTable.rows = action.payload
    },
    onReadDataBenchmark: (state, action) => {
      state.isBenchmarkLoading = true
      state.benchmarkOptions = []
      state.dataTable.rows = []
      state.fields.benchmark_id.value = ""
    },
    onReadBenchmarkDataSuccess: (state, action) => {
      state.isBenchmarkLoading = false
      state.benchmarkOptions = action.payload.benchmarkOptions
    },
 
    onEditStart: (state, action) => {
      state.isEditLoading = true
      state.fields.name.value = ""
      state.mode = "edit"
      state.selectedRow = action.payload
    },
    onEditFetchSuccess: (state, action) => {
      state.isEditLoading = false
      state.fields.name.value = action.payload.name
      state.fields.code.value = action.payload.code
    },

    /*____________________________________________________________________________________________________________________________________________________ */
    onDeleteStart: (state, action) => {
      state.isModalDelete = true
      state.mode = "delete"
      state.selectedRow = action.payload
      state.fields.name.value = ""
    },
    onDelete: (state, action) => {
      state.isSubmitting = true
    },
    onDeleteSuccess: (state, action) => {
      state.isSubmitting = false
      state.mode = "create"
      state.selectedRow = null
      state.isModalDelete = false
      state.dataTable.rows = state.dataTable.rows.filter(
        row => row.id !== action.payload.id
      )
    },
    /*____________________________________________________________________________________________________________________________________________________ */
    onEditIni: (state, action) => {
      state.isSubmitting = true
    },

    onEditSuccess: (state, action) => {
      state.isSubmitting = false
      state.mode = "create"
      state.fields.name.value = ""
      state.fields.code.value = ""
      state.selectedRow = null
      state.dataTable.rows = state.dataTable.rows.map(row =>
        row.id === action.payload.id ? action.payload : row
      )
    },
    onCancel: state => {
      state.mode = "create"
      state.selectedRow = null
      state.fields.name.value = ""
      state.fields.code.value = ""
      state.isSubmitting = false
      state.isModalDelete = false
    },

    /*____________________________________________________________________________________________________________________________________________________ */
    setFieldError: (state, action) => {
      const { fieldKey, errors } = action.payload
      state.fields[fieldKey].errors = errors
      state.isSubmitting = false
    },
  },
})

export const {
  onCreateStart,
  onCreateCancel,
  onFieldChange,
  onReadData,
  onReadDataSuccess,
  onEditStart,
  onEditFetchSuccess,
  onEditIni,
  onEditSuccess,
  onDeleteStart,
  onDelete,
  onDeleteSuccess,
  onCancel,
  onCreateSuccess,
  onReadDataBenchmark,
  onReadBenchmarkDataSuccess,
 
  setFieldError,
} = competenceSlice.actions
export default competenceSlice.reducer
