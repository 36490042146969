import React from "react"

function ErrorMessage({ error }) {
  // Check if the error is a string or an object
  if (!error) {
    // If there's no error, return null
    return null
  } else if (typeof error === "string") {
    // If it's a string, display the single error message
    return <div>{error}</div>
  } else if (typeof error === "object") {
    // If it's an object, display each error message as list items
    const errorMessages = Object.values(error).map((message, index) => (
      <li key={index}>{message}</li>
    ))

    return <ul>{errorMessages}</ul>
  } else {
    // Handle other types of errors if needed
    return <div>An unknown error occurred.</div>
  }
}

export default ErrorMessage
