export const Fields = {
  subject_id: {
    label: "Subjects",
    selectLabel: "name",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "select",
  },
  benchmark_id: {
    label: "Benchmark",
    selectLabel: "name",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "select",
  },
  code: {
    label: "Code",
    value: "",
    errors: [],
    type: "text",
  },
  name: {
    label: "Description",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "textarea",
  },
}
