import React, {useEffect} from 'react'
import { createSelector } from "reselect"
import { useSelector, useDispatch } from "react-redux"
import { Card, CardBody, Row, Col,FormGroup,Label } from 'reactstrap'
import Select from 'react-select'
import {
  onFieldChange,
  onReadData,
  onEditStart,
  onCancel,
  onDeleteStart,
  onEditIni,
  onCreateStart,
  onDelete,
  onReadDataBenchmark,
} from "features/teacher/academicStandars/competence/competenceSlice"
import CompetenceForm from './CompetenceForm'
import DataTableSimple from 'components/Common/DataTableSimple/DataTableSimple'
import Skeleton from 'react-loading-skeleton'
import DeleteModal from 'components/Common/DeleteModal'

const selectCompetenceState = state => state.teacher.competence
const selectProperties = createSelector([selectCompetenceState], state => ({
  isModal: state.isModal,
  mode: state.mode,
  isModalDelete: state.isModalDelete,
  fields: state.fields,
  isSubmitting: state.isSubmitting,
  rows: state.rows,
  columns: state.columns,
  isLoading: state.isLoading,
  dataTable: state.dataTable,
  isEditLoading: state.isEditLoading,
  selectedRow: state.selectedRow,
  benchmarkOptions: state.benchmarkOptions,
  isBenchmarkLoading: state.isBenchmarkLoading,
}))



const Competence = (props) => {
  const dispatch = useDispatch()
  const competenceState = useSelector(selectProperties)
  const {
    isModal,
    mode,
    isModalDelete,
    fields,
    isSubmitting,
    dataTable,
    isLoading,
    isEditLoading,
    selectedRow,
    benchmarkOptions,
    isBenchmarkLoading,
  } = competenceState

  const { subjectsOptions, t } = props
  /*____________________________________________________________________________________________________________________________________________________ */

  const handleFieldChange = (field, value) => {
    dispatch(onFieldChange({ field, value }))
  }
  /*____________________________________________________________________________________________________________________________________________________ */


    const FecthBenchmark = subject_id => {
      dispatch(onReadDataBenchmark({ id: subject_id }))
    }
  /*____________________________________________________________________________________________________________________________________________________ */

  const FecthCompetence = benchmark_id => {
    dispatch(onReadData({ id: benchmark_id }))
  }
  /*____________________________________________________________________________________________________________________________________________________ */

  const handleForm = () => {
    if (mode === "create") {
      dispatch(onCreateStart({ fields: fields, t: t }))
    }
    if (mode === "edit") {
      dispatch(onEditIni({ fields: fields, id: selectedRow.id, t: t }))
    }
    if (mode === "delete") {
      dispatch(onDelete({ id: selectedRow.id, t: t }))
    }
  }
  /*____________________________________________________________________________________________________________________________________________________ */
  const handleReset = () => {
    dispatch(onCancel())
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const handleEdit = row => {
    dispatch(onEditStart(row))
    console.log(row)
    console.log("edit")
  }
  /*____________________________________________________________________________________________________________________________________________________ */
  const handleDelete = row => {
    dispatch(onDeleteStart(row))
  }

  // const rowsWithActions = dataTable?.rows.map(item => ({
  //   ...item,
  //   options: (
  //     <>
  //       <button  className="icon-button" onClick={() => handleEdit(item)}> <i className="fas fa-edit"></i></button>
  //       <button className="icon-button danger-button ms-1" onClick={() => handleDelete(item)}> <i className="fas fa-trash"></i></button>
  //     </>
  //   ),
  // }))
  const rowsWithActions = dataTable?.rows.map(item => ({
    ...item,
    options: (
      <>
        <div className="tooltip-container">
          <button className="icon-button" onClick={() => handleEdit(item)}>
            <i className="fas fa-edit"></i>
          </button>
          <span className="tooltip">{t("Edit")}</span>
        </div>

        <div className="tooltip-container">
          <button
            className="icon-button danger-button ms-1"
            onClick={() => handleDelete(item)}
          >
            <i className="fas fa-trash"></i>
          </button>
          <span className="tooltip"> {t("Delete")} </span>
        </div>
      </>
    ),
  }))

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={4}>
            <CompetenceForm
              fields={fields}
              subjectsOptions={subjectsOptions}
              mode={mode}
              t={t}
              isBenchmarkLoading={isBenchmarkLoading}
              isEditLoading={isEditLoading}
              isSubmitting={isSubmitting}
              handleFieldChange={handleFieldChange}
              FecthCompetence={FecthCompetence}
              FecthBenchmark={FecthBenchmark}
              benchmarkOptions={benchmarkOptions}
              handleForm={handleForm}
              handleReset={handleReset}
            />
          </Col>
          <Col md={8}>
            {isLoading ? (
              <Skeleton count={10} height={40} />
            ) : (
              <DataTableSimple data={{ ...dataTable, rows: rowsWithActions }} />
            )}
          </Col>
        </Row>

        <DeleteModal
          show={isModalDelete}
          onDeleteClick={handleForm}
          onCloseClick={handleReset}
          t={t}
        />
      </CardBody>
    </Card>
  )
}

export default Competence