import React from 'react'
import { FormGroup, Label, Row, Col, Input, Button, Spinner } from "reactstrap"
import {selectCurrentStudents} from 'features/teacher/subject/subjectSlice'
import Select from "react-select"
import DatePicker from "react-datepicker"
import {generateOptions } from "helpers/select_helper"
import { useSelector } from "react-redux"
import FormFieldError from 'components/Common/FormFieldError'

const PrivilegeRemovalForm = (props) => {
    const { t, isSubmitting, fields } = props
 const { handleFieldChange ,handleSubmit, handleCancel,  } = props
 const Students = useSelector(selectCurrentStudents)
 const studentOptions = generateOptions(
   Students,
   "id",
   item => `${item.name} ${item.last_name}`
 )
    
  return (
    <>
      <Row>
        <Col>
          <FormGroup>
            <Label className="fw-bold" for="student">
              {t("Student")}
            </Label>
            <Select
              className="form-controll"
              value={fields.student_id.value}
              options={studentOptions}
              onChange={option => handleFieldChange("student_id", option)}
            />
            <FormFieldError errors={fields.student_id.errors} t={t} />
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Label className="fw-bold" for="to_serve_for">
              {t("To server for")}
            </Label>
            <Input
              type="number"
              id="to_serve_for"
              name="to_serve_for"
              placeholder={t("e.g., 3 days.")}
              value={fields.to_serve_for.value}
              onChange={event =>
                handleFieldChange("to_serve_for", event.target.value)
              }
            />
            <FormFieldError errors={fields.to_serve_for.errors} t={t} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label className="fw-bold" for="due_date">
              {t("Date")}
            </Label>
            <DatePicker
              name="due_date"
              selected={fields.date.value}
              minDate={new Date()}
              onChange={date => handleFieldChange("date", date)}
              showTimeSelect
              dateFormat="MM-dd-yyyy h:mm aa"
              className="form-control"
            />
            <FormFieldError errors={fields.date.errors} t={t} />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label className="fw-bold ">{t("Reasons")}:</Label>
        <Row>
          {fields.reasons.options.map(option => (
            <Col key={option.value} xs="4">
              <div>
                <Input
                  type="checkbox"
                  id={option.value}
                  checked={fields.reasons.value.includes(option.value)}
                  onChange={() => handleFieldChange("reasons", option.value)}
                />
                <Label for={option.value}>{t(option.label)}</Label>
              </div>
            </Col>
          ))}
        </Row>
        <FormFieldError errors={fields.reasons.errors} t={t} />
      </FormGroup>
      <div className="text-center mt-4">
        <Button
          color="primary"
          type="submit"
          className="mr-2 waves-effect waves-light"
          onClick={() => {
            handleSubmit()
          }}
          disabled={isSubmitting}
        >
          {!isSubmitting ? (
            t("Save")
          ) : (
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              {t("Sending...")}
            </>
          )}
        </Button>
        <Button
          disabled={isSubmitting}
          color="light"
          type="submit"
          className="waves-effect ms-1"
          onClick={() => handleCancel()}
        >
          Cancelar
        </Button>
      </div>
    </>
  )
}

export default PrivilegeRemovalForm