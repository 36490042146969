import React from "react";

import { Input, FormGroup, Label } from "reactstrap"

const Filter = ({ handleOnChange, searchTerm,placeholder,t }) => {
  //this.currentData=this.props.data

  return (
    <FormGroup>
      <Label htmlFor="student">{t("Students")}</Label>
      <Input
        type="search"
        className="form-control"
        value={searchTerm}
        placeholder={placeholder}
        onChange={e => handleOnChange(e)}
      />
    </FormGroup>
  )
}

export default Filter;
