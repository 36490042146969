import React from "react"
import {
  Row,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Col,
  Alert,
  Button,
} from "reactstrap"
import classnames from "classnames"
import AssignmentFormTab1 from "./AssignmentFormTab1"
import AssignmentFormTab2 from "./AssignmentFormTab2"
import ValidationSummary from "components/Common/ValidationSummary"


const AssignmentForm = ( props) => {
 

  const { toggleTab, t, assignmentState } = props

  const { isLoadingEdit, activeTab, fields } = assignmentState
  return (
    <>
      <Row>
        <Col lg={12}>
          <div className="wizard clearfix">
            <div className="steps clearfix">
              <ul className="twitter-bs-wizard-nav nav  nav-justified">
                <NavItem className={classnames({ current: activeTab === 1 })}>
                  <NavLink
                    className={classnames({ current: activeTab === 1 })}
                    onClick={() => {
                      toggleTab(1)
                    }}
                  >
                    <span className="number">01</span>
                    <span className="step-title pl-2">
                      {t("Basic information")}
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem className={classnames({ current: activeTab === 2 })}>
                  <NavLink
                    className={classnames({ current: activeTab === 2 })}
                    onClick={() => {
                      toggleTab(2)
                    }}
                  >
                    <span className="number">02</span>
                    <span className="step-title pl-2">
                      {t("Academic standard")}
                    </span>
                  </NavLink>
                </NavItem>
              </ul>
            </div>

            <div
              className={
                isLoadingEdit
                  ? "content clearfix disabled-div"
                  : "content clearfix"
              }
            >
              <TabContent activeTab={activeTab} className="body">
                <ValidationSummary fields={fields} />
                <TabPane tabId={1}>
                  <AssignmentFormTab1 {...props} />
                </TabPane>
                <TabPane tabId={2}>
                  <AssignmentFormTab2 {...props} />
                </TabPane>
              </TabContent>
            </div>
            <div className="actions clearfix">
              <ul>
                <li
                  className={activeTab === 1 ? "previous disabled" : "previous"}
                >
                  <Button
                    color="primary"
                    disabled={activeTab === 1}
                    onClick={() => {
                      toggleTab(activeTab - 1)
                    }}
                  >
                    {t("Previous")}
                  </Button>
                </li>
                <li className={activeTab === 2 ? "next disabled" : "next"}>
                  <Button
                    color="primary"
                    disabled={activeTab === 2}
                    onClick={() => {
                      toggleTab(activeTab + 1)
                    }}
                  >
                    {t("Next")}
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default AssignmentForm
