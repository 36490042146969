
import TeacherMiddleware from "middleware/TeacherMiddleware"
import Inbox from "pages/CommonPages/Inbox/Inbox"
import Profile from "pages/CommonPages/Profile.js/Profile"
import RemoteSupport from "pages/CommonPages/RemoteSupport/RemoteSupport"
import Settings from "pages/CommonPages/Settings/Settings"
import AcademicStandard from "pages/TeacherPages/AcademicStandard/AcademicStandard"
import TeacherDashboard from "pages/TeacherPages/TeacherDashboard/TeacherDashboard"
import Assignment from "pages/TeacherPages/TeacherSubject/Activities/Assignment"
import Attendance from "pages/TeacherPages/TeacherSubject/Attendance/Attendance"
import BehaviorTrack from "pages/TeacherPages/TeacherSubject/BehaviorTrack/BehaviorTrack"
import ExhortationNoteView from "pages/TeacherPages/TeacherSubject/BehaviorTrack/Exhortation Note/ExhortationNoteView"
import Planning from "pages/TeacherPages/TeacherSubject/Planning/Planning"
import MainSchoolGrade from "pages/TeacherPages/TeacherSubject/SchoolGrade/MainSchoolGrade"
import PedagogicalRecovery from "pages/TeacherPages/TeacherSubject/SchoolGrade/PedagogicalRecovery"
import SchoolGrade from "pages/TeacherPages/TeacherSubject/SchoolGrade/SchoolGrade"
import Start from "pages/TeacherPages/TeacherSubject/Start/Start"
import TeacherSujbect from "pages/TeacherPages/TeacherSubject/TeacherSujbect"
import VirtualClassroom from "pages/TeacherPages/TeacherSubject/VirtualClassroom/VirtualClassroom"
import Tutorials from "pages/TeacherPages/Tutorials/Tutorials"
import StudentPedagogicalRecovery from "pages/TeacherPages/TeacherSubject/SchoolGrade/StudentPedagogicalRecovery"

import { Route } from "react-router-dom"
import Record from "pages/TeacherPages/TeacherSubject/SchoolGrade/Records/Record"


 export default [
   <Route key={1} element={<TeacherMiddleware />}>
     <Route key={2} path="/dashboard" element={<TeacherDashboard />} />
     <Route key={3} path="/inbox" element={<Inbox />} />
     <Route key={4} path="/academic-standards" element={<AcademicStandard />} />
     <Route key={5} path="/tutorials" element={<Tutorials />} />
     <Route key={6} path="/remote-support" element={<RemoteSupport />} />
     <Route key={7} path="/profile" element={<Profile />} />
     <Route key={8} path="/settings" element={<Settings />} />

     <Route key={9} path="/subjects" element={<TeacherSujbect />}>
       <Route key={10} index path=":subject_id/start" element={<Start />} />
       <Route key={11} path=":subject_id/plannings" element={<Planning />} />
       <Route key={12} path=":subject_id/activities" element={<Assignment />} />
       <Route key={13} path=":subject_id/attendance" element={<Attendance />} />
        <Route key={14} path=":subject_id/school-grades" element={<MainSchoolGrade />} >
        <Route index element={<SchoolGrade />} /> 
        <Route key={18} path="pedagogical-recovery" element={<PedagogicalRecovery/>} />
        <Route key={19} path="pedagogical-recovery/student/:student_id" element={<StudentPedagogicalRecovery/>} />
         <Route key={20} path="record" element={<Record/>} />

        </Route>

       <Route key={15} path=":subject_id/virtual-classroom" element={<VirtualClassroom />} />
     
        <Route key={16} path=":subject_id/behavior-track" element={<BehaviorTrack/>  } >
        <Route key={17} path=":behavior_track_id" element={<ExhortationNoteView />} />

       </Route>
     </Route>
   </Route>,
 ]