import StudentMiddleware from "middleware/StudentMiddleware";
import Inbox from "pages/CommonPages/Inbox/Inbox";
import StudentDashboard from "pages/StudentPages/StudentDashboard/StudentDashboard";

import Activities from "pages/StudentPages/StudentSubjects/Activities/Activities";
import ActivityView from "pages/StudentPages/StudentSubjects/Activities/ActivityView";
import ActivitiesList from "pages/StudentPages/StudentSubjects/Activities/ActivitiesList";
import SchoolGrades from "pages/StudentPages/StudentSubjects/SchoolGrades/SchoolGrades";
import Start from "pages/StudentPages/StudentSubjects/Start/Start";
import StudentSubjects from "pages/StudentPages/StudentSubjects/StudentSubjects";
import VirtualClassroom from "pages/StudentPages/StudentSubjects/VirtualClassroom/VirtualClassroom";
import { Route } from "react-router-dom"

import LeftSide from "pages/StudentPages/StudentSubjects/Activities/ActivityView/LeftSide";
import Submission from "pages/StudentPages/StudentSubjects/Activities/ActivityView/Submission";
export default [
  <Route element={<StudentMiddleware />}>
    <Route path="/dashboard" element={<StudentDashboard />} />
    <Route path="/subjects" element={<StudentSubjects />}>
      <Route index path=":id/start" element={<Start />} />

      <Route path=":id/activities" element={<Activities />}>
        <Route index element={<ActivitiesList />} />
        <Route path=":activity_id" element={<ActivityView />}>

          <Route index element={<LeftSide />} /> 
          <Route path="submissions/:submission_id" element={<Submission />} />
        </Route>
      </Route>

      <Route path=":id/school-grades" element={<SchoolGrades />} />
      <Route path=":id/virtual-classroom" element={<VirtualClassroom />} />
    </Route>
    <Route path="inbox" element={<Inbox />} />
  </Route>,
]
