
import CoordinatorMiddleware from "middleware/CoordinatorMiddleware";
import Inbox from "pages/CommonPages/Inbox/Inbox";
import Profile from "pages/CommonPages/Profile.js/Profile";
import RemoteSupport from "pages/CommonPages/RemoteSupport/RemoteSupport";
import Settings from "pages/CommonPages/Settings/Settings";
import Assignments from "pages/CoordinatorPages/Assignments/Assignments";
import CoordinatorDashboard from "pages/CoordinatorPages/CoordinatorDashboard/CoordinatorDashboard";
import Grades from "pages/CoordinatorPages/Grades/Grades";
import Plannings from "pages/CoordinatorPages/Plannings/Plannings";
import SchoolGrade from "pages/CoordinatorPages/SchoolGrade/SchoolGrade";
import Subjects from "pages/CoordinatorPages/Subjects/Subjects";
import Tutorials from "pages/CoordinatorPages/Tutorials/Tutorials";
import Students from "pages/CoordinatorPages/Users/Students";
import Teacher from "pages/CoordinatorPages/Users/Teacher";
import User from "pages/CoordinatorPages/Users/User";
import VirtualClassrooms from "pages/CoordinatorPages/VirtualClassroom/VirtualClassrooms";
import { Route } from "react-router-dom"




export default [
  <Route key="dashboard" element={<CoordinatorMiddleware />}>
    <Route key="dashboard-route" path="/dashboard" element={<CoordinatorDashboard />} />
    <Route key="users" path="/users" element={<User />}>
      <Route key="teachers" path="teachers" element={<Teacher />} />
      <Route key="students" path="students" element={<Students />} />
    </Route>
    <Route key="grades" path="/grades" element={<Grades />} />
    <Route key="subjects" path="/subjects" element={<Subjects />} />
    <Route key="assignments" path="/assignments" element={<Assignments />} />
    <Route key="school-grades" path="/school-grades" element={<SchoolGrade />} />
    <Route key="plannings" path="/plannings" element={<Plannings />} />
    <Route key="inbox" path="/inbox" element={<Inbox />} />
    <Route key="virtual-classroom" path="/virtual-classroom" element={<VirtualClassrooms />} />
    <Route key="tutorials" path="/tutorials" element={<Tutorials />} />
    <Route key="remote-support" path="/remote-support" element={<RemoteSupport />} />
    <Route key="profile" path="/profile" element={<Profile />} />
    <Route key="settings" path="/settings" element={<Settings />} />
  </Route>,
];
