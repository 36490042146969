
import React, { useState } from "react"
import {
  Button,
  Collapse,
  CardBody,
  Card,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap"

import { getDatesInRange } from "helpers/time_helper"

import DateRangeDisplay from "./DateRangeDisplay"
import PlanningForm from "./PlanningForm"








const DateRangeAccordion = (props) => {
const { startDate, endDate, handleSetDateApply } = props


    const [open, setOpen] = useState("")
    const toggle = id => {


        if (open === id) {
            setOpen()
        } else {
            handleSetDateApply(id)
            setOpen(id)
        }
    }

    const formattedDates = getDatesInRange(startDate, endDate)

    return (
        <div>
            <Accordion open={open} toggle={toggle}>
                {formattedDates.map((item, index) => {
                    const date = new Date(item)
                    const options = {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                    }

                    return (
                      <AccordionItem key={index}>
                        <AccordionHeader targetId={String(item)}>
                          {/* Convert targetId to string */}
                          <strong>
                            {date.toLocaleDateString("es-ES", options)}
                          </strong>
                        </AccordionHeader>
                        <AccordionBody accordionId={String(item)}>
                          <PlanningForm {...props} />
                        </AccordionBody>
                      </AccordionItem>
                    )
                })}
            </Accordion>
        </div>
    )
}


export default DateRangeAccordion
