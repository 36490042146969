
import React from "react";
import { CardTitle,  UncontrolledTooltip,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";

                 
const TableHeader = ({ columns, handleDropdownHeader }) => {



  return (
    <React.Fragment>
      {columns &&
        columns.map((header, index, arr) => {
          let title = header.title
          let submission_type_id = header.submission_type_id
          let submission_type_name = header.submission_type_name

          return (
            <th key={index} className={header.className}>
              {header.columnType === "activity" && (
                <UncontrolledDropdown className="float-end">
                  <DropdownToggle href="#" className="btn nav-btn" tag="i">
                    <i className="bx bx-dots-vertical-rounded right"></i>
                  </DropdownToggle>
                  <DropdownMenu direction="right">
                    <DropdownItem
                      disabled={submission_type_id === "4" ? true : false}
                      onClick={() =>
                        handleDropdownHeader("boardGrader", header)
                      }
                      className="text-center"
                    >
                      BoardGrader
                    </DropdownItem>

                    <hr></hr>
                    <p className="text-center"> {submission_type_name}</p>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
              <Link to="#" id={"profile" + index}>
                <UncontrolledTooltip placement="top" target={"profile" + index}>
                  {title}
                </UncontrolledTooltip>
                {}
                <CardTitle
                  className={
                    header.columnType === "activity"
                      ? "title_assignment link-info"
                      : ""
                  }
                >
                  {title}
                </CardTitle>
              </Link>
              {header.activity_name}
            </th>
          )
        })}
    </React.Fragment>
  )
}

export default TableHeader;
