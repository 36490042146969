const ValidationSummary = ({ fields, t = text => text }) => {
  // Check if there are any fields with errors
  const hasErrors = Object.keys(fields).some(
    fieldName => fields[fieldName].errors.length > 0
  )

  // Conditional rendering
  return hasErrors ? (
    <div className="alert alert-danger">
      <ul>
        {Object.keys(fields).map(fieldName => {
          const field = fields[fieldName]
          if (field.errors && field.errors.length > 0) {
            return (
              <li key={fieldName}>
                {field.label}: {t(field.errors.join(", "))}
              </li>
            )
          }
          return null
        })}
      </ul>
    </div>
  ) : null
}

export default ValidationSummary
