import { takeLatest, put, call, all, delay } from "redux-saga/effects"

import { apiClient } from "api/axios"
import {
  onReadData,
  onReadDataSuccess,
  onEditStart,
  onEditFetchSuccess,
  onEditIni,
  onCreateStart,
  onCreateSuccess,
  onEditSuccess,
  onDelete,
  onDeleteSuccess,
  onReadDataBenchmark,
  onReadBenchmarkDataSuccess,
  setFieldError,
  onReadDataCompetence,
  onReadCompetenceDataSuccess,
} from "./indicatorSlice"
import { toast } from "react-toastify"
import { subjectEndpoints, competenceEndpoints,benchmarkEndpoints, indicatorEndpoints } from "api/endpoints"
import { generateOptions } from "helpers/select_helper"


function* workerFetchBenchmark({ payload: { id } }) {
  try {
    const response = yield call(apiClient.get, subjectEndpoints.benchmarks(id))

   const options= generateOptions(response.data, "id", (item) => item.name)
    yield put(onReadBenchmarkDataSuccess({benchmarkOptions:options}))
  } catch (error) {
    toast.error(error.message)
  }
}



function* workerFetchCompetence({ payload: { id } }) {
  try {
    const response = yield call(
      apiClient.get,
      benchmarkEndpoints.competences(id)
    )

    const options = generateOptions(response.data, "id", (item) => item.name)
    yield put(onReadCompetenceDataSuccess({ competenceOptions: options}))
  } catch (error) {
    toast.error(error.message)
  }
}

function* workerFetch({ payload: { id } }) {  // fecthing inditcators by competence
  try {
    const response = yield call(apiClient.get, competenceEndpoints.indicators(id))

    yield put(onReadDataSuccess(response.data))
  } catch (error) {
    toast.error(error.message)
  }
}

function* workerEdit({payload:{id}}) {
  try {

    const response = yield call(
      apiClient.get,indicatorEndpoints.show(id))
    
 

    yield put(onEditFetchSuccess(response.data))
  
  } catch (error) {
    toast.error(error.message)
  }
}


function* workerEditIni({ payload: { fields,id,t } }) {
  try {
const data = {
  id: id,
  name: fields.name.value,
  competence_id: fields.competence_id.value.value,
  category:  fields.category.value.value,
  
  subject_id: fields.subject_id.value.value, //to validate ownership
}

    const response = yield call(apiClient.post, indicatorEndpoints.update(id),data)

    yield put(onEditSuccess(response.data))
    toast.success(t("Updated successfully"))
   
  } catch (error) {
    // display validation errors
     const { status, data } = error?.response
      const messages = data?.messages
      if (status === 400) {
        for (const fieldKey in messages) {
          if (messages.hasOwnProperty(fieldKey)) {
            yield put(setFieldError({ fieldKey, errors: [messages[fieldKey]] }))
          }
        }
      }
  }
}

function* workerCreate({ payload: { fields,t } }) {

  try {
 const data = {
   
   name: fields.name.value,
   competence_id: fields.competence_id.value.value,
   category: fields.category.value.value,
   subject_id: fields.subject_id.value.value, //to validate ownership
 }
    const response = yield call(
    apiClient.post,indicatorEndpoints.store,data)
    toast.success(t("Created successfully"))
    yield put(onCreateSuccess(response.data))
  } catch (error) {
    // display validation errors
     const { status, data } = error?.response
      const messages = data?.messages
    if (status === 400) {
        for (const fieldKey in messages) {
          if (messages.hasOwnProperty(fieldKey)) {
            yield put(setFieldError({ fieldKey, errors: [messages[fieldKey]] }))
          }
        }
      }

  }
}

function* workerDelete({ payload: { id,t } }) {


  try {
    const response = yield call(apiClient.delete,indicatorEndpoints.delete(id))
    yield put(onDeleteSuccess({ id: response.data.competence_id}))
  
    toast.success(t("Deleted successfully"))
  } catch (error) {
    toast.error(error.message)
  }
}

export function* watchIndicator() {
  yield takeLatest(onReadData.type, workerFetch)
  yield takeLatest(onReadDataBenchmark.type, workerFetchBenchmark)
  yield takeLatest(onReadDataCompetence.type, workerFetchCompetence)
  yield takeLatest(onEditStart.type, workerEdit)
  yield takeLatest(onEditIni.type, workerEditIni)
  yield takeLatest(onCreateStart.type, workerCreate)
  yield takeLatest(onDelete.type, workerDelete)
}

export default watchIndicator
