export const Fields = {
  subject_id: {
    label: "Subjects",
    selectLabel: "name",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "select",
  },
  benchmark_id: {
    label: "Benchmark",
    selectLabel: "name",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "select",
  },
  competence_id: {
    label: "Competence",
    selectLabel: "name",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "select",
  },
  code: {
    label: "Code",
    value: "",
    errors: [],
    type: "text",
  },

  category: {
    label: "Category",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "radio", // Change the type to "radio"
    options: [
      { label: "CCMB", value: "CCMB" },
      { label: "MINERD", value: "MINERD" },
     
      
    ],
  },
  name: {
    label: "Description",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "textarea",
  },
}
