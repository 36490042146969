import React, { useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
    CardBody,
    Media,
    Badge

} from 'reactstrap';
import SimpleBar from 'simplebar-react';

function AccordionSchoolGrades(props) {

    const { open, setOpen, schoolGrades, totalScore,t } = props
  
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
 const handleStatusAssignment = key => {
   let color
   let status
   switch (key) {
     case "0":
       color = "danger"
       status = "pendiente"
       break
     case "1":
       color = "warning"
       status = "por evaluar"
       break
     case "2":
       color = "success"
       status = "evaluada"
       break
     case "3":
       color = "secondary"
       status = "vencida"
       break
     default:
       break
   }
   return <Badge color={color}>{status}</Badge>
 }


  return (
    <div>
      <Accordion open={open} toggle={toggle}>
        {schoolGrades &&
          schoolGrades.map((item, index) => (
            <AccordionItem key={index}>
              <AccordionHeader targetId={index.toString()}>
                {item.name}
              </AccordionHeader>
              <div className="d-flex justify-content-end m-2" key={index.toString()}>
                <h5 className="font-size-14">
                  {item.total_score && (
                    <span>{Number(item.total_score)?.toFixed(2)}</span>
                  )}
                </h5>
              </div>
              <AccordionBody accordionId={index.toString()}>
                <CardBody>
                  {/* <p className="mb-0">{this.props.answer1}</p> */}
                  {item?.assignments && item?.assignments.length > 0 && (
                    <SimpleBar style={{ maxHeight: "390px" }}>
                      <ul className="list-group">
                        {item.assignments.map(item => {
                          return (
                            <li className="list-group-item" key={item.id}>
                              <Media>
                                <Media body>
                                  <h5 className="font-size-14">
                                    {item.title}-{item.code}
                                    <span className="float-end">
                                      {handleStatusAssignment(item.status)}
                                      {item.score}
                                    </span>
                                  </h5>
                                </Media>
                              </Media>
                            </li>
                          )
                        })}
                      </ul>
                    </SimpleBar>
                  )}
                </CardBody>
              </AccordionBody>
            </AccordionItem>
          ))}
      </Accordion>
      {totalScore > 0 && (
        <div className="mt-3">
          <h5 className="font-size-14">
            {t("Total Score")} : {totalScore}
          </h5>
        </div>
      )}
    </div>
  )
}

export default AccordionSchoolGrades;

 