import { all, fork } from "redux-saga/effects"
// import dashboarSaga from "./dashboard/dashboardSaga"
import watchCoordinatorAssignments from "./assignment/assignmentSaga"
import watchSchoolGrade from "./schoolGrade/schoolGradeSaga"
// import watchCoordinatorSubject from "./subject/subjectSaga"
// import watchCoordinatorAnnouncements from "./subject/announcement/announcementSaga"
// import watchCoordinatorSchoolGrade from "./subject/schoolGrade/schoolGradeSaga"


export default function* coordinatorSagas() {
  // yield all([fork(dashboarSaga)])
  yield all([fork(watchCoordinatorAssignments)])
  yield all([fork(watchSchoolGrade)])
  // yield all([fork(watchCoordinatorSubject)])
  // yield all([fork(watchCoordinatorAnnouncements)])
  // yield all([fork(watchCoordinatorSchoolGrade)])

}
