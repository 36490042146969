import { takeLatest, put, call, all } from "redux-saga/effects"

import { apiClient } from "api/axios"
import {
  onReadData,
  onReadDataSuccess,


} from "./pedagogicalRecoveryStudentSlice"
import { toast } from "react-toastify"
import { studentEndpoints } from "api/endpoints"




function* workerFetchPedagogicalRecovery({payload:{studentId,periodId,subjectId}}) {
    try {

        
        const response = yield call(apiClient.get, studentEndpoints.pedagogical_recovery(studentId,`period=${periodId}&subject=${subjectId}`))
        yield put(onReadDataSuccess(response.data))
     

    } catch (error) {
        
        toast.error(error.message)
    }
}

export function* watchPedagogicalRecoveryStudent() {
 
   yield takeLatest(onReadData.type, workerFetchPedagogicalRecovery)
  

}

export default watchPedagogicalRecoveryStudent
