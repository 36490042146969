import { combineReducers } from "@reduxjs/toolkit"
import assignmentSlice from "./assignmentListSlice"
import assignmentViewSlice from "./assignmentViewSlice"


const assignmentReducers = combineReducers({
  assignmentList: assignmentSlice,
  assigmentView: assignmentViewSlice,

  // Add other reducers as needed
})

export default assignmentReducers
