
import { parse, format } from "date-fns"

// Reusable function to parse and format datetime strings
export const parseAndFormatDateTime = (dateTimeString, formatString) => {
  // Split the date and time
  const dateTimeParts = dateTimeString.split(" ")
  const datePart = dateTimeParts[0]
  const timePart = dateTimeParts[1].slice(0, 5) // Remove seconds (":ss")

  // Combine the date and time without seconds
  const dateTimeWithoutSeconds = `${datePart} ${timePart}`

  // Parse the datetime without seconds
  const parsedDate = parse(
    dateTimeWithoutSeconds,
    "yyyy-MM-dd HH:mm",
    new Date()
  )

  // Format the parsed date as needed
  return format(parsedDate, formatString)
}

export const filterPassedTime = time => {
  const currentDate = new Date()
  const selectedDate = new Date(time)
  return currentDate.getTime() < selectedDate.getTime()
}




// Reusable function to generate an array of weeks between two dates
export const generateWeeksArray = (schoolYearStart, schoolYearEnd) => {
  const weeksArray = []
  let currentDate = new Date(schoolYearStart)

  while (currentDate <= schoolYearEnd) {
    const weekStart = new Date(currentDate)
    const weekEnd = new Date(currentDate)

    // Adjust to the nearest Monday
    weekStart.setDate(
      currentDate.getDate() + ((1 - currentDate.getDay() + 7) % 7)
    )

    // Adjust to the nearest Friday
    weekEnd.setDate(
      currentDate.getDate() + ((5 - currentDate.getDay() + 7) % 7)
    )

    weeksArray.push({
      value: weeksArray.length + 1,
      label: `Week ${
        weeksArray.length + 1
      } (${weekStart.toLocaleDateString()} - ${weekEnd.toLocaleDateString()})`,
      start_date: weekStart,
      end_date: weekEnd,
    })

    // Move to the next Monday
    currentDate.setDate(currentDate.getDate() + 7)
  }

  return weeksArray
}


export const getDatesInRange = (startDate, endDate) => {
  const dates = []
  let currentDate = new Date(startDate)

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dates
}


