import React, { useEffect } from "react"


import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import {
  onReadData,
  setCurrentPeriod,
  setAccordionId,
  setCurrentStudent,
} from "features/teacher/subject/pedagogicalRecoveryStudent/pedagogicalRecoveryStudentSlice"
import { useOutletContext } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  CardImg,
  CardBody,
  FormGroup,
  Label,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap"
import avatarImage from "assets/images/users/avatar-1.jpg" 
import Select from "react-select"






// Create a selector for the assignment state
const selectpedagogicalRecoveryStudent = state =>
  state.teacher.pedagogicalRecoveryStudent

// Create a memoized selector for specific assignment properties
const selectpedagogicalRecoveryProperties = createSelector(
  [selectpedagogicalRecoveryStudent],
  pedagogicalRecoveryStudent => ({
    filterby: pedagogicalRecoveryStudent.filterby,
    period: pedagogicalRecoveryStudent.period,
    students: pedagogicalRecoveryStudent.students,
    isLoading: pedagogicalRecoveryStudent.isLoading,
    student: pedagogicalRecoveryStudent.student,
    periodId: pedagogicalRecoveryStudent.periodId,
    data: pedagogicalRecoveryStudent.data,
    period: pedagogicalRecoveryStudent.period,
    accordionId: pedagogicalRecoveryStudent.accordionId,
    selectedStudent: pedagogicalRecoveryStudent.selectedStudent,
    studentOptions: pedagogicalRecoveryStudent.studentOptions,
  })
)

const StudentPedagogicalRecovery = () => {
  const pedagogicalRecoveryState = useSelector(selectpedagogicalRecoveryProperties)
  const dispatch = useDispatch()
  const {
    student,
    periodId,
    data,
    period,
    accordionId,
    selectedStudent,
    studentOptions,
  } = pedagogicalRecoveryState
 const { t, SubjectTeacher } = useOutletContext()

useEffect(() => {
  

  const period= SubjectTeacher.periodsOptions.filter(period => {
    return periodId === period.value

  })

 dispatch(setCurrentPeriod(period))




  dispatch(
    onReadData({
      subjectId: SubjectTeacher.subject?.id,
      periodId: periodId,
      studentId: student?.id,
    })
  )
  
}, [dispatch, SubjectTeacher])

 const groupedData = data.reduce((acc, item) => {
   if (!acc[item.specific_competence_id]) {
     acc[item.specific_competence_id] = []
   }
   acc[item.specific_competence_id].push(item)
   return acc
 }, {})

 const averagedData = Object.keys(groupedData).map(specific_competence_id => {
   const items = groupedData[specific_competence_id]
    const sumScore = items.reduce(
      (total, item) => total + parseFloat(item.score),
      0
    )
    const averageScore = (sumScore / items.length).toFixed(2)

   return {
     specific_competence_id,
     code: items[0].code,
     averageScore,
     items,
   }
 })

const handleCellClick = items => {
  console.log(items)
  // Do something with the items


}


const handlePeriodChange = option => {
  dispatch(setCurrentPeriod(option))
  dispatch(
    onReadData({
      subjectId: SubjectTeacher.subject?.id,
      periodId: option.value,
      studentId: student?.id,
    })
  )
}

const setShowAccordions = (id) => {
  if (id === accordionId) {
    dispatch(setAccordionId(null))
    return
  }
  dispatch(setAccordionId(id))


}

const handleStudentChange = option => {
  dispatch(setCurrentStudent(option))
  dispatch(
    onReadData({
      subjectId: SubjectTeacher.subject?.id,
      periodId: period.id,
      studentId: option.value,
    })
  )


}

Jaymie

  return (
    <>
      <Row>
        <Col xs="3">
          <Card>
            <CardBody className="d-flex flex-column align-items-center">
              <CardImg
                src={student?.avatar || avatarImage}
                style={{ width: "100px", height: "100px", borderRadius: "50%" }}
                className="img-fluid"
              />
              <h5 className="avatar-name">{student?.name}</h5>
            </CardBody>
            {/* <FormGroup className="mx-4">
              <Label htmlFor="student">{t("Students")}</Label>
              <Select
                name="student"
                // className="form-control"
                value={selectedStudent}
                options={studentOptions}
                onChange={option => handleStudentChange(option)}
              />
           
            </FormGroup> */}
            <FormGroup className="mx-4">
              <Label htmlFor="period">{t("Periods")}</Label>
              <Select
                name="period_id"
                // className="form-control"
                value={period}
                options={SubjectTeacher.periodsOptions}
                onChange={option => handlePeriodChange(option)}
              />
              {/* <FormFieldError errors={fields.period_id.errors} t={t} /> */}
            </FormGroup>
          </Card>
        </Col>
        <Col xs="9">
          <Card>
            <Accordion open={accordionId} toggle={setShowAccordions}>
              {averagedData && averagedData.length > 0 ? (
                <>
                  {averagedData.map((item, index) => {
                    return (
                      <AccordionItem key={item.specific_competence_id}>
                        <AccordionHeader targetId={item.specific_competence_id}>
                          <div className="d-flex" key={index.toString()}>
                            <h5 className="font-size-14">{item.code}</h5>
                          </div>
                        </AccordionHeader>
                        <div
                          className="d-flex justify-content-end m-2"
                          key={index.toString()}
                        >
                          <h5
                            className={
                              parseFloat(item.averageScore) < 70
                                ? "text-danger"
                                : ""
                            }
                          >
                            {item.averageScore && (
                              <span>
                                {Number(item.averageScore)?.toFixed(2)}
                              </span>
                            )}
                          </h5>
                        </div>
                        <AccordionBody
                          accordionId={item.specific_competence_id}
                        >
                          <Table>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>{t("Assignment")}</th>
                                <th>{t("Code")}</th>
                                <th>{t("Score")} </th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.items.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{item.title}</td>
                                    <td>{item.code}</td>
                                    <td>{item.score}</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </AccordionBody>
                      </AccordionItem>
                    )
                  })}
                </>
              ) : (
                // <Table>
                //   <thead>
                //     <tr>
                //       <th>#</th>
                //       <th>Specific Competence ID</th>
                //       <th>Code</th>
                //       <th>Average Score</th>
                //     </tr>
                //   </thead>
                //   <tbody>
                //     {averagedData.map((item, index) => {
                //       console.log(item)

                //       return (
                //         <tr key={index}>
                //           <th scope="row">{index + 1}</th>
                //           <td>{item.specific_competence_id}</td>
                //           <td>{item.code}</td>
                //           <td
                //             onClick={() => handleCellClick(item.items)}
                //             className={
                //               parseFloat(item.averageScore) < 70
                //                 ? "text-danger"
                //                 : ""
                //             }
                //           >
                //             {item.averageScore}
                //           </td>
                //         </tr>
                //       )
                //     })}
                //   </tbody>
                // </Table>
                <p>No data available</p>
              )}
            </Accordion>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default StudentPedagogicalRecovery


             