import React from 'react'
import DataTableSimple from 'components/Common/DataTableSimple/DataTableSimple'
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Button, Row, Col } from "reactstrap"
import {
  onCreateStart,
  onCancel,
  onFieldChange,
  onCreate,
  onRead
} from "features/teacher/subject/behaviorTrack/privilegeRemoval/privilegeRemovalSlice"
import PrivilegeRemovalForm from './PrivilegeRemovalForm'
//import PrivilegeRemovalForm from 'components/Common/Notifications/PrivilegeRemovalForm'
const selectState = state => state.teacher?.privilegeRemoval

 const selectProperties = createSelector([selectState], state => ({
   dataTable: state.dataTable,
   isVisible: state.isVisible,
   isSubmitting: state.isSubmitting,
   fields: state.fields,
   isLoading: state.isLoading,
 }))

const PrivilegeRemoval = (props) => {
  const State = useSelector(selectProperties)
  const { dataTable, isVisible, isSubmitting, fields ,isLoading} = State
  const dispatch = useDispatch()

  const { t, subject } = props

  React.useEffect(() => {
    dispatch(onRead({subject:subject}))
  }
  , [dispatch,subject])
  
  /*____________________________________________________________________________________________________________________________________________________ */

  const handleCreateStart = () => {
    dispatch(onCreateStart())
  }
  /*____________________________________________________________________________________________________________________________________________________ */

  const handleCancel = () => {
    dispatch(onCancel())
  }
  /*____________________________________________________________________________________________________________________________________________________ */

  const handleSubmit = () => {
    dispatch(onCreate({fields:fields,t:t,subject:subject}))
   
  }
  /*____________________________________________________________________________________________________________________________________________________ */
  const handleFieldChange = (name, value) => {
    dispatch(
      onFieldChange({
        fieldName: name,
        newValue: value,
      })
    )
  }
const rowsWithActions = dataTable?.rows.map(item => ({
  ...item,
  options: (
    <>
      {/* <div className="tooltip-container">
        <button className="icon-button" onClick={() => handleEdit(item)}>
          <i className="fas fa-edit"></i>
        </button>
        <span className="tooltip">{t("Edit")}</span>
      </div>

      <div className="tooltip-container">
        <button
          className="icon-button danger-button ms-1"
          onClick={() => handleDelete(item)}
        >
          <i className="fas fa-trash"></i>
        </button>
        <span className="tooltip"> {t("Delete")} </span>
      </div> */}
    </>
  ),
}))
 



  return (
    <div>
      <Row className="mt-2">
        <Col>
          <Button
            type="button"
            color="success"
            className=" float-end waves-effect waves-light mb-2 mr-2"
            onClick={() => handleCreateStart()}
          >
            <i className="mdi mdi-plus mr-1"></i>
            {t("Privilege Removal")}
          </Button>
        </Col>
      </Row>
      <div
        style={{ display: isVisible ? "block" : "none" }}
        className="jumbotron"
      >
        <PrivilegeRemovalForm
          t={t}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          handleFieldChange={handleFieldChange}
          fields={fields}
        />
        <hr></hr>
      </div>
      <DataTableSimple
        isLoading={isLoading}
        data={{ ...dataTable, rows: rowsWithActions }}
      />
    </div>
  )
}

export default PrivilegeRemoval