

import React from 'react'
import DataTableSimple from 'components/Common/DataTableSimple/DataTableSimple'
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Button, Row, Col } from "reactstrap"
import {
  onCreateStart,
  onCancel,
  onFieldChange,
  onCreate,
  onRead,
  setValidationErrors,
  onToggleView,
} from "features/teacher/subject/behaviorTrack/exhortationNote/exhortationNoteSlice"
import ExhortationNoteForm from './ExhortationNoteForm'
import { validator } from "helpers/form_helper"
import { Outlet, useNavigate } from "react-router-dom";
import ExhortationNoteView from './ExhortationNoteView'


//import ExhortationNoteForm from 'components/Common/Notifications/ExhortationNoteForm'

const selectState = state => state.teacher?.exhortationNote

 const selectProperties = createSelector([selectState], state => ({
   dataTable: state.dataTable,
   isVisible: state.isVisible,
   isSubmitting: state.isSubmitting,
   fields: state.fields,
   isLoading: state.isLoading,
   isModalView: state.isModalView,
    currentExortationNote: state.currentExortationNote,

 }))

const ExhortationNote = (props) => {
    const navigate = useNavigate()
  const State = useSelector(selectProperties)
  const {
    dataTable,
    isVisible,
    isSubmitting,
    fields,
    isLoading,
    isModalView,
    currentExortationNote,
  } = State
  const dispatch = useDispatch()

  const { t, subject } = props

  React.useEffect(() => {
    dispatch(onRead({ subject: subject }))
  }, [dispatch, subject])

  /*____________________________________________________________________________________________________________________________________________________ */

  const handleCreateStart = () => {
    dispatch(onCreateStart())
  }
  /*____________________________________________________________________________________________________________________________________________________ */

  const handleCancel = () => {
    dispatch(onCancel())
  }
  /*____________________________________________________________________________________________________________________________________________________ */

  const handleSubmit = () => {
      // const ShowAcademicDifficultiesOther =
      //   fields.academic_difficulties.value.includes("Other")
      // if (ShowAcademicDifficultiesOther) {

      // }
      const errors = validator(fields)
      console.log(errors)
     
        if (errors.hasErrors ) {
          console.log("errro triggered")
          dispatch(setValidationErrors(errors))

          return
        }
    dispatch(onCreate({ fields: fields, t: t, subject: subject }))
  }
  /*____________________________________________________________________________________________________________________________________________________ */
  const handleFieldChange = (name, value) => {
    dispatch(
      onFieldChange({
        fieldName: name,
        newValue: value,
      })
    )
  }
  /*____________________________________________________________________________________________________________________________________________________ */
  



  // ...

  const handleView = item => {
    console.log(item)
    dispatch(onToggleView(item)) 
   /// navigate(`${item.rid}`)
  }
   const handleCloseView = () => {
     dispatch(onToggleView(null))
     /// navigate(`${item.rid}`)
   }

  

  /*____________________________________________________________________________________________________________________________________________________ */

  const rowsWithActions = dataTable?.rows.map(item => ({
    ...item,
    options: (
      <>
        <div className="tooltip-container">
          <button className="icon-button"
             onClick={() => handleView(item)}
          >
            <i className="fas fa-eye"></i>
          </button>
          <span className="tooltip">{t("View")}</span>
        </div>

        {/* <div className="tooltip-container">
          <button className="icon-button"
            // onClick={() => handleEdit(item)}
          >
            <i className="fas fa-edit"></i>
          </button>
          <span className="tooltip">{t("Edit")}</span>
        </div>

        <div className="tooltip-container">
          <button className="icon-button danger-button ms-1"
            // onClick={() => handleDelete(item)}
          >
            <i className="fas fa-trash"></i>
          </button>
          <span className="tooltip"> {t("Delete")} </span>
        </div> */}
      </>
    ),
  }))

  return (
    <div>
      <Row className="mt-2">
        <Col>
          <Button
            type="button"
            color="success"
            className=" float-end waves-effect waves-light mb-2 mr-2"
            onClick={() => handleCreateStart()}
          >
            <i className="mdi mdi-plus mr-1"></i>

            {t("Exhortation Note")}
          </Button>
        </Col>
      </Row>
      <div
        style={{ display: isVisible ? "block" : "none" }}
        className="jumbotron"
      >
        <ExhortationNoteForm
          t={t}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          handleFieldChange={handleFieldChange}
          fields={fields}
        />
        <hr></hr>
      </div>
      <DataTableSimple
        isLoading={isLoading}
        data={{ ...dataTable, rows: rowsWithActions }}
      />
     
<ExhortationNoteView 
  isModalView={isModalView}
  currentExortationNote={currentExortationNote}
  t={t}
  handleCloseView={handleCloseView}

/>
      
    </div>
  )
}

export default ExhortationNote