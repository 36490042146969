import React from "react"
import {
  Outlet,
  useOutletContext,
  NavLink as RRNavLink,
  useParams,
} from "react-router-dom"
import { Row, Col, Nav, NavItem, NavLink, Card, CardBody } from "reactstrap"
import {
  selectCurrentSubject,
  selectCurrentSubjectStudent,
} from "features/student/subject/subjectSlice"
import { useSelector } from "react-redux"
import Breadcrumb from "components/Common/Breadcrumb"
import { selectCurrentUser } from "store/auth/login/loginSlice"
const StudentSubjects = () => {

  const subject = useSelector(selectCurrentSubject)
  const user = useSelector(selectCurrentUser)

const SubjectStudent = useSelector(selectCurrentSubjectStudent)
  const { t } = useOutletContext()
  const { id } = useParams()
  return (
    <>
      <div className="page-content">
        <Row className="checkout-tabs">
          <Col lg="2">
            <Nav className="flex-column" pills>
              <NavItem key={"tabs"}>
                <NavLink
                  tag={RRNavLink}
                  to={`/subjects/${id}/start`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  <i className={`bx bxs-home d-block check-nav-icon`}></i>
                  <p className="font-weight-bold mb-3">{t("Start")}</p>
                </NavLink>
              </NavItem>

              <NavItem key={"tabs3"}>
                <NavLink
                  tag={RRNavLink}
                  to={`/subjects/${id}/activities`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  <i className={`bx bx-edit-alt d-block check-nav-icon`}></i>
                  <p className="font-weight-bold mb-3">{t("Activities")}</p>
                </NavLink>
              </NavItem>

              <NavItem key={"tabs5"}>
                <NavLink
                  tag={RRNavLink}
                  to={`/subjects/${id}/school-grades`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  <i className={`bx bx-notepad d-block check-nav-icon`}></i>
                  <p className="font-weight-bold mb-3">{t("School grades")}</p>
                </NavLink>
              </NavItem>
              <NavItem key={"tabs6"}>
                <NavLink
                  tag={RRNavLink}
                  to={`/subjects/${id}/virtual-classroom`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  <i
                    className={`bx bx-video-recording d-block check-nav-icon`}
                  ></i>
                  <p className="font-weight-bold mb-3">
                    {t("Virtual Classroom")}
                  </p>
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col lg="10">
            <Breadcrumb
              title={t(subject?.grade_name + " " + subject?.name)}
              breadcrumbItem={t("Dashboard")}
            />
            <Card className="h-100">
              <CardBody>
                <Outlet context={{ t, subject, user, SubjectStudent }} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default StudentSubjects
