export const TableSchema = {
  columns: [
    {
      label: "Titulo",
      field: "title",
      //   sort: "asc",
    },
    {
      label: "Tipo de actividad",
      field: "activity_name",
      sort: "asc",
    },
    {
      label: "Periodo",
      field: "period_name",
    },
    {
      label: "Fecha de Entrega",
      field: "due_date",
    },
    {
      label: "Fecha de publicación",
      field: "publication_date",
    },
    {
      label: "Tipo de Entrega",
      field: "submission_type_name",
    },
    {
      label: "Estado",
      field: "isPublic",
    },

    {
      label: "Opciones",
      field: "options",
    },
  ],
  rows: [],
}

