import { combineReducers } from "@reduxjs/toolkit"
import dashboardReducer from './dashboard/dashboardSlice'
import assignmentReducer from './subject/assignment/assignmentSlice'
import subjectReducer from "./subject/subjectSlice"
import announcementReducer from "./subject/announcement/announcementSlice"
import schoolGradeReducer from "./subject/schoolGrade/schoolGradeSlice"
import behaviorTrackSlice from "./subject/behaviorTrack/behaviorTrackSlice"
import benchmarkSlice from "./academicStandars/benchmark/benchmarkSlice"
import academicStandarSlice from "./academicStandars/academicStandarSlice"
import competenceSlice from "./academicStandars/competence/competenceSlice"
import indicatorSlice from "./academicStandars/indicator/indicatorSlice"
import privilegeRemovalSlice from "./subject/behaviorTrack/privilegeRemoval/privilegeRemovalSlice"
import exhortationNoteSlice from "./subject/behaviorTrack/exhortationNote/exhortationNoteSlice"
import planningSlice from "./subject/planning/planningSlice"
import attendanceSlice from "./subject/attendance/attendanceSlice"
import pedagogicalRecoverySlice from "./subject/pedagogicalRecovery/pedagogicalRecoverySlice"
import pedagogicalRecoveryStudentSlice from "./subject/pedagogicalRecoveryStudent/pedagogicalRecoveryStudentSlice"
import recordSlice from "./subject/record/recordSlice"
const teacherReducers = combineReducers({
  dashboard: dashboardReducer,
  assignment: assignmentReducer,
  subject: subjectReducer,
  announcement: announcementReducer,
  schoolGrade: schoolGradeReducer,
  behaviorTrack: behaviorTrackSlice,
  benchmark: benchmarkSlice,
  academicStandar: academicStandarSlice,
  competence: competenceSlice,
  indicator: indicatorSlice,
  privilegeRemoval: privilegeRemovalSlice,
  exhortationNote: exhortationNoteSlice,
  planning: planningSlice,
  attendance: attendanceSlice,
  pedagogicalRecovery: pedagogicalRecoverySlice,
  pedagogicalRecoveryStudent: pedagogicalRecoveryStudentSlice,
  record: recordSlice,

  //assignments: assignmentReducer,
  // Add other reducers as needed
})

export default teacherReducers