import React, { forwardRef, useState } from "react"

const RecordTable = forwardRef(({ data, subject, zoomLevel }, ref) => {
  const [filter, setFilter] = useState({
    selectValue: "",
    searchText: "",
  })

 

  const handleSearchChange = e => {
    setFilter({ ...filter, searchText: e.target.value })
  }

  
  const filterStudents = students => {
    return students.filter(student => {
      const fullName = `${student.name} ${student.last_name}`.toLowerCase()
      const matchesSelect = filter.selectValue
        ? student.someProperty === filter.selectValue // replace someProperty with the actual property
        : true
      const matchesSearch = fullName.includes(filter.searchText.toLowerCase())
      return matchesSelect && matchesSearch
    })
  }

  const renderTableRows = () => {
    const rows = []

    // First row: Title spanning columns
    rows.push(
      <tr key="title">
        <th
          colSpan={data?.specificCompetence?.length * 8 + 1 + 3 + 1}
          className="record-table fs-3 record-table-title"
        >
          {subject?.name}
        </th>
      </tr>
    )

    // Second row: "Competencias" as a vertical word in the first cell
    rows.push(
      <tr key="competencias">
        <td className="vertical-text">Competencias</td>
        {data?.specificCompetence.map((item, index) => (
          <td colSpan="8" key={index}>
            {item?.name}
          </td>
        ))}
        {/* Last column */}
        {data?.grade?.educational_level_name !== "Primario" ? (
          <>
            <td rowSpan="2" className="vertical-text">
              70%
            </td>
            <td rowSpan="2" className="vertical-text">
              30%
            </td>
          </>
        ) : (
          <>
            {data?.specificCompetence.map((item, index) => (
              <td rowSpan="2" key={index}>
                {item?.code}
              </td>
            ))}
          </>
        )}

        <td rowSpan="2" className="vertical-text">
          Calificación final
        </td>
      </tr>
    )

    // Third row: Column headers (P1, RP, P2, RP, P3, RP, P4, RP)
    rows.push(
      <tr key="content" className="record-third-row">
        <td></td> {/* First column empty */}
        {data?.specificCompetence.map((item, index) =>
          item.columns.map((column, i) => (
            <td
              key={index + "-" + i}
              className={
                column.includes("RP") ? "light-gray-background fs-4" : "fs-4"
              }
            >
              {column}
            </td>
          ))
        )}
      </tr>
    )

    // Data rows for each student
    const filteredStudents = filterStudents(data?.students)
    filteredStudents.forEach((student, studentIndex) => {
      const studentRow = (
        <tr key={studentIndex}>
          <td>
            {student.name} {student.last_name}
          </td>
          {data?.specificCompetence.map((competence, competenceIndex) => {
            const studentCompetence = student.data.find(
              sc => sc.specific_competence_id === competence.id
            )

            return competence.columns.map((column, columnIndex) => (
              <td
                className={
                  column.includes("RP") ? "light-gray-background fs-4" : "fs-4"
                }
                key={competenceIndex + "-" + columnIndex}
              >
                {studentCompetence ? studentCompetence[column] : ""}
              </td>
            ))
          })}
          {/* Last column */}
          {data?.grade?.educational_level_name !== "Primario" ? (
            <>
              <td className="fs-4">{student.total_score_7}</td>
              <td className="fs-4">{student.total_score_3}</td>
            </>
          ) : (
            <>
              {student.data.map((item, index) => (
                <td className="fs-4" key={index}>
                  {item.Average}
                </td>
              ))}
            </>
          )}

          <td className="fs-4">{student.total_score}</td>
          {/* <td>{student.totalAverage}</td>  for testing purposes*/}
        </tr>
      )

      rows.push(studentRow)
    })

    return rows
  }

  return (
    <>
      <div className="table-container">
        <div className="table-responsive scale-to-fit" ref={ref}>
          <h2 className="text-center fs-1 p-2">
            <strong>Reporte por compentencias</strong>
          </h2>
          <div className="filter-container non-printable p-2">
            <input
              type="text"
              value={filter.searchText}
              onChange={handleSearchChange}
              className="form-control"
              placeholder="Buscar por nombre"
            />
          </div>
          <table
            className="record-table table-bordered text-center"
            style={{
              transform: `scale(${zoomLevel})`,
              transformOrigin: "0 0",
            }}
          >
            <tbody>{renderTableRows()}</tbody>
          </table>
        </div>
      </div>
    </>
  )
})

export default RecordTable
