import { takeLatest, put, call, all } from "redux-saga/effects"

import { apiClient } from "api/axios"
import {
  onReadData,
  onReadDataSuccess,
  onSaveData,
  onSaveDataSuccess,
  setStudents,
} from "./attendanceSlice"
import { toast } from "react-toastify"
import { subjectEndpoints, attendanceEndpoints } from "api/endpoints"
import Moment from "moment"

function* workerFetchAttendance({
  payload: { SubjectTeacher, attendanceDate },
}) {
  try {
    const { id } = SubjectTeacher.subject
    const formattedDate= Moment(attendanceDate).format("YYYY-MM-DD")
    const students = yield call(
      apiClient.get,
      subjectEndpoints.attendances(id, `date=${formattedDate}`)
    )
    
    console.log(students.data)
    if (students.data.length !== 0) {
      const firstObject = students.data[0];
      
      
       yield put(setStudents(students.data));
      yield put(onReadDataSuccess({attendanceId:firstObject.attendances_id,isEdit:true}));
    }
    else{
     
      yield put(onReadDataSuccess({attendanceId:null,isEdit:false}));
    }
    
   
  
  } catch (error) {
    toast.error(error.message)
  }
}


function* workerSaveAttendance({    payload: { json , 
    t,isEdit,attendanceId } }) {


    try {
        if (isEdit) {
            yield call(
                apiClient.post,
                attendanceEndpoints.update(attendanceId),
                json
            )
                   toast.success(t("Record updated successfully"))


                 yield put(onSaveDataSuccess({attendanceId:attendanceId,isEdit:true}))  
        } else {
           const response= yield call(apiClient.post, attendanceEndpoints.create, json)
        
              yield put(onSaveDataSuccess({attendanceId:response.data,isEdit:true}))
                   toast.success(t("Record added successfully"))
        }
       
      
    } catch (error) {
        toast.error(error.message)
    }
    }


export function* watchAttendance() {
    yield takeLatest(onReadData.type, workerFetchAttendance)
    yield takeLatest(onSaveData.type, workerSaveAttendance)

 
}

export default watchAttendance
