export const Fields = {
  
  subject_id: {
    label: "Subjects",
    selectLabel: "name",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "select",
  },
    name: {
        label: "Description",
        value: "",
        errors: [],
        rules: {
        require: true,
        },
        type: "textarea",
    },
  
  
 
}
