import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States

import {
  onForgetPasswordSuccess,
  onForgotPasswordError,
} from "./forgetPasswordSlice"

import axios from "../../../helpers/api_helper"
//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, navigate } }) {
  try {
    

    const data = {
      email: user.email,
      
    }
   
 const response = yield call(axios.post, 'api/forgot-password',data);

 yield put(onForgetPasswordSuccess(response.data.message)); 
  } catch (error) {
if (error.isAxiosError) {
    if (error.response) {
      console.log(error)
    const { status, data } = error.response
    if(status===404){
     yield put(onForgotPasswordError(data.messages.error)); 
    }else{
      yield put(onForgotPasswordError("An error occurred"));
    }
    

    }
  
    }else{
        yield put(onLoginError("An error occurred"));
    }

  }
}

export function* watchUserPasswordForget() {
  yield takeEvery('forgetPassword/onForgotPassword', forgetUser)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
