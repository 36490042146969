import React from "react"
import { FormGroup, Label, Row, Col, Input, Button, Spinner } from "reactstrap"
import { selectCurrentStudents } from "features/teacher/subject/subjectSlice"
import Select from "react-select"
import DatePicker from "react-datepicker"
import { generateOptions } from "helpers/select_helper"
import { useSelector } from "react-redux"
import FormFieldError from "components/Common/FormFieldError"

const ExhortationNoteForm = props => {
  const { t, isSubmitting, fields } = props
  const { handleFieldChange, handleSubmit, handleCancel } = props
  const Students = useSelector(selectCurrentStudents)
  const studentOptions = generateOptions(
    Students,
    "id",
    item => `${item.name} ${item.last_name}`
  )

  const ShowAcademicDifficultiesOther = fields.academic_difficulties.value.includes("Other");
  const ShowBehavioralDifficultiesOther = fields.behavioral_difficulties.value.includes("Other");

  return (
    <>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label className="fw-bold" for="student">
              {t("Student")}
            </Label>
            <Select
              className="form-controll"
              value={fields.student_id.value}
              options={studentOptions}
              onChange={option => handleFieldChange("student_id", option)}
            />
            <FormFieldError errors={fields.student_id.errors} t={t} />
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label className="fw-bold" for="due_date">
              {t("Date")}
            </Label>
            <DatePicker
              name="due_date"
              selected={fields.date.value}
              minDate={new Date()}
              onChange={date => handleFieldChange("date", date)}
              showTimeSelect
              dateFormat="MM-dd-yyyy h:mm aa"
              className="form-control"
            />
            <FormFieldError errors={fields.date.errors} t={t} />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label className="fw-bold text-center">
          {t(fields.academic_difficulties.label)}:
        </Label>
        <Row>
          {fields.academic_difficulties.options.map(option => (
            <Col key={option.value} xs="4">
              <div>
                <Input
                  type="checkbox"
                  id={option.value}
                  checked={fields.academic_difficulties.value.includes(
                    option.value
                  )}
                  onChange={() =>
                    handleFieldChange("academic_difficulties", option.value)
                  }
                />
                <Label for={option.value}> {t(option.label)}</Label>
              </div>
            </Col>
          ))}
        </Row>
        {ShowAcademicDifficultiesOther && ( 
          <FormGroup>
            <Label className="fw-bold" for="academic_difficulties_other">
              {t("Others")} {t("Please specify:")}
            </Label>
            <Input
              type="textarea"
              id="academic_difficulties_other"
              value={fields.academic_difficulties_other.value}
              onChange={e =>
                handleFieldChange("academic_difficulties_other", e.target.value)
              }
            />
            <FormFieldError
              errors={fields.academic_difficulties_other.errors}
              t={t}
            />
          </FormGroup>
        )}
        <FormFieldError errors={fields.academic_difficulties.errors} t={t} />
      </FormGroup>

      <hr />
      <FormGroup>
        <Label className="fw-bold">
          {t(fields.behavioral_difficulties.label)}:
        </Label>
        <Row>
          {fields.behavioral_difficulties.options.map(option => (
            <Col key={option.value} xs="4">
              <div>
                <Input
                  type="checkbox"
                  id={option.value}
                  checked={fields.behavioral_difficulties.value.includes(
                    option.value
                  )}
                  onChange={() =>
                    handleFieldChange("behavioral_difficulties", option.value)
                  }
                />
                <Label for={option.value}>{t(option.label)}</Label>
              </div>
            </Col>
          ))}
        </Row>
        {ShowBehavioralDifficultiesOther && (
          <FormGroup>
            <Label className="fw-bold" for="behavioral_difficulties_other">
              {t("Others")} {t("Please specify:")}
            </Label>
            <Input
              type="textarea"
              id="behavioral_difficulties_other"
              value={fields.behavioral_difficulties_other.value}
              onChange={e =>
                handleFieldChange("behavioral_difficulties_other", e.target.value)
              }
            />
            <FormFieldError
              errors={fields.behavioral_difficulties_other.errors}
              t={t}
            />
          </FormGroup>
        )}
        <FormFieldError errors={fields.behavioral_difficulties.errors} t={t} />
      </FormGroup>

      <hr />
      <FormGroup>
        <Label className="fw-bold">{t(fields.recommendations.label)} :</Label>
        <Row>
          {fields.recommendations.options.map(option => (
            <Col key={option.value} xs="4">
              <div>
                <Input
                  type="checkbox"
                  id={option.value}
                  checked={fields.recommendations.value.includes(option.value)}
                  onChange={() =>
                    handleFieldChange("recommendations", option.value)
                  }
                />
                <Label for={option.value}>{t(option.label)}</Label>
              </div>
            </Col>
          ))}
        </Row>
        <FormFieldError errors={fields.recommendations.errors} t={t} />
      </FormGroup>
      <hr />
      <div className="text-center mt-4">
        <Button
          color="primary"
          type="submit"
          className="mr-2 waves-effect waves-light"
          onClick={() => {
            handleSubmit()
          }}
          disabled={isSubmitting}
        >
          {!isSubmitting ? (
            t("Save")
          ) : (
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              {t("Sending...")}
            </>
          )}
        </Button>
        <Button
          disabled={isSubmitting}
          color="light"
          type="submit"
          className="waves-effect ms-1"
          onClick={() => handleCancel()}
        >
          Cancelar
        </Button>
      </div>
    </>
  )
}

export default ExhortationNoteForm
