import React, { useEffect } from "react"
import { useOutletContext } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import {
  setActiveTab,
  handleFieldChange,
  onCreateIsSubmitting,
  onReadData,
  setValidationErrors,
  setSearchTerm,
  onCreateStart,
  onCreateCancel,
  onDelete,
  onDeleteCancel,
  onDeleteStart,
  onEditStart,
  onEditIsSubmitting,
  onFecthIndicators,
} from "features/teacher/subject/assignment/assignmentSlice"
import Main from "components/Common/Assignment/Assignment"
import { validator } from "helpers/form_helper"



 const selectStyles = {
   menu: base => ({
     ...base,
     zIndex: 100,
   }),
 }

// Create a selector for the assignment state
const selectAssignmentState = state => state.teacher.assignment

// Create a memoized selector for specific assignment properties
const selectAssignmentProperties = createSelector(
  [selectAssignmentState],
  assignmentState => ({
    isModal: assignmentState.isModal,
    activeTab: assignmentState.activeTab,
    fields: assignmentState.fields,
    isSubmitting: assignmentState.isSubmitting,
    isLoading: assignmentState.isLoading,
    dataTable: assignmentState.dataTable,
    periodsOptions: assignmentState.periodsOptions,
    submissionTypesOptions: assignmentState.submissionTypesOptions,
    activityOptions: assignmentState.activityOptions,
    uploadPercentage: assignmentState.uploadPercentage,
    pagination: assignmentState.pagination,
    searchTerm: assignmentState.searchTerm,
    isModalDelete: assignmentState.isModalDelete,
    selectedTableRow: assignmentState.selectedTableRow,
    mode: assignmentState.mode,
    isLoadingEdit: assignmentState.isLoadingEdit,
    competencesOptions: assignmentState.competencesOptions,
    specificCompetencesOptions: assignmentState.specificCompetencesOptions,
    indicatorsOptions: assignmentState.indicatorsOptions,
    isIndicatorOptionsLoading: assignmentState.isIndicatorOptionsLoading,
  })
)

const Assignment = () => {
  const dispatch = useDispatch()
  const assignmentState = useSelector(selectAssignmentProperties)
  const { t, subject } = useOutletContext()


  /*____________________________________________________________________________________________________________________________________________________ */
  const handleCreateCancel = () => {
     dispatch(onCreateCancel())
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const handleAssignmentCreateStart = () => {
    dispatch(onCreateStart())
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const toggleTab = tab => {
    dispatch(setActiveTab(tab))
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const handleChange = (name, value) => {
    dispatch(
      handleFieldChange({
        fieldName: name,
        newValue: value,
      })
    )
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const handleCancel = () => {
     dispatch(onCreateCancel())
   
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const handleSubmit = () => {
    console.log("handleSubmit")
    const validation = validator(assignmentState.fields)

    if (validation.hasErrors) {
      const fieldErrors = { ...validation.fieldErrors }
      dispatch(setValidationErrors({ fieldErrors }))
    } else if (assignmentState.mode==="Create") {
console.log("pass validation")

      dispatch(onCreateIsSubmitting({ fields: assignmentState.fields, subject }))
    }else if (assignmentState.mode==="Edit") {


      dispatch(onEditIsSubmitting({ fields: assignmentState.fields, subject,selectedTableRow: assignmentState.selectedTableRow }))

    }else{
      console.log("No mode selected")
    }
 
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const getRow = (action, row) => {
    if (action === "delete") {
      dispatch(onDeleteStart({ selectedTableRow: row }))
    }
    if (action === "edit") {
      dispatch(onEditStart({ selectedTableRow: row }))
    }
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const handlePageChange = pageNumber => {
    dispatch(
      onReadData({ subject, pageNumber, searchTerm: assignmentState.searchTerm })
    )
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const handleSearchTerm = term => {
    dispatch(setSearchTerm(term))
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const handleCancelDelete = () => {
    dispatch(onDeleteCancel())
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const handleConfirmToDelete = () => {
    if (assignmentState.selectedTableRow) {
      dispatch(
        onDelete({
          selectedTableRow: assignmentState.selectedTableRow,
        })
      )
    } else {
      console.log("No row selected")
    }
  }
   const handleFetchIndicators = (id) => {
     dispatch(onFecthIndicators({competenceId:id}))
   }

  // Additional useEffect with a comment separator
  useEffect(() => {
    if (
      assignmentState.searchTerm.length === 0 ||
      assignmentState.searchTerm.length > 2
    ) {
      dispatch(
        onReadData({
          subject,
          pageNumber: 1,
          searchTerm: assignmentState.searchTerm,
        })
      )
    }
  }, [dispatch,  assignmentState.searchTerm])

  return (
    <Main
      selectStyles={selectStyles}
      subject={subject}
      t={t}
      handleAssignmentCreateStart={handleAssignmentCreateStart}
      toggleTab={toggleTab}
      handleFieldChange={handleChange}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      getRow={getRow}
      handlePageChange={handlePageChange}
      handleSearchTerm={handleSearchTerm}
      handleCancelDelete={handleCancelDelete}
      handleConfirmToDelete={handleConfirmToDelete}
      assignmentState={assignmentState}
      handleFetchIndicators={handleFetchIndicators}
      // activeTab={assignmentState.activeTab}
      // mode={assignmentState.mode}
      // isModal={assignmentState.isModal}
      // isSubmitting={assignmentState.isSubmitting}
      // dataTable={assignmentState.dataTable}
      // fields={assignmentState.fields}
      // isLoading={assignmentState.isLoading}
      // periodsOptions={assignmentState.periodsOptions}
      // submissionTypesOptions={assignmentState.submissionTypesOptions}
      // activityOptions={assignmentState.activityOptions}
      // uploadPercentage={assignmentState.uploadPercentage}
      // pagination={assignmentState.pagination}
      // searchTerm={assignmentState.searchTerm}
      // isModalDelete={assignmentState.isModalDelete}
      // isLoadingEdit={assignmentState.isLoadingEdit}
    />
  )
}

export default Assignment
