export const Fields = {
  student_id: {
    label: "Students",
    selectLabel: "name",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "select",
  },
  to_serve_for: { 
    label: "To server for",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "number",

  },
  date: {
    label: "Date of the incident",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "datetime",
  
  },
  reasons: {
    label: "Reasons",
    value: [],
    errors: [],
    rules: {
      require: true,
    },
    type: "checkbox", // Set the type to "checkbox"
  options: [
  { label: "Late to Class", value: "Late to Class" },
  { label: "Failure to do homework", value: "Failure to do homework" },
  { label: "Eating in class", value: "Eating in class" },
  { label: "Disturbing classmates", value: "Disturbing classmates" },
  { label: "Lack of class materials", value: "Lack of class materials" },
  { label: "Restless, not paying attention", value: "Restless, not paying attention" },
  { label: "Disrespect", value: "Disrespect" },
  { label: "Lack of cooperation", value: "Lack of cooperation" },
  { label: "Lack of reverence", value: "Lack of reverence" },
  { label: "Misuse of others' property", value: "Misuse of others' property" },
  { label: "Rudeness", value: "Rudeness" },
  { label: "Misbehavior in the hallways", value: "Misbehavior in the hallways" },
  { label: "Talking excessively", value: "Talking excessively" },
  { label: "Inappropriate uniform", value: "Inappropriate uniform" },
  // Add more options as needed
],
  },
}
