import { takeLatest, put, call, all } from "redux-saga/effects"

import { apiClient } from "api/axios"
import {
  onReadData,
  onReadDataSuccess,
  onEdit,
  onReadSubjects,
  onReadSubjectsSuccess,
} from "./schoolGradeSlice"
import { generateOptions, extractUniqueOptions } from "helpers/select_helper"
import { coordinatorEndpoints, subjectEndpoints } from "api/endpoints"

import { toast } from "react-toastify"

function* workerFetchGradeBook({payload:{subjectId}}) {
    try {
        //const response = yield call(apiClient.get, `/api/coordinator/subjects/${subjectId}/gradebook`)
        const response = yield call(apiClient.get, subjectEndpoints.gradebook(subjectId))

        yield put(onReadDataSuccess(response.data))
   

    } catch (error) {
        
        toast.error(error.message)
    }
}



/** disable at the moment*/
function* workerEditGradeBook({payload:{subjectId,studentId,assignmentId,score}}) {
    try {
        const response = yield call(apiClient.post, `/api/coordinator/subjects/${subjectId}/gradebook`,{subjectId,studentId,assignmentId,score})

        // yield put(onReadData(response.data))
        yield put(onReadDataSuccess(response.data))

    } catch (error) {
        
        toast.error(error.message)
    }


}

function* workerFetchSubjectOptions({payload:{id}}) {
  try {
  const response = yield call(apiClient.get,coordinatorEndpoints.subjects(id));

    const gradeOptions = generateOptions(
      extractUniqueOptions(response.data.subjects, "grade_id", "grade_name"),
      "grade_id",
      element => element.grade_name
    )

    yield put(onReadSubjectsSuccess({ subjects: response.data.subjects, gradeOptions }))
  } catch (error) {}
}

export function* watchSchoolGrade() {
   yield takeLatest(onReadData.type, workerFetchGradeBook)
   yield takeLatest(onEdit.type, workerEditGradeBook)
   yield takeLatest(onReadSubjects.type,workerFetchSubjectOptions )

}

export default watchSchoolGrade
