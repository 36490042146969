import axios from "axios";

const API_URL = "https://api2.ccmbtareas.com/"

// for public routes
export default axios.create({
  baseURL: API_URL,
})

// for private routes
export const apiClient = axios.create({
  baseURL: API_URL,
  headers: { "Content-Type": "application/json" },
  
  withCredentials: true,
})



// axiosPrivate.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject(error)
// );

// axiosPrivate.interceptors.request.use(



//   function (response) {

//       if (
//         response.url.includes("login") ||
//         response.url.includes("forgot-password") ||
//         response.url.includes("reset-password") ||
//         response.url.includes("logout") ||
//         response.url.includes("get-email-from-token")
//       ) {
//         return response // Skip the interceptor and proceed with the request
//       }
//     // const token =
//     //   localStorage.getItem("authUser") !== null
//     //     ? JSON.parse(localStorage.getItem("authUser")).token
//     //     : ""
//     // response.headers.Authorization = token

//     return response
//   },
//   function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     return Promise.reject(error)
//   }
// )

// export async function get(url, config = {}) {
//   return await axiosPrivate
//     .get(url, { ...config })
//     .then((response) => response.data);
// }

// export async function post(url, data, config = {}) {
//   return axiosPrivate
//     .post(url, { ...data }, { ...config })
//     .then((response) => response.data);
// }

// export async function put(url, data, config = {}) {
//   return axiosPrivate
//     .put(url, { ...data }, { ...config })
//     .then((response) => response.data);
// }

// export async function del(url, config = {}) {
//   return await axiosPrivate
//     .delete(url, { ...config })
//     .then((response) => response.data);
// }
