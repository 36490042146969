
import { takeLatest, call, put } from 'redux-saga/effects'

import { onReadData, onReadDataSuccess } from './assignmentListSlice'
import { apiClient } from 'api/axios'
import { subjectEndpoints } from 'api/endpoints'


function* workerFetchAssignments({ payload }) {
    try {
        const subject = payload.subject
        const user = payload.user
       
    
       const response = yield call(apiClient.get, subjectEndpoints.student_assignments(subject?.id,user?.user_id))
       yield put(onReadDataSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
    }







export function* assignmetSaga() {
  yield takeLatest(onReadData.type, workerFetchAssignments)
}

export default assignmetSaga
