// forgetPasswordSlice.js
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  forgetSuccessMsg: null,
  forgetError: null,
}

const forgetPasswordSlice = createSlice({
  name: "forgetPassword",
  initialState,
  reducers: {
    onForgotPassword: state => {
      state.forgetSuccessMsg = null
      state.forgetError = null
    },
    onForgetPasswordSuccess: (state, action) => {
      state.forgetSuccessMsg = action.payload
    },
    onForgotPasswordError: (state, action) => {
      state.forgetError = action.payload
    },
  },
})

export const { onForgotPassword, onForgetPasswordSuccess, onForgotPasswordError } =
  forgetPasswordSlice.actions

export default forgetPasswordSlice.reducer
