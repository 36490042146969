import  React ,{useEffect} from "react";
const TableData = ({
  data,
  columns,
  rowUpdate,
  filterBy,
  handleSelectedRow,
  selectedRow,
  handleConfimationModalStart,
  isEditing,
  editable,
 
}) => {
  // useEffect(() => {
  //   // This effect will run whenever the `columns` prop changes
  //   // You can add additional logic here if needed
  // }, [columns])

  const onUpdate = e => {
    e.preventDefault()
    let input = e.target.firstChild
    rowUpdate(selectedRow, input.getAttribute("dataid"), input.value)
  }

  const handleOnBlur = (actualValue, nota, assignmentId) => {
    if (isEditing) return
    if (actualValue !== nota) {
      handleConfimationModalStart(actualValue, nota, assignmentId)
    }
  }

  let tRowsView =
    data &&
    data
      .filter(x => {
        return (
          x.student.name.toLowerCase().indexOf(filterBy.toLowerCase()) !== -1
        )
      })
      .map((row, rIndex) => {
        let tData = columns.map((header, hIndex, arr) => {
          return (
            <td
              key={hIndex}
              // data-row={header.id}
              //
              className={hIndex === 0 ? "sticky-col first-col" : "text-center"}
              style={{
                backgroundColor:
                  row[header.title]?.status === "1"
                    ? "#ffefbf"
                    : row[header.title]?.status === "2"
                    ? "#addbad"
                    : row[header.title]?.status === "3"
                    ? "#909598"
                    : row[header.title]?.status === "0" && "#eca9a6",
              }}
            >
              {hIndex === 0 ? (
                <>
                  {/* <img
                    src={require(`../../../../assets/images/users/${row.student.photo}`)}
                    alt="profile"
                    className="rounded-circle header-profile-user mr-1 "
                  /> */}
                  {row.student.name}
                </>
              ) : header.columnType === "activity" ? (
                <>
                  {editable ? (
                    <form
                      onSubmit={onUpdate}
                      onBlur={e => {
                        let value = e.target.value
                        handleOnBlur(value, row[header.title]?.nota, header.id)
                      }}
                    >
                      <input
                        className="input_edit"
                        dataid={header.id}
                        defaultValue={row[header.title]?.nota}
                        type="number"
                        max="100"
                        min="0"
                        // onKeyUp={this.onFormReset}
                      />
                    </form>
                  ) : (
                    <> {row[header.title]?.nota} </>
                  )}
                </>
              ) : (
                <>
                  <span className="text-center">
                    {row[header.title]?.total + "%"}
                  </span>
                </>
              )}
            </td>
          )
        })
        return (
          <tr key={row.id} onClick={() => handleSelectedRow(row.id)}>
            {tData}
          </tr>
        )
      })
  return <React.Fragment>{tRowsView}</React.Fragment>
}
export default TableData;
