import React,{useEffect} from 'react'
import { Card, Col, Container, Row } from 'reactstrap'
import Breadcrumbs from 'components/Common/Breadcrumb'
import { withTranslation } from 'react-i18next'
import Select from "react-select"
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import { validator } from 'helpers/form_helper'
import {
  onReadSubjects,
  setActiveTab,
  handleFieldChange,
  onCreateIsSubmitting,
  onReadData,
  setValidationErrors,
  setSearchTerm,
  onCreateStart,
  onCreateCancel,
  onDelete,
  onDeleteCancel,
  onDeleteStart,
  onEditStart,
  onEditIsSubmitting,
  onFecthIndicators,
  setSelectSubject,
  setSelectedGrade,
  setSubjectOptions
} from "features/coordinator/assignment/assignmentSlice"
import Main from "components/Common/Assignment/Assignment"
import FilterSubjectByGrade from 'components/Common/Filters/FilterSubjectByGrade'
import { useOutletContext } from  "react-router-dom"







 const selectStyles = {
   menu: base => ({
     ...base,
     zIndex: 100,
   }),
 }

 // Create a selector for the assignment state
 const selectAssignmentState = state => state.coordinator.assignment

 // Create a memoized selector for specific assignment properties
 const selectAssignmentProperties = createSelector(
   [selectAssignmentState],
   assignmentState => ({
     isModal: assignmentState.isModal,
     activeTab: assignmentState.activeTab,
     fields: assignmentState.fields,
     isSubmitting: assignmentState.isSubmitting,
     isLoading: assignmentState.isLoading,
     dataTable: assignmentState.dataTable,
     periodsOptions: assignmentState.periodsOptions,
     submissionTypesOptions: assignmentState.submissionTypesOptions,
     activityOptions: assignmentState.activityOptions,
     uploadPercentage: assignmentState.uploadPercentage,
     pagination: assignmentState.pagination,
     searchTerm: assignmentState.searchTerm,
     isModalDelete: assignmentState.isModalDelete,
     selectedTableRow: assignmentState.selectedTableRow,
     mode: assignmentState.mode,
     isLoadingEdit: assignmentState.isLoadingEdit,
     competencesOptions: assignmentState.competencesOptions,
     specificCompetencesOptions: assignmentState.specificCompetencesOptions,
     indicatorsOptions: assignmentState.indicatorsOptions,
     isIndicatorOptionsLoading: assignmentState.isIndicatorOptionsLoading,
     subjectOptions: assignmentState.subjectOptions,
     isSubjectOptionsLoading: assignmentState.isSubjectOptionsLoading,
     subjects: assignmentState.subjects,
     subject: assignmentState.subject,
     selectedSubject: assignmentState.selectedSubject,
     selectedGrade:  assignmentState.selectedGrade,
     gradeOptions: assignmentState.gradeOptions,
   })
 )


const Assignments = () => {
  const dispatch = useDispatch()
  const assignmentState = useSelector(selectAssignmentProperties)
  const { t, user } = useOutletContext()
  const { subject, subjects} = assignmentState



  useEffect(() => {
    dispatch(onReadSubjects({ id: user?.user_id }))
  }, [user,dispatch])
  
  

  /////*start same for assignment
  /*____________________________________________________________________________________________________________________________________________________ */
  const handleCreateCancel = () => {
    dispatch(onCreateCancel())
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const handleAssignmentCreateStart = () => {
    dispatch(onCreateStart())
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const toggleTab = tab => {
    dispatch(setActiveTab(tab))
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const handleChange = (name, value) => {
    dispatch(
      handleFieldChange({
        fieldName: name,
        newValue: value,
      })
    )
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const handleCancel = () => {
    dispatch(onCreateCancel())
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const handleSubmit = () => {
    console.log("handleSubmit")
    const validation = validator(assignmentState.fields)

    if (validation.hasErrors) {
      const fieldErrors = { ...validation.fieldErrors }
      dispatch(setValidationErrors({ fieldErrors }))
    } else if (assignmentState.mode === "Create") {
      console.log("pass validation")

      dispatch(
        onCreateIsSubmitting({ fields: assignmentState.fields, subject })
      )
    } else if (assignmentState.mode === "Edit") {
      dispatch(
        onEditIsSubmitting({
          fields: assignmentState.fields,
          subject,
          selectedTableRow: assignmentState.selectedTableRow,
        })
      )
    } else {
      console.log("No mode selected")
    }
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const getRow = (action, row) => {
    if (action === "delete") {
      dispatch(onDeleteStart({ selectedTableRow: row }))
    }
    if (action === "edit") {
      dispatch(onEditStart({ selectedTableRow: row }))
    }
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const handlePageChange = pageNumber => {
    dispatch(
      onReadData({
        subject,
        pageNumber,
        searchTerm: assignmentState.searchTerm,
      })
    )
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const handleSearchTerm = term => {
    dispatch(setSearchTerm(term))
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const handleCancelDelete = () => {
    dispatch(onDeleteCancel())
  }

  /*____________________________________________________________________________________________________________________________________________________ */
  const handleConfirmToDelete = () => {
    if (assignmentState.selectedTableRow) {
      dispatch(
        onDelete({
          selectedTableRow: assignmentState.selectedTableRow,
        })
      )
    } else {
      console.log("No row selected")
    }
  }
  const handleFetchIndicators = id => {
    dispatch(onFecthIndicators({ competenceId: id }))
  }

  // Additional useEffect with a comment separator
useEffect(() => {
  if (
    subject !== null && // Check if subject is not null
    (assignmentState.searchTerm.length === 0 ||
      assignmentState.searchTerm.length > 2)
  ) {
    dispatch(
      onReadData({
        subject,
        pageNumber: 1,
        searchTerm: assignmentState.searchTerm,
      })
    )
  }
}, [dispatch, subject, assignmentState.searchTerm])

 const handleSelectGrades = (selectedGrade) => {
     dispatch(setSelectedGrade(selectedGrade))
const subjectsForGrade = subjects.filter(
  subject => subject.grade_id === selectedGrade.value
)
const options = subjectsForGrade.map(subject => ({
  value: subject.id,
  label: `${subject.name} - ${subject.teacher_name}`,
}))
     dispatch(setSubjectOptions(options))

 }
     const handleChangeSelect = selectedSubject => {
       const option = {
         value: selectedSubject.value,
         label: selectedSubject.label,
       }

       dispatch(
         setSelectSubject({
           option,
           subject: subjects.find(
             subject => subject.id === selectedSubject.value
           ),
         })
       )
     } 
  
  ///end same for assignment
  return (
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumb */}
        <Breadcrumbs
          title={t("Dashboards")}
          breadcrumbItem={t("Activities")}
        />
        <FilterSubjectByGrade
          selectedGrade={assignmentState.selectedGrade}
          gradeOptions={assignmentState.gradeOptions}
          subjectOptions={assignmentState.subjectOptions}
          selectedSubject={assignmentState.selectedSubject}
          handleSelectGrades={handleSelectGrades}
          handleSelectSubjecs={handleChangeSelect}
          t={t}
        />
        
        <Row>
          <Card className='p-4'>
            
            <Main
              selectStyles={selectStyles}
              subject={subject}
              t={t}
              handleAssignmentCreateStart={handleAssignmentCreateStart}
              toggleTab={toggleTab}
              handleFieldChange={handleChange}
              handleCancel={handleCancel}
              handleSubmit={handleSubmit}
              getRow={getRow}
              handlePageChange={handlePageChange}
              handleSearchTerm={handleSearchTerm}
              handleCancelDelete={handleCancelDelete}
              handleConfirmToDelete={handleConfirmToDelete}
              assignmentState={assignmentState}
              handleFetchIndicators={handleFetchIndicators}
            />
          </Card>
        </Row>
      </Container>
    </div>
  )
}



export default Assignments;