import React from "react"
import PropTypes from "prop-types"
import { Col, Card, CardBody, Media } from "reactstrap"

const MiniWidgets = props => {
  return (
    <Col md={4}>
      <Card>
        <CardBody>
          <Media>
            <Media body className="overflow-hidden">
              <p className="text-truncate font-size-14 mb-2">{props.title}</p>
              <h4 className="mb-0">{props.count}</h4>
            </Media>
            <div className="text-primary  position-absolute top-0 end-0 p-4">
              <i className={props.icon + " fa-3x"}></i>
            </div>
          </Media>
        </CardBody>
      </Card>
    </Col>
  )
}

MiniWidgets.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
}

export default MiniWidgets