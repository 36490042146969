import React, { useEffect } from "react"

import StudentCard from './StudentCard'
import { 
    Col, Row, FormGroup, Label, Input,  

} from 'reactstrap'

import { useOutletContext } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import {
  onReadData,
  setCurrentPeriod,
  onFilterChange,
} from "features/teacher/subject/pedagogicalRecovery/pedagogicalRecoverySlice"
 import { setInicialData } from "features/teacher/subject/pedagogicalRecoveryStudent/pedagogicalRecoveryStudentSlice"

import Select from "react-select"

import LoadingCard from "./LoadingCard"



// Create a selector for the assignment state
const selectpedagogicalRecoveryState = state => state.teacher.pedagogicalRecovery

// Create a memoized selector for specific assignment properties
const selectpedagogicalRecoveryProperties = createSelector(
  [selectpedagogicalRecoveryState],
  pedagogicalRecoveryState => ({
    
    filterby: pedagogicalRecoveryState.filterby,
    period: pedagogicalRecoveryState.period,
    students: pedagogicalRecoveryState.students,
    isLoading: pedagogicalRecoveryState.isLoading,
  })
)



const PedagogicalRecovery = () => {

    const pedagogicalRecoveryState = useSelector(
      selectpedagogicalRecoveryProperties

    )
    const dispatch = useDispatch()
    const { t, subject, SubjectTeacher } = useOutletContext()
    
 const {  filterby, period, students,isLoading } = pedagogicalRecoveryState




useEffect(() => {
  const currentDate = new Date()
  const calculatedCurrentPeriod = SubjectTeacher.periodsOptions.find(period => {
    const startDate = new Date(period.start_date)
    const endDate = new Date(period.end_date)

    return startDate <= currentDate && endDate >= currentDate
  })
  if (calculatedCurrentPeriod) {
   
    dispatch(setCurrentPeriod(calculatedCurrentPeriod))
    dispatch(
      onReadData({
        subject: SubjectTeacher.subject,
        periodId: calculatedCurrentPeriod.id,
      })
    )

    // // Dispatch the fetchCurrentPeriod action to make the API call
    // dispatch(fetchCurrentPeriod())
  }
}, [dispatch, SubjectTeacher])

const handlePeriodChange = option => {
    dispatch(setCurrentPeriod(option))
    dispatch(
        onReadData({
        subject: SubjectTeacher.subject,
        periodId: option.value,
        })
    )
}
const handleFilter = (e) => {
  dispatch(onFilterChange(e.target.value))
}


const  handleStudentClick = student => {


  dispatch(setInicialData({student, periodId: period.id,studentOptions:students}))


}






  return (
    <>
      <Row className="mb-2">
        <Col sm="4">
          <FormGroup>
            <Label htmlFor="student">{t("Students")}</Label>
            <Input
              type="search"
              className="form-control"
              value={filterby}
              placeholder={t("Student filter")}
              onChange={e => handleFilter(e)}
            />
          </FormGroup>
        </Col>
        <Col sm="4">
          <FormGroup>
            <Label htmlFor="period">{t("Periods")}</Label>
            <Select
              name="period_id"
              // className="form-control"
              value={period}
              options={SubjectTeacher.periodsOptions}
              onChange={option => handlePeriodChange(option)}
            />
            {/* <FormFieldError errors={fields.period_id.errors} t={t} /> */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {isLoading ? (
          <>
            <LoadingCard key={1} />
            <LoadingCard key={2} />
            <LoadingCard key={3} />
          </>
        ) : students.length === 0 ? (
          <p>{t("No data found.")}</p>
        ) : (
          students
            .filter(student =>
              student.name
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  filterby
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            )
            .map(student => (
              <StudentCard
                key={student.id}
                student={student}
                handleStudentClick = {handleStudentClick}
              />
            ))
        )}
      </Row>
    </>
  )
}

export default PedagogicalRecovery