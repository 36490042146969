
import React, { useState, useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom"

import {generateWeeksArray} from 'helpers/time_helper'
import Select from 'react-select'

import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import {
  onReadData,
  onFieldChange,
  setWeeksOptions,
  setFieldError,
  onAddPlanningWeek,
  onFecthPlanningWeeks,
  setIsEditBiblicalIntegration,
  onFieldChangePlanForm,
  setDateApply,
  onSavePlanning,
  setValidationErrors,
  onResetPlanningForm,
  

} from "features/teacher/subject/planning/planningSlice"
import { FormGroup, Label,Button,Input } from "reactstrap"
import FormFieldError from "components/Common/FormFieldError"
import DateRangeAccordion from "./DateRangeAccordion"
import { data } from "autoprefixer";
import BibllicalIntegrationForm from "./BibllicalIntegrationForm";
import { validator } from "helpers/form_helper"
import Moment from 'moment'







const selectState = state => state.teacher?.planning

const selectProperties = createSelector([selectState], state => ({
  schoolYearOptions: state.schoolYearOptions,
  weeksOptions: state.weeksOptions,
  schoolYear: state.schoolYear,
  isLoading: state.isLoading,
  schoolYearSelected: state.schoolYearSelected,
  fields: state.fields,
  planningWeek: state.planningWeek,
  isEditBiblicalIntegration: state.isEditBiblicalIntegration,
  isLoadingPlanningWeek: state.isLoadingPlanningWeek,
  fieldsPlannings: state.fieldsPlannings,
  indicatorOptions: state.indicatorOptions,
  laesOptions: state.laesOptions,
  dateApply: state.dateApply,
  plannings: state.plannings,
  day:state.day,

}))



const Planning = () => {
  
 const { t, subject } = useOutletContext()

const State = useSelector(selectProperties)
const {
  schoolYearOptions,
  weeksOptions,
  fields,
  schoolYear,
  planningWeek,
  isEditBiblicalIntegration,
  isLoadingPlanningWeek,
  fieldsPlannings,
  isLoading,
} = State
const dispatch = useDispatch()


  useEffect(() => {
    dispatch(onReadData({subject}))
    
   
  }, [ dispatch, subject]);


  const handleFieldChange = (field, value) => {
      dispatch(onFieldChange({ field, value }))
  }

    const handleFieldChangePlanForm = (field, value) => {
      dispatch(onFieldChangePlanForm({ field, value }))
    }


  const handleGenerateWeeksArray = (schoolYearId) => {
   
    const data = schoolYear.find(
      schoolYear => schoolYear.id === schoolYearId
    )
      
    const generatedWeeksArray = generateWeeksArray(
        new Date(data.start_date),
        new Date(data.end_date)
    )
  
    dispatch(setWeeksOptions({ weeksOptions: generatedWeeksArray }))

  }

  const handleSavePlanningWeek = () => {
    const { week, biblical_integration } = fields;

    if (!week.value) {
      dispatch(setFieldError({
        fieldKey: "week",
        errors: [t("Week is required")],
      }));
    } else if (!biblical_integration.value) {
      dispatch(setFieldError({
        fieldKey: "biblical_integration",
        errors: [t("Biblical integration is required")],
      }));
    } else {
      const json = {
        id: planningWeek?.id,
        subject_id: subject.id,
        school_year_id: fields.school_year_id.value.value,
        week_no: week.value.value,
        biblical_integration: biblical_integration.value,
      }
      dispatch(
        onAddPlanningWeek({
          json,
          t,
          isEditBiblicalIntegration: isEditBiblicalIntegration,
        })
      )
    }
  };

  const handleCancelPlanningWeek = () => {

  handleFieldChange("biblical_integration", "")



  }

  const handleFecthPlanningWeeks = option => {
  
    dispatch(
      onFecthPlanningWeeks({
        subject_id: subject.id,
        school_year_id: fields.school_year_id.value.value,
        week_no: option.value,
        start_date: Moment(option.start_date).format("YYYY-M-D"),
        end_date: Moment(option.end_date).format("YYYY-M-D"),
      })
    )
  }

  const handleIsEditBiblicalIntegration = () => {
    dispatch(setIsEditBiblicalIntegration({ biblical_integration: planningWeek.biblical_integration }))
  }

  const handleSavePlanning = () => {
// onSavePlanning
 const validation = validator(State.fieldsPlannings)
    if (validation.hasErrors) {
      const fieldErrors = { ...validation.fieldErrors }
        dispatch(setValidationErrors({ fieldErrors }))
    }else{
   
 
      const json = {
        
        subject_id: subject.id,
        school_year_id: fields.school_year_id.value.value,
        week_no: fields.week.value.value,
        date_apply: Moment(State.dateApply).format("YYYY-MM-DD H:mm:ss"),
        indicator_id: fieldsPlannings.indicator_id.value.value,
        laes_id: fieldsPlannings.laes_id.value.value,
        teacher_id:subject.teacher_id,
        begins: fieldsPlannings.begins.value,
        close: fieldsPlannings.close.value,
        resources: fieldsPlannings.resources.value,
        modeling: fieldsPlannings.modeling.value,
        group: fieldsPlannings.group.value,
      }
      dispatch(onSavePlanning({ json, t ,day:State.day}))



    }




  }

  const handleCancelPlanning= () => {}

  const resetPlanForm = () => {
    dispatch(onResetPlanningForm())
  }



const handleSetDateApply = (dateApply) => {
  const formattedDateToApply = Moment(dateApply).format("YYYY-MM-DD");
  resetPlanForm();


  const day = State.plannings.find(planning => planning.date_apply === formattedDateToApply )
  if(day){  
      handleFieldChangePlanForm("indicator_id", { value: day.indicator_id, label: day.indicator_name })
      handleFieldChangePlanForm("laes_id", { value: day.laes_id, label: day.laes_name })
      handleFieldChangePlanForm("begins", day.begins)
      handleFieldChangePlanForm("close", day.close)
      handleFieldChangePlanForm("resources", day.resources)
      handleFieldChangePlanForm("modeling", day.modeling)
      handleFieldChangePlanForm("group", day.group)

      dispatch(setDateApply({ dateApply,day }));

  } else {  
  
  dispatch(setDateApply({ dateApply,day:null }));
  }
};


  return (
    <>
    
        <>
          <FormGroup>
            <Label htmlFor="activity_id">{t("School Year")}</Label>
            <Select
              // styles={selectStyles}
              name="school_year_id"
              className="form-controll"
              value={fields?.school_year_id?.value}
              options={schoolYearOptions}
              onChange={option => {
                handleGenerateWeeksArray(option.value)
                handleFieldChange("school_year_id", option)
                handleFieldChange("week", "")
                handleFieldChange("biblical_integration", "")
              }}
            />
            <FormFieldError errors={fields?.school_year_id?.errors} t={t} />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="activity_id">{t("Plan Week")}</Label>

            <Select
              name="week"
              className="form-controll"
              options={weeksOptions}
              value={fields?.week?.value}
              onChange={option => {
                handleFieldChange("week", option) /// set field.week.value
                handleFieldChange("biblical_integration", "")
                handleFecthPlanningWeeks(option)
              }}
            />
            <FormFieldError errors={fields?.week?.errors} t={t} />
          </FormGroup>
          <div className="d-flex">
            {!planningWeek || isEditBiblicalIntegration ? (
              <>
                <BibllicalIntegrationForm
                  state={State}
                  t={t}
                  handleFieldChange={handleFieldChange}
                  handleSavePlanningWeek={handleSavePlanningWeek}
                  handleCancelPlanningWeek={handleCancelPlanningWeek}
                />
              </>
            ) : (
              <>
                <FormGroup className="flex-grow-1 mr-2">
                  <Label htmlFor="biblical_integration">
                    {t("Bible Integration")}
                  </Label>
                  <Input
                    type="textarea"
                    name="biblical_integration"
                    value={planningWeek.biblical_integration}
                    className="form-control"
                    rows="6"
                    disabled={true}
                  />
                </FormGroup>

                <div>
                  <Link
                    to="#"
                    className="d-flex flex-column pt-4 m-2"
                    onClick={() => handleIsEditBiblicalIntegration()}
                  >
                    <i className="bx bx-edit" /> {t("Edit")}
                  </Link>
                </div>
              </>
            )}
          </div>
          <hr className="pt-4"></hr>
          {fields?.week?.value?.start_date && (
            <DateRangeAccordion
              state={State}
              t={t}
              handleFieldChangePlanForm={handleFieldChangePlanForm}
              handleSavePlanning={handleSavePlanning}
              handleCancelPlanning={handleCancelPlanning}
              handleSetDateApply={handleSetDateApply}
              startDate={fields?.week?.value?.start_date}
              endDate={fields?.week?.value?.end_date}
            />
          )}
        </>
    
    </>
  )
     
  
};

export default Planning;

