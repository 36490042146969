import React from 'react'
import { FormGroup, Label, Input, Button } from "reactstrap"
import FormFieldError from 'components/Common/FormFieldError'
import MyCKEditor from 'components/Common/CKeditor/MyCKEditor'



const AnnouncementsForm = (props) => {
    const { t, announcementState, handleChange, handleSubmit, handleCancel } =
      props
    const  {fields} = announcementState
  return (
    <>
      <FormGroup>
        <Label for="title">{t("Title")}</Label>
        <Input
          type="text"
          id="title"
          name="title"
          onChange={e => handleChange(e.target.name, e.target.value)}
          value={fields.title.value}
        />
        <FormFieldError errors={fields.title.errors} t={t} />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="productdesc">{t("Description")}</Label>
        <MyCKEditor
          editorData={fields.description.value}
          handleEditorChange={rawData => handleChange("description", rawData)}
        />
        <FormFieldError errors={fields.description.errors} t={t} />
      </FormGroup>
      <div className="text-center">
        <Button color="primary" onClick={handleSubmit}>
         {t("Save")}
        </Button>
        <Button className="ms-1" color="secondary" onClick={handleCancel}>
            {t("Cancel")}
        </Button>
      </div>
    </>
  )
}

export default AnnouncementsForm