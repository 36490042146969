export const Fields = {
  student_id: {
    label: "Students",
    selectLabel: "name",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "select",
  },

  date: {
    label: "Date of the incident",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "datetime",
  },

  academic_difficulties: {
    label: "Academic Difficulties",
    value: [],
    errors: [],
    rules: {
      require: true,
    },
    type: "checkbox", // Set the type to "checkbox"
    options: [
      { label: "Not applicable", value: "Not applicable" },
      { label: "Inappropriate attitude", value: "Inappropriate attitude" },
      { label: "Pending assignments", value: "Pending assignments" },
      {
        label: "Difficulty copying notes/tasks",
        value: "Difficulty copying notes/tasks",
      },
      { label: "Poor foundation", value: "Poor foundation" },
      { label: "Low-quality work", value: "Low-quality work" },
      {
        label: "Does not bring materials/books to class",
        value: "Does not bring materials/books to class",
      },

      {
        label: "Lack of effort/Motivation",
        value: "Lack of effort/Motivation",
      },
      {
        label: "Difficulty following instructions",
        value: "Difficulty following instructions",
      },
      {
        label: "Other",
        value: "Other",
      },
      {
        label: "Does not finish class assignments",
        value: "Does not finish class assignments",
      },
      {
        label: "Low test grade",
        value: "Low test grade",
      },
      // Add more options as needed
    ],
  },
  academic_difficulties_other: {
    label: "Other Academic Difficulties",
    value: "",
    errors: [],
    rules: {
      maxLength: 60,
      minLength: 3,
    },
    type: "text",
  },

  behavioral_difficulties: {
    label: "Behavioral Difficulties",
    value: [],
    errors: [],
    rules: {
      require: true,
    },
    type: "checkbox", // Set the type to "checkbox"
    options: [
      { label: "Not applicable", value: "Not applicable" },
      { label: "Talks excessively", value: "Talks excessively" },
      {
        label: "Does not respond/ignores correction",
        value: "Does not respond/ignores correction",
      },
      { label: "Excessive tardiness", value: "Excessive tardiness" },
      {
        label: "Distracts/bothers classmates",
        value: "Distracts/bothers classmates",
      },
      // { label: "Low-quality work", value: "Low-quality work" },
      { label: "Disrespects authority", value: "Disrespects authority" },
      { label: "Constantly interrupts", value: "Constantly interrupts" },
      {
        label: "Lack of attention/cooperation",
        value: "Lack of attention/cooperation",
      },
      { label: "Excessive absences", value: "Excessive absences" },
      {
        label: "Other",
        value: "Other",
      },
      // Add more options as needed
    ],
  },
  behavioral_difficulties_other: {
    label: "Other Behavioral Difficulties",
    value: "",
    errors: [],
    rules: {
      maxLength: 60,
      minLength: 3,
    },
    type: "text",
  },
  recommendations: {
    label: "Recommendations",
    value: [],
    errors: [],
    rules: {
      require: true,
    },
    type: "checkbox", // Set the type to "checkbox"
    options: [
      { label: "Talk with the student", value: "Talk with the student" },
      { label: "Afternoon assistance", value: "Afternoon assistance" },
      { label: "Put in more effort", value: "Put in more effort" },
      { label: "Meeting with the teacher", value: "Meeting with the teacher" },
      { label: "Tutoring", value: "Tutoring" },
      {
        label: "Meeting with the counselor",
        value: "Meeting with the counselor",
      },
      {
        label: "Meeting with the Principal",
        value: "Meeting with the Principal",
      },
      // Add more options as needed
    ],
  },
}
