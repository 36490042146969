export const Fields = {
  text_entry_student: {
    label: "Description",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "textarea",
  },
}
