import { createSlice } from "@reduxjs/toolkit"


const pedagogicalRecoveryStudentSlice = createSlice({
  name: "teacher/pedagogicalRecoveryStudent",
  initialState: {
    test: "test",
    data: [],
    isLoading: false,
    selectedRow: null,
    isEditing: false,
    isSaveConfirmationModal: false,
    unsavedData: null,
    visibleColumns: [],
    periodsOptions: [],
    periodId: null,
    period: null,
    isLoadingPeriods: false,
    filterby: "",
    student: null,
    accordionId: null,
    selectedStudent: null,
    studentOptions: [],
  },
  reducers: {
    onReadPeriod: state => {
      state.isLoadingPeriods = true
    },
    onReadPeriodSuccess: (state, action) => {
      state.periodsOptions = action.payload.periodsOptions
      state.isLoadingPeriods = false
    },
    setInicialData: (state, action) => {
      state.studentOptions = action.payload.studentOptions
      state.student = action.payload.student
      state.periodId = action.payload.periodId
      state.selectedStudent = {
        label: action.payload.student.name,
        value: action.payload.student.id,
        avatar: action.payload.student.avatar,
      }
    },
    setCurrentStudent: (state, action) => {
      state.selectedStudent = action.payload
    },

    setCurrentPeriod: (state, action) => {
      state.period = action.payload
      state.accordionId = null
    },
    onReadData: state => {
      state.isLoading = true
      state.filterby = ""
    },
    onReadDataSuccess: (state, action) => {
      state.isLoading = false
      state.data = action.payload
    },
    onFilterChange: (state, action) => {
      state.filterby = action.payload
    },
    setAccordionId: (state, action) => {
      state.accordionId = action.payload
    },
  },
})



export const {
  setCurrentPeriod,
  onReadPeriod,
  onReadPeriodSuccess,
  onReadData,
  onReadDataSuccess,
  onFilterChange,
  onEditStart,
  onEdit,
  onSaveConfirmationModalStart,
  onSaveConfirmationCancel,
  onToggleColumnVisibility,
  setInicialData,
  setAccordionId,
  setCurrentStudent,
} = pedagogicalRecoveryStudentSlice.actions
export default pedagogicalRecoveryStudentSlice.reducer



