import PropTypes from "prop-types"
import React,{useEffect} from "react"
import { Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n

import { onReadData,} from "features/teacher/dashboard/dashboardSlice"
import { handleSelectSubject } from "features/teacher/subject/subjectSlice"
import { useSelector, useDispatch } from "react-redux"
import {selectCurrentSubjects,selectCurrentIsloading} from "features/teacher/dashboard/dashboardSlice"
//import { selectCurrentUser } from "features/auth/login/loginSlice"
import SubjectCard from "components/Common/SubjectCard"
import { useOutletContext } from "react-router-dom"

const TeacherDashboard = props => {
  
const {t,user}= useOutletContext()
  //meta title
  document.title = "Dashboard | CCMB Tareas"
  const dispatch = useDispatch()
  const subjects = useSelector(selectCurrentSubjects)


const handleSelect =(subject)=>{

   dispatch(handleSelectSubject(subject))
}

  useEffect(() => {
  
    dispatch(onReadData({id:user?.user_id}))
  }, [user,dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={t("Dashboards")}
            breadcrumbItem={t("Dashboard")}
          />
          <Row>
            {subjects &&
              subjects.map(subject => (
                <SubjectCard
                  key={subject.id + "subject"}
                  data={subject}
                  handleSelectSubject={handleSelect}
                />
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

TeacherDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default TeacherDashboard
