import React from "react"
import { Card, CardBody, CardImg, CardText,Col } from "reactstrap"
import avatarImage from "assets/images/users/avatar-1.jpg" // Import your avatar image
import { Link } from "react-router-dom" // Import the Link component from React Router

const StudentCard = ({student,handleStudentClick}) => {
  return (
    <Col sm={4} className="mb-4 d-flex justify-content-center">
      {" "}
      {/* Centering the card */}
      <Card
        style={{ width: "100%", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
      >
        <CardBody className="d-flex flex-column align-items-center">
          {" "}
          {/* Centering the content */}
          <div className="mb-3">
            <CardImg
              src={student?.avatar || avatarImage}
              style={{ width: "100px", height: "100px", borderRadius: "50%" }}
              className="img-fluid"
            />{" "}
            {/* Centering the avatar */}
          </div>
          <Link
            to={`student/${student?.id}`}
            onClick={() => handleStudentClick(student)}
            style={{
              fontWeight: "bold",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            {" "}
            {/* Link to the specified route */}
            <CardText className="text-center">{student?.name}</CardText>{" "}
          </Link>
          <CardText
            className="text-center"
            style={{ color: "red", fontWeight: "bold" }}
          >
            Tiene {student?.specific_competence?.length} competencias por debajo
            de 70{" "}
          </CardText>
        </CardBody>
      </Card>
    </Col>
  )
}

export default StudentCard
