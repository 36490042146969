import React from "react"
import { Outlet } from "react-router-dom"
import VerticalLayout from "components/VerticalLayout/"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "store/auth/login/loginSlice"
import { withTranslation } from "react-i18next"




const CoordinatorMiddleware = (props) => {
   const user = useSelector(selectCurrentUser)
   const { t } = props



  return (
    <>
      <VerticalLayout>
    
        <Outlet context={{ t, user:user }} />
      </VerticalLayout>
    </>
  )
}


export default withTranslation()(CoordinatorMiddleware);