import React from 'react'
import { useOutletContext } from 'react-router-dom'


import { useSelector,useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { useEffect } from 'react'
import {
  onReadPeriod,
  onReadData,
  setCurrentPeriod,
  onEditStart,
  onEdit,
  onSaveConfirmationModalStart,
  onSaveConfirmationCancel,
  onFilterChange,
  onToggleColumnVisibility,
} from "features/teacher/subject/schoolGrade/schoolGradeSlice"
import Skeleton from 'react-loading-skeleton'
import SaveConfirmationModal from 'components/Common/SaveConfirmationModal'
import GradeBook from 'components/Common/GradeBook/GradeBook'


// Create a selector for the assignment state
const selectSchoolGradeState = state => state.teacher.schoolGrade

// Create a memoized selector for specific assignment properties
const selectSchoolGradeProperties = createSelector(
  [selectSchoolGradeState],
  schoolGradeState => ({
    dataTable: schoolGradeState.dataTable,
    filterby: schoolGradeState.filterby,
    isLoading: schoolGradeState.isLoading,
    selectedRow: schoolGradeState.selectedRow,
    isEditing: schoolGradeState.isEditing,
    isSaveConfirmationModal: schoolGradeState.isSaveConfirmationModal,
    visibleColumns: schoolGradeState.visibleColumns,
    periodsOptions: schoolGradeState.periodsOptions,
    period: schoolGradeState.period,
    isLoadingPeriods: schoolGradeState.isLoadingPeriods,
  })
)
const SchoolGrade = () => {
  const dispatch = useDispatch()
  const schoolGradeState = useSelector(selectSchoolGradeProperties)
  const { t, subject, SubjectTeacher } = useOutletContext()




const handleDropdownHeader = (optionName, header) => {
console.log(optionName, header)

}
const handleRowUpdate = (studentId, assignmentId, score) => {

  dispatch(onEdit({subjectId:subject.id,studentId,assignmentId,score,periodId: schoolGradeState.period.value }))
}
const handleSelectedRow=(row)=>{
   dispatch(onEditStart(row))
 
}


const handleConfimationModalStart = (actualValue, nota, assignmentId) => {
  

dispatch(onSaveConfirmationModalStart({actualValue, nota, assignmentId}))


}

const handleSaveConfirmationModal =()=>{


}

const handleCancelConfirmationModal =()=>{
  dispatch(onSaveConfirmationCancel())
};

const handleFilter = (e) => {
  dispatch(onFilterChange(e.target.value))


}
const handleToggleColumnVisibility = columnId => {
  dispatch(onToggleColumnVisibility({ columnId }))
}
useEffect(() => {
   const currentDate = new Date()
  const calculatedCurrentPeriod = SubjectTeacher.periodsOptions.find(period => {
    const startDate = new Date(period.start_date)
    const endDate = new Date(period.end_date)

     return startDate <= currentDate && endDate >= currentDate
  })
if (calculatedCurrentPeriod) {
  console.log(calculatedCurrentPeriod)
  dispatch(setCurrentPeriod(calculatedCurrentPeriod))
  dispatch(onReadData({ subject:SubjectTeacher.subject, periodId: calculatedCurrentPeriod.id }))

  // // Dispatch the fetchCurrentPeriod action to make the API call
  // dispatch(fetchCurrentPeriod())
}
}, [dispatch, SubjectTeacher])

const handlePeriodChange = option => {  
  dispatch(setCurrentPeriod(option))
dispatch(
  onReadData({
    subject: SubjectTeacher.subject,
    periodId: option.value,
  })
)
}

  return (
    <>
      {schoolGradeState.isLoading ? (
        <Skeleton count={10} height={40} />
      ) : (
        <>
          <GradeBook
            SubjectTeacher={SubjectTeacher}
            schoolGradeState={schoolGradeState}
            handleDropdownHeader={handleDropdownHeader}
            handleRowUpdate={handleRowUpdate}
            handleSelectedRow={handleSelectedRow}
            handleConfimationModalStart={handleConfimationModalStart}
            handleFilter={handleFilter}
            handleToggleColumnVisibility={handleToggleColumnVisibility}
            handlePeriodChange={handlePeriodChange}
            t={t}
            editable={true}
          />
          {/* isOpen, handleSave,handleCancel, t = key => key */}
          <SaveConfirmationModal
            isOpen={schoolGradeState.isSaveConfirmationModal}
            handleSave={handleSaveConfirmationModal}
            handleCancel={handleCancelConfirmationModal}
            t={t}
          />
        </>
      )}
    </>
  )
}

export default SchoolGrade