import React from "react";

const Pagination = ({
  paginationState,
 
 
  onPageChange,
}) => {
  const getPageNumbers = () => {
    const pageNumbers = []
    let startPage, endPage
    const maxPageNumbers = 7
    const maxPagesBeforeBreak = 3

    if (paginationState?.total_pages <= maxPageNumbers) {
      startPage = 1
      endPage = paginationState?.total_pages
    } else {
      if (paginationState?.current_page <= maxPagesBeforeBreak) {
        startPage = 1
        endPage = maxPageNumbers
      } else if (paginationState?.current_page + maxPagesBeforeBreak >= paginationState?.total_pages) {
        startPage = paginationState?.total_pages - maxPageNumbers + 1
        endPage = paginationState?.total_pages
      } else {
        startPage = paginationState?.current_page - maxPagesBeforeBreak
        endPage = paginationState?.current_page + maxPagesBeforeBreak - 1
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i)
    }

    if (endPage < paginationState?.total_pages) {
      pageNumbers.push(-1)
      pageNumbers.push(paginationState?.total_pages)
    }

    if (startPage > 1) {
      pageNumbers.unshift(1)
      pageNumbers.unshift(-1)
    }

    return pageNumbers
  }

  const renderPageNumbers = () => {
    const pageNumbers = getPageNumbers()
    return pageNumbers.map((number, index) => {
      if (number === -1) {
        return (
          <li key={index} className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        )
      }
      return (
        <li
          key={index}
          className={`page-item${number === paginationState?.current_page ? " active" : ""}`}
        >
          <button className="page-link" onClick={() => onPageChange(number)}>
            {number}
          </button>
        </li>
      )
    })
  }

  return (
    <nav>
      <ul className="pagination">
        <li className={`page-item${paginationState?.current_page === 1 ? " disabled" : ""}`}>
          <button
            className="page-link"
            onClick={() => onPageChange(paginationState?.current_page - 1)}
          >
            Previous
          </button>
        </li>
        {renderPageNumbers()}
        <li
          className={`page-item${
            paginationState?.current_page === paginationState?.total_pages ? " disabled" : ""
          }`}
        >
          <button
            className="page-link"
            onClick={() => onPageChange(paginationState?.current_page + 1)}
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  )
}

export default Pagination;