import React from 'react'
import { Link } from "react-router-dom"
import { UncontrolledTooltip, CardTitle,Button,Modal,ModalBody,ModalHeader } from "reactstrap"
import AnnouncementsList from './AnnouncementsList'
import AnnouncementsForm from './AnnouncementsForm'
const Announcements = (props) => {
  const {
     t, 
     announcementState,
      handleCancel,
      handleCreateStart
  
  } = props
  const {isModal,mode} = announcementState
  return (
    <>
      <CardTitle> {t(`Announcements`)}</CardTitle>
      {/* <Link to="#" className=" text-success float-end " onClick={() => {}}>
        <i className=" bx bx-plus font-size-18 mr-3" id="addtooltip"></i>
        <UncontrolledTooltip placement="top" target="addtooltip">
          Agregar Anuncio
        </UncontrolledTooltip>
      </Link> */}
      <div className="clearfix">
        <Button
          type="button"
          color="success"
          className=" float-end waves-effect waves-light mb-2 mr-2"
          onClick={handleCreateStart}
        >
          <i className="mdi mdi-plus mr-1"></i>
          {t("Add Announcement")}
        </Button>
      </div>

      <Modal
        size={"l"}
        isOpen={isModal}
        toggle={handleCancel}
        autoFocus={true}
        backdrop={"static"}
      >
        <ModalHeader toggle={handleCancel}> {t(mode)}</ModalHeader>
        <ModalBody>
          <AnnouncementsForm {...props} />
        </ModalBody>
      </Modal>
      <AnnouncementsList {...props} />
    </>
  )
}

export default Announcements