import React, { useEffect } from "react"

import { Link, Outlet, useOutletContext ,
    useParams
} from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import {
  onReadData,
  onFieldChange,
  onSubmit,
  onSelectSubmission,
} from "features/student/subject/assignment/assignmentViewSlice"
import { Row, Col } from "reactstrap"
import LeftSide from "./ActivityView/LeftSide"
import RightSide from "./ActivityView/RightSide"




 const selectState = state => state.student?.activity?.assigmentView

 const selectProperties = createSelector([selectState], state => ({
   assignment: state.assignment,
   submissions: state.submissions,
   isLoading: state.isLoading,
   fields: state.fields,
   isSubmitting: state.isSubmitting,
   showSubmission: state.showSubmission,
   selectedSubmission: state.selectedSubmission,
 }))





const ActivityView = () => {
    const { t, subject, user } = useOutletContext()
    const { activity_id } = useParams()

    const state = useSelector(selectProperties)
    const {  assignment } = state
    const dispatch = useDispatch()




 useEffect(() => {
   dispatch(
     onReadData({
       activity_id,
       user,
     })
   )
 }, [dispatch, activity_id])


const handleFieldChange = (field, value) => {
  dispatch(onFieldChange({ field, value }))
}

const handleSubmission = () => {

dispatch(onSubmit({ assignment, user, fields: state.fields,t }))

}
const handleDisplaySubmission = submission => {
  dispatch(onSelectSubmission(submission))
}


  return (
    <>
      <Link
        to={`/subjects/${subject?.id}/activities`}
        className="btn  d-none d-sm-inline-block btn-link"
      >
        <i className="mdi mdi-arrow-left mr-1"></i>
        {t("Back")}
      </Link>

      <Row>
        <Col xl="8">
          <Outlet context={{ state, t, handleFieldChange, handleSubmission }} />
          {/* <LeftSide
            state={state}
            t={t}
            handleFieldChange={handleFieldChange}
            handleSubmission={handleSubmission}
          /> */}
        </Col>
        <Col xl="4">
          <RightSide
            state={state}
            t={t}
            handleDisplaySubmission={handleDisplaySubmission}
          />
          {/* <div className="text-xl-right mt-xl-0 mt-4">
            <Link
              to={`/subjects/${subject.id}/activities/${activity_id}/edit`}
              className="btn btn-primary"
            >
              <i className="mdi mdi-plus-circle mr-2"></i> {t("Edit")}
            </Link>
          </div> */}
        </Col>
      </Row>
    </>
  )
}

export default ActivityView



    // <div style={{ 
    //     width: '100%',
    //     height: '100vh',
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     backgroundColor: '#f2f2f2',
    //     padding: '20px',
    //     border: '1px solid #ccc',
    //     borderRadius: '5px',
    //     overflow: 'auto' // Add this line to enable scrolling if content exceeds the container size
    // }}>
    //     {parse(
    //         selectedSubmission && selectedSubmission.text_entry_student
    //             ? selectedSubmission.text_entry_student
    //             : ""
    //     )}
    // </div>