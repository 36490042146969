


export const schoolYearEndpoints = {
  create: "/api/school-years",
  read: "/api/school-years/all",
  show: id => `/api/school-years/${id}`,
  update: id => `/api/school-years/${id}/update`,
  delete: id => `/api/school-years/${id}`,
  periods: id => `/api/school-years/${id}/periods`,

}
export const planningWeekEndpoints = {
  create: "/api/planning-weeks",
  readAll: `/api/planning-weeks/all`,
  read:  `/api/planning-weeks`, //pagination
  update: id => `/api/planning-weeks/${id}/update`,
  delete: id => `/api/planning-weeks/${id}`,
  show: id => `/api/planning-weeks/${id}`,

}

export const coordinatorEndpoints = {
  create: "/api/coordinators",
  read: id => `/api/coordinators/${id}`,
  update: id => `/api/coordinators/${id}/update`,
  delete: id => `/api/coordinators/${id}`,
  subjects: id => `/api/coordinators/${id}/subjects`,
  dashboard: id => `/api/coordinators/${id}/dashboard`,
}
export const teacherEndpoints = {
  create: "/api/teachers",
  read: id => `/api/teachers/${id}`,
  update: id => `/api/teachers/${id}/update`,
  delete: id => `/api/teachers/${id}`,
  dashboard: id => `/api/teachers/${id}/dashboard`,
  subjects: id => `/api/teachers/${id}/subjects`, // sames as dashboard
}

export const subjectEndpoints = {
  create: "/api/subjects",
  read: id => `/api/subjects/${id}`,
  update: id => `/api/subjects/${id}/update`,
  delete: id => `/api/subjects/${id}`,
  actvities: id => `/api/subjects/${id}/activities`,
  assignments: (id, query) => `/api/subjects/${id}/assignments?${query}`,
  competences: id => `/api/subjects/${id}/competences`,
  specific_competences: id => `/api/subjects/${id}/specific-competences`,
  gradebook: (id, query) => `/api/subjects/${id}/gradebook?${query}`,
  gradebookUpdate: id => `/api/subjects/${id}/gradebook`,
  benchmarks: id => `/api/subjects/${id}/benchmarks`,
  students: id => `/api/subjects/${id}/students`,
  privilege_removals: id => `/api/subjects/${id}/privilege-removals`,
  exhortation_notes: id => `/api/subjects/${id}/exhortation-notes`,
  student_assignments: (id, studentId) =>
    `/api/subjects/${id}/students/${studentId}/assignments`,
  planning_weeks: (id, query) => `/api/subjects/${id}/planning-weeks?${query}`,
  indicators: id => `/api/subjects/${id}/indicators`,
  periods: id => `/api/subjects/${id}/periods`,
  attendances: (id, query) => `/api/subjects/${id}/attendances?${query}`,
  school_grades: (id,query) => `/api/subjects/${id}/school-grades?${query}`,
  pedagogical_recovery: (id,query) => `/api/subjects/${id}/pedagogical-recovery?${query}`,
  records: (id) => `/api/subjects/${id}/records`,
  
}

export const attendanceEndpoints = {
  create: "/api/attendances",
  read: id => `/api/attendances/${id}`,
  update: id => `/api/attendances/${id}/update`,
  delete: id => `/api/attendances/${id}`,
  show: id => `/api/attendances/${id}`,
}


export const benchmarkEndpoints = {
  store: "/api/benchmarks",
  show: id => `/api/benchmarks/${id}`,
  update: id => `/api/benchmarks/${id}/update`,
  delete: id => `/api/benchmarks/${id}`,
  edit: id => `/api/benchmarks/${id}/edit`,
  competences: id => `/api/benchmarks/${id}/competences`,
}

export const competenceEndpoints = {
  store: "/api/competences",
  show: id => `/api/competences/${id}`,
  update: id => `/api/competences/${id}/update`,
  delete: id => `/api/competences/${id}`,
  indicators: id => `/api/competences/${id}/indicators`,

}
export const indicatorEndpoints = {
  store: "/api/indicators", //post
  index: "/api/indicators", //get
  show: id => `/api/indicators/${id}`,
  update: id => `/api/indicators/${id}/update`,
  delete: id => `/api/indicators/${id}`,
}

export const gradeEndpoints = {
  create: "/api/grades",
  read: id => `/api/grades/${id}`,
  update: id => `/api/grades/${id}/update`,
  delete: id => `/api/grades/${id}`,
  dashboard: id => `/api/grades/${id}/dashboard`,
  periods: id => `/api/grades/${id}/periods`,
}

export const studentEndpoints = {
  create: "/api/students",
  read: id => `/api/students/${id}`,
  update: id => `/api/students/${id}/update`,
  delete: id => `/api/students/${id}`,
  dashboard: id => `/api/students/${id}/dashboard`,
  student_assignment: (id, assignmentId) =>
    `/api/students/${id}/assignments/${assignmentId}`,
  student_assignment_submissions: (id, assignmentId) =>
    `/api/students/${id}/assignments/${assignmentId}/submissions`,
    pedagogical_recovery: (id,query) => `/api/students/${id}/pedagogical-recovery?${query}`,
}
export const submissionEndpoints = {
  create: "/api/submissions",
  read: id => `/api/submissions/${id}`,
  update: id => `/api/submissions/${id}/update`,
  delete: id => `/api/submissions/${id}`,
  show: id => `/api/submissions/${id}`,

}
export const assignmentEndpoints = {
  create: "/api/assignments",
  read: id => `/api/assignments/${id}`,
  update: id => `/api/assignments/${id}/update`,
  delete: id => `/api/assignments/${id}`,
  show: id => `/api/assignments/${id}`,
}

export const exhortationNotesEndpoints = {
  create: "/api/exhortation-notes",
  read: id => `/api/exhortation-notes/${id}`,
  update: id => `/api/exhortation-notes/${id}/update`,
  delete: id => `/api/exhortation-notes/${id}`,
  show: id => `/api/exhortation-notes/${id}`,

}

export const privilegeRemovalEndpoints = {
  create: "/api/privilege-removals",
  read: id => `/api/privilege-removals/${id}`,
  update: id => `/api/privilege-removals/${id}/update`,
  delete: id => `/api/privilege-removals/${id}`,
  show: id => `/api/privilege-removals/${id}`,
}

export const planningEndpoints = {
  create: "/api/plannings",
  read: `/api/plannings/all`,
  update: id => `/api/plannings/${id}/update`,
  delete: id => `/api/plannings/${id}`,
  show: id => `/api/plannings/${id}`,
  query: query => `/api/plannings/query?${query}`,
}

export const laesEndpoints = {
  create: "/api/laes",
  read: `/api/laes/all`,
  update: id => `/api/laes/${id}/update`,
  delete: id => `/api/laes/${id}`,
  show: id => `/api/laes/${id}`,
  
} 