// import { createStore, applyMiddleware, compose } from "redux";
// import createSagaMiddleware from "redux-saga";

// import rootReducer from "./reducers";
// import rootSaga from "./sagas";

// const sagaMiddleware = createSagaMiddleware();
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = createStore(
//   rootReducer,
//   composeEnhancers(applyMiddleware(sagaMiddleware))
// );
// sagaMiddleware.run(rootSaga);

// export default store;

// store.js
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import layoutReducer from './layout/layoutSlice'
import loginReducer from "./auth/login/loginSlice"
import forgetPasswordReducer from "./auth/forgetpwd/forgetPasswordSlice"
import resetPassowrdSlice from './auth/resetpwd/resetPassowrdSlice';


 // Import your slice(s) here
import rootSaga from './sagas';
import gradesSlice from './store-admin/grades/gradesSlice';
import teacherReducers from 'features/teacher/teacherReducers';
import studentReducers from 'features/student/studentReducers';
import coordinatorReducers from 'features/coordinator/coordinatorReducers';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    layout: layoutReducer,
    login: loginReducer,
    ForgetPassword: forgetPasswordReducer,
    ResetPassword: resetPassowrdSlice,
    // other reducers
    grades:gradesSlice,
    teacher:teacherReducers,
    student:studentReducers,
    coordinator:coordinatorReducers,
  },
  middleware: [sagaMiddleware],
  devTools: process.env.REACT_APP_NODE_ENV !== "production",
})

sagaMiddleware.run(rootSaga);

export default store;