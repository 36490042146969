import PropTypes from 'prop-types';
import React from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Routes, Route } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./routes";
// Import all middleware
import Authmiddleware from "./routes/route";
import NonAuthLayout from "./components/NonAuthLayout";
// Import scss
import "./assets/scss/theme.scss";
import "react-loading-skeleton/dist/skeleton.css"
import "react-datepicker/dist/react-datepicker.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "assets/css/custom_styles.css"
import "react-toastify/dist/ReactToastify.css"

import { selectCurrentUser } from 'store/auth/login/loginSlice';
import MissingPage from 'pages/MissingPage';

import AdminRoutes from "routes/AdminRoutes";
import TeacherRoutes from 'routes/TeacherRoutes';
import CoordinatorRoutes from 'routes/CoordinatorRoutes';
import StudentRoutes from 'routes/StudentRoutes';
import { ToastContainer } from "react-toastify"
const App = () => {

 const user = useSelector(selectCurrentUser)

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
          />
        ))}

        {user?.role_id === "1" && AdminRoutes}

        {user?.role_id === "2" && CoordinatorRoutes}

        {user?.role_id === "3" && TeacherRoutes}
        {user?.role_id === "4" && StudentRoutes}

        <Route path="*" element={<MissingPage />} />
      </Routes>
      <ToastContainer />
    </React.Fragment>
  )
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;