export const dataTable = {
  columns: [
    {
      label: "Estudiante",
      field: "student_name",
    },
    {
      label: "Fecha de la falta",
      field: "date",
    },

    {
      label: "Vista por el padre/tutor",
      field: "parent_confirmation",
    },

    {
      label: "Opciones",
      field: "options",
    },
  ],
  rows: [],
}
