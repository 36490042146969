import { createSlice } from "@reduxjs/toolkit"
import {TableSchema} from "./tableSchema"

const assignmentListSlice = createSlice({
  name: "student/activity/assignmentList",
  initialState: {
    isModal: false,
    mode: null,
    isModalDelete: false,
    searchTerm: "",
    pageNumber: 1,
    isSubmitting: false,
    isLoading: false,
    assignments: null,
    dataTable: { ...TableSchema },
    status: "0",
  },
  reducers: {
    onCreateStart: state => {
      state.isModal = !state.isModal
      state.mode = "create"
    },

    onCreateCancel: state => {
      state.isModal = !state.isModal
      state.mode = null
    },
    onReadData: state => {
      state.isLoading = true
    },
    onReadDataSuccess: (state, action) => {
      state.isLoading = false
      state.dataTable.rows= action.payload
    },
    handleStatusChange: (state, action) => {
      state.status = action.payload
    },
  },
})

export const {
  onCreateStart,
  onCreateCancel,
  onReadData,
  onReadDataSuccess,
  handleStatusChange,
} = assignmentListSlice.actions
export default assignmentListSlice.reducer



