export const Fields = {
  title: {
    label: "Title",
    value: "",
    errors: [],
    rules: {
      require: true,
      maxLength: 30,
    },
    type: "text",
  },
  due_date: {
    label: "Due Date",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "datetime",
  },
  activity_id: {
    label: "Type",
    selectLabel: "type_name",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "select",
  },
  period_id: {
    label: "Period",
    selectLabel: "period_name",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "select",
  },
  submission_type_id: {
    label: "Submit Type",
    selectLabel: "submit_type_name",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "select",
  },
  description: {
    label: "Description",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "text",
  },

  competence_id: {
    label: "Competence",
    selectLabel: "name",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "select",
  },
  specific_competence_id: {
    label: "Specific Competence",
    selectLabel: "name",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "select",
  },
  indicator_id: {
    label: "Indicator",
    selectLabel: "name",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "select",
  },
  publication_date: {
    label: "Public Date",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "datetime",
  },
  //   assignment_indicator_id: {
  //     label: "Indicator",
  //     selectLabel: "indicator_name",
  //     value: "",
  //     errors: [],
  //     rules: {
  //       require: true,
  //     },
  //     type: "select",
  //   },

  //   files: {
  //     label: "Archivos",
  //     rules: {},
  //     value: [],
  //     errors: [],
  //     type: "file",
  //   },
}
