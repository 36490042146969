// gradesSagas.js

import { takeLatest, call, put } from "redux-saga/effects"
import {
  createGrade,
  readGrades,
  updateGrade,
  deleteGrade,
  setError,
} from "./gradesSlice" // Import your Redux Toolkit actions
//import * as api from "./api" // Replace 'api' with your API service module
import { apiClient } from "api/axios"
// Worker saga for creating a new grade
// function* createGradeWorker(action) {
//   try {
//     const newGrade = yield call(api.createGrade, action.payload)
//     yield put(createGrade(newGrade))
//   } catch (error) {
//     yield put(setError(error.message))
//   }
// }

// Worker saga for reading grades
function* readGradesWorker() {
  try {
   
    const grades = yield call(apiClient.get,'api/admin/courses') // Replace with your API call
    console.log(grades)
    //yield put(readGrades(grades))
  } catch (error) {
     console.log("dentro de grades")
    console.log(error)
   // yield put(setError(error.message))
  }


}

// // Worker saga for updating a grade
// function* updateGradeWorker(action) {
//   try {
//     const updatedGrade = yield call(api.updateGrade, action.payload)
//     yield put(updateGrade({ id: action.payload.id, updatedGrade }))
//   } catch (error) {
//     yield put(setError(error.message))
//   }
// }

// // Worker saga for deleting a grade
// function* deleteGradeWorker(action) {
//   try {
//     yield call(api.deleteGrade, action.payload)
//     yield put(deleteGrade(action.payload))
//   } catch (error) {
//     yield put(setError(error.message))
//   }
// }

// Watcher saga to listen for specific actions and trigger worker sagas
export default function* watchGradeSagas() {
//   yield takeLatest("grades/createGrade", createGradeWorker)
  yield takeLatest("grades/readGrades", readGradesWorker)
//   yield takeLatest("grades/updateGrade", updateGradeWorker)
//   yield takeLatest("grades/deleteGrade", deleteGradeWorker)
}
