import React from "react";
const getField = (row, getRow, header, onSubmit, onChange) => {
  let component = "";

  if (header?.features) {
    const features = header.features;
    const { edit, avatar, type } = features;

    if (edit && type === "text") {
      component = (
        <form onSubmit={(e) => onSubmit(e)}>
          <input
            className="input_edit"
            dataid={row.id}
            dataname={header.field}
            type={type ? type : "text"}
            name={header.field}
            defaultValue={row[header.field]}
          />
          {/* {row[header.field] && <i className="fa fa-check success" aria-hidden="true" />
          } */}
        </form>
      );
    } else if (edit && type === "radio") {
      component = (
        <input
          // className="input_edit"
          name={row.compmarks_id}
          value={header.field}
          checked={row[header.field]}
          type={type}
          onChange={(e) => onChange(e)}
        />
      );
    } else if (avatar) {
      component = (
        <>
          <img
            src={row.avatar}
            alt="profile"
            className="rounded-circle header-profile-user mr-1 "
          />
          {row[header.field]}
        </>
      );
    }
  } else if (header.field === "options") {
    component = (
      <>
        <button className="icon-button" onClick={() => getRow("edit", row)}>
          <i className="fas fa-edit"></i>
        </button>
        <button
          className="icon-button danger-button ms-1"
          onClick={() => getRow("delete", row)}
        >
          <i className="fas fa-trash"></i>
        </button>
      </>
    )
  } //end of features
  else {
    // default
    component = row[header.field];
  }
  return <>{component}</>;
};
const TableBody = ({ rows,getRow ,columns, onSubmit, onChange }) => {
  let tRowsView =
    rows &&
    rows.map((row, rIndex) => {
      let tData = columns.map((header, hIndex, arr) => {
        return (
          <td key={hIndex} width={header?.width}>
            {getField(row, getRow, header, onSubmit, onChange)}
          </td>
        );
      });
      return <tr key={rIndex}>{tData}</tr>;
    });
  return <>{tRowsView}</>;
};
export default TableBody;
