import { createSlice } from "@reduxjs/toolkit"



const schoolGradeSlice = createSlice({
  name: "student/schoolGrade",
  initialState: {
  
    isLoading: false,
    periodsOptions: [],
    period: "null",
    isLoadingPeriods: false,
    schoolGrades: null,
    open:"1",
    totalScore:null
  },
  reducers: {
    onReadPeriod: state => {
      state.isLoadingPeriods = true
    },
    onReadPeriodSuccess: (state, action) => {
      state.periodsOptions = action.payload.periodsOptions
      state.isLoadingPeriods = false
    },
    setCurrentPeriod: (state, action) => {
      state.period = action.payload
    },
    setOpen: (state,action) => {
      state.open = action.payload

    },
    onReadData: state => {
      state.isLoading = true
      state.totalScore=null
    },
    onReadDataSuccess: (state, action) => {
      state.schoolGrades = action.payload.activities
       state.totalScore = action.payload.grand_total_score

      state.isLoading = false
    },
  },
})



export const {
  setCurrentPeriod,
  onReadPeriod,
  onReadPeriodSuccess,
  onReadData,
  onReadDataSuccess,

  onFilterChange,
  onEditStart,
  onEdit,
  onSaveConfirmationModalStart,
  onSaveConfirmationCancel,
  onToggleColumnVisibility,
  setOpen,
} = schoolGradeSlice.actions
export default schoolGradeSlice.reducer



