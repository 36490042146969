import { createSlice } from "@reduxjs/toolkit"

const gradesSlice = createSlice({
  name: "grades",
  initialState: {
    grades: [], // Initial state with an empty array to store grades
    error: null, // To handle potential errors
  },
  reducers: {
    // Action to create a new grade
    createGrade: (state, action) => {
      state.grades.push(action.payload)
    },

    // Action to read (get) grades
    readGrades: (state, action) => {
      state.grades = action.payload
    },

    // Action to update a grade
    updateGrade: (state, action) => {
      const { id, updatedGrade } = action.payload
      const index = state.grades.findIndex(grade => grade.id === id)
      if (index !== -1) {
        state.grades[index] = updatedGrade
      }
    },

    // Action to delete a grade
    deleteGrade: (state, action) => {
      const idToDelete = action.payload
      state.grades = state.grades.filter(grade => grade.id !== idToDelete)
    },

    // Action to handle potential errors
    setError: (state, action) => {
      state.error = action.payload
    },
  },
})

export const { createGrade, readGrades, updateGrade, deleteGrade, setError } =
  gradesSlice.actions

export default gradesSlice.reducer
