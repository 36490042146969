import React, { useEffect, useRef, useState } from "react"
import { createSelector } from "reselect"
import { useSelector, useDispatch } from "react-redux"
import { useOutletContext } from "react-router-dom"
import { onReadData } from "features/teacher/subject/record/recordSlice"
import RecordTable from "./RecordTable"
import { useReactToPrint } from "react-to-print"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

// Create a selector for the record state
const selectRecordState = state => state.teacher.record

// Create a memoized selector for specific record properties
const selectRecordProperties = createSelector(
  [selectRecordState],
  recordState => ({
    data: recordState.data,
    isLoading: recordState.isLoading,
  })
)

const Record = () => {
  const [zoomLevel, setZoomLevel] = useState(1)
  const dispatch = useDispatch()
  const recordState = useSelector(selectRecordProperties)
  const { data, isLoading } = recordState
  const { t, subject, SubjectTeacher } = useOutletContext()

  const [contentReady, setContentReady] = useState(false)
  const [modalOpen, setModalOpen] = useState(false) // State to control modal visibility

  useEffect(() => {
    dispatch(onReadData({ subject: SubjectTeacher.subject }))
  }, [dispatch, SubjectTeacher.subject])

  const componentRef = useRef()
  const modalComponentRef = useRef(null)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      return new Promise(resolve => {
        const interval = setInterval(() => {
          if (!isLoading) {
            setContentReady(true)
            clearInterval(interval)
            resolve()
          }
        }, 100)
      })
    },
  })

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const handlePrintButtonClick = () => {
    setZoomLevel(1) 
    handlePrint()
  }
  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1)
  }

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 0.1)
  }

  const handleResetZoom = () => {
    setZoomLevel(1) // Reset zoom level to 1 (original size)
  }


  return (
    <div>
      <div className="row mb-3">
        <div className="col">
          <button
            className="btn btn-primary  me-2"
            onClick={toggleModal}
            disabled={isLoading}
          >
            <i className="bx bx-folder-open"></i> Abrir Modal
          </button>
          <button
            className="btn btn-secondary  me-2"
            onClick={handlePrintButtonClick}
            disabled={isLoading}
          >
            <i className="bx bx-printer"></i> Imprimir
          </button>

          <button className="btn btn-primary me-2" onClick={handleZoomIn}>
            <i className="bx bx-zoom-in"></i> Acercar
          </button>
          <button className="btn btn-primary me-2" onClick={handleZoomOut}>
            <i className="bx bx-zoom-out"></i> Alejar
          </button>
          <button className="btn btn-primary" onClick={handleResetZoom}>
            Restablecer Zoom
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {isLoading ? (
            <p>Cargando...</p>
          ) : (
            data && (
              <RecordTable
                ref={componentRef}
                data={data}
                subject={SubjectTeacher.subject}
                zoomLevel={zoomLevel}
              />
            )
          )}
        </div>
      </div>

      <Modal isOpen={modalOpen} toggle={toggleModal} size="xl">
        {" "}
        {/* Establecer tamaño como "xl" para extra grande */}
        <ModalHeader toggle={toggleModal}>Reporte por competencias</ModalHeader>
        <ModalBody>
          <RecordTable
            ref={modalComponentRef}
            data={data}
            subject={SubjectTeacher.subject}
            zoomLevel={0.4}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default Record
