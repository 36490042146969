import { all, fork } from "redux-saga/effects";


import AuthSaga from "./auth/login/loginSaga";
import ForgetSaga from "./auth/forgetpwd/saga";

import LayoutSaga from "./layout/saga";
import ResetPasswordSaga from "./auth/resetpwd/resetPasswordSaga"
import watchGradeSagas from "./store-admin/grades/gradesSagas";
import teacherSagas from "features/teacher/teacherSagas";
import studentSagas from "features/student/studentSagas";
import coordinatorSagas from "features/coordinator/coordinatorSagas";
export default function* rootSaga() {
  yield all([
    //public
   
    fork(AuthSaga),
    fork(ForgetSaga),
  
    fork(LayoutSaga),
    fork(ResetPasswordSaga),
//resources
fork(watchGradeSagas),
fork(teacherSagas),
fork(studentSagas),
fork(coordinatorSagas),



  ]);
}
