import React from "react";
import { Navigate } from "react-router-dom";

// Profile
//import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/LoginPage";
import Logout from "../pages/Authentication/LogoutPage";
//import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPasswordPage from "pages/Authentication/ResetPasswordPage";

// Dashboard
import AdminDashboard from "pages/AdminPages/AdminDashboard/AdminDashboard";
import TeacherDashboard from "pages/TeacherPages/TeacherDashboard/TeacherDashboard";
import CoordinatorDashboard from "pages/CoordinatorPages/CoordinatorDashboard/CoordinatorDashboard";
import StudentDashboard from "pages/StudentPages/StudentDashboard/StudentDashboard";
import PersistPage from "pages/PersistPage";
import MissingPage from "pages/MissingPage";
import TeacherSujbect from "pages/TeacherPages/TeacherSubject/TeacherSujbect";
import Planning from "pages/TeacherPages/TeacherSubject/Planning/Planning";
const authProtectedRoutes = [
  //admin routes
  { path: "/dashboard", role_id: "1", component: <AdminDashboard /> },
  //coordinator routes
  { path: "/dashboard", role_id: "2", component: <CoordinatorDashboard /> },
  //teacher routes
  { path: "/dashboard", role_id: "3", component: <TeacherDashboard /> },

  {
    path: "/subjects",
    role_id: "3",
    component: <TeacherSujbect />,
    children: [
      {
        path: "planning",
        element: <Planning />,
        // loader: contactLoader,
      },
    ],
  },

  //student routes
  { path: "/dashboard", role_id: "4", component: <StudentDashboard /> },

  // //profile
  // { path: "/profile", component: <UserProfile /> },
  { path: "/persist", role_id: "any", component: <PersistPage /> },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    role_id: "any",
   
    component: <Navigate to="/persist" />,
  },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/reset-password/:token", component: <ResetPasswordPage /> },
  { path: "*", component: <Login /> },
  // { path: "*", component: <MissingPage /> },
  // { path: "/register", component: <Register /> },
]

export { authProtectedRoutes, publicRoutes };
