// dashboardSlice.js
import { createSlice } from "@reduxjs/toolkit"

const dashboardSlice = createSlice({
  name: "student/dashboard",
  initialState: {
    subjects: null,
    isLoading: false,
  },
  reducers: {
    onReadData: state => {
      state.isLoading = true
    },
    onReadDataSuccess: (state,action) => {
      state.isLoading = false
      state.subjects = action.payload
    },
  },
})

export const { onReadData, onReadDataSuccess } = dashboardSlice.actions
export default dashboardSlice.reducer

  
export const selectCurrentSubjects = state => state.student.dashboard.subjects
export const selectCurrentIsloading = state => state.student.dashboard.isLoading
