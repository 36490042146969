// loginSlice.js
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  email: "",
  error: "",
  loading: false,
  isUserLogout: false,
  resetPwdSuccessMsg: null,
  isRedirecting: false,
  seconds: 5,
}

const resetPassowrdSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    getEmailRequest: state => {
      state.isLoading = true
      state.error = null
    },
    getEmailSuccess: (state, action) => {
      state.email = action.payload
      state.isLoading = false
    },
    getEmailFailure: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    onResetPassword: state => {
      state.loading = true
    },

    onResetPasswordSuccess: (state, action) => {
      state.resetPwdSuccessMsg = action.payload
    },

    onResetPasswordError: (state, action) => {
      state.error = action.payload
      state.loading = false
      state.isUserLogout = false
    },
    startCountdown: state => {
      state.isRedirecting = true
    },
    decrementCountdown: state => {
      if (state.seconds > 0) {
        state.seconds -= 1
      }
    },
    cancelCountdown: state => {
      state.isRedirecting = false
      state.seconds = 5 // Reset the countdown timer
      state.resetPwdSuccessMsg=null
    },
  },
})

export const {
  getEmailRequest,
  getEmailSuccess,
  getEmailFailure,
  onResetPassword,
  onResetPasswordSuccess,
  onResetPasswordError,
  startCountdown,
  decrementCountdown,
  cancelCountdown,
} = resetPassowrdSlice.actions

export default resetPassowrdSlice.reducer
