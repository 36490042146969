export const Fields = {
  school_year_id: {
    label: "School Year",
    selectLabel: "name",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "select",
  },
  week: {
    label: "Plan Week",
    selectLabel: "name",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "select",
  },
  biblical_integration: {
    label: "Bible Integration",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "textarea",
  },
 
}

	// shool_year_id Índice	
	// 3	name	
	// 4	indicator_id Índice	
	// 5	begins	
	// 6	modeling	
	// 7	group	
	// 8	close		
	// 9	resources		



export const FieldsPlanning = {
  indicator_id: {
    label: "Indicators",
    selectLabel: "name",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "select",
  },
  laes_id: {
    label: "Laes",
    selectLabel: "name",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "select",
  },
  begins: {
    label: "Begin",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "textarea",
  },
  modeling: {
    label: "Modeling",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "textarea",
  },
  group: {
    label: "Group",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "textarea",
  },
  close: {
    label: "Close",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "textarea",
  },
  resources: {
    label: "Resources",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "textarea",
  },
  
}


