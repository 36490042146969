import React from 'react'
import { CardTitle, Media } from "reactstrap"
import SimpleBar from "simplebar-react"
import Moment from 'moment'
import { Link } from "react-router-dom"



const RightSide = (props) => {

const { state, t, handleDisplaySubmission } = props
const {submissions,isLoading,assignment} = state
  return (
    <>
      <CardTitle> {t("Submissions")} </CardTitle>
      <SimpleBar style={{ maxHeight: "280px" }}>
        <ul className="list-group">
          {submissions?.map((submission, index) => {
            return (
              <li
                key={submission.id}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                <Media>
                  <Media body>
                    <h5 className="font-size-14">
                      {`#${index + 1}`}
                      <Link
                        to={`/subjects/${assignment.subject_id}/activities/${assignment.id}/submissions/${submission.id}`}
                        className=" font-weight-bold"
                        onClick={() => {
                          handleDisplaySubmission(submission)
                        
                        }}
                      >
                        {Moment(submission.created_at).format("LLLL")}
                      </Link>

                      <hr></hr>
                      {submission?.text_entry_teacher && (
                        <Link
                          to="#"
                          className=" font-weight-bold text-danger text-justify "
                          onClick={() => {

                            console.log("clik 48")
                          }}
                        >
                          {t("See corrections")}
                        </Link>
                      )}
                      {submission?.submission_type_name !== "No entregable" &&
                      new Date(submission.created_at) >
                        new Date(assignment.student_assignment_due_date) ? (
                        <p className="text-danger">{t("Submitted late")}</p>
                      ) : null}
                    </h5>
                    <p className="text-muted">{submission?.teacher_notes}</p>
                  </Media>
                </Media>
              </li>
            )
          })}
        </ul>
      </SimpleBar>
      <hr></hr>
      {assignment?.score !==null && (
        <h5>
          <strong>{t('Grade')}:</strong> {assignment?.score}
        </h5>
      )}
    </>
  )
}

export default RightSide