import React from "react"
import { Card, CardBody, CardTitle } from "reactstrap"

import Skeleton from "react-loading-skeleton"
const CardLoader = props => {
  return (
    <React.Fragment>
      {Array(15)
        .fill(0)
        .map((_, i) => (
          <Card key={i} className="ml-1" style={{ width: "12.5rem" }}>
            <Skeleton height={160} className="card-img-top rounded-top" />
            {/* <img
                src={require(`../../assets/images/subject/${report.subject_image}`)}
                className="card-img-top rounded-top"
                alt={report.subject_name}
              /> */}
            <CardBody className="p-0 m-0">
              <CardTitle className="m-0">
                <Skeleton width={80} className="p-0 mt-0" />
              </CardTitle>
            </CardBody>
            <div className="d-flex justify-content-between ">
              <div
                className="btn  noti-icon waves-effect"
                tag="button"
                id={"Tareas_"}
              >
                <i className="bx bx-book "></i>
              </div>
              <div
                className="btn  noti-icon waves-effect "
                tag="button"
                id={"Announcements_"}
              >
                <i className="bx bxs-megaphone text-secundary"></i>
              </div>
              <div className="text-sm-right mt-4 mt-sm-0">
                <Skeleton circle={true} height={40} width={40} />
                <p className="text-muted mb-0 pr-2">
                  <Skeleton width={60} />
                </p>
              </div>
            </div>
          </Card>
        ))}
    </React.Fragment>
  )
}

export default CardLoader
