import React from "react"
import PropTypes from "prop-types"
import { MDBDataTableV5 } from "mdbreact"
import Skeleton from "react-loading-skeleton"
import { TableSchema } from "features/teacher/subject/assignment/tableSchema"
const DataTable = props => {
  const {  data,getRow, loading, options, thead } = props
  return (
    <>
      {loading ? (
        <Skeleton count={11} height={35} /> // 10 rows
      ) : (
        <MDBDataTableV5
          searchTop
          searchBottom={false}
          data={{...data}}
        />
      )}
    </>
  )
}

DataTable.propTypes = {
  t: PropTypes.func,
  getRow: PropTypes.func,
  data: PropTypes.any,
  thead: PropTypes.any,
  options: PropTypes.func,
}

export default DataTable
