import { combineReducers } from "@reduxjs/toolkit"
import dashboardReducer from './dashboard/dashboardSlice'
import subjectSlice from "./subject/subjectSlice"
import schoolGradeReducers from "./subject/schoolGrade/schoolGradeSlice"

// import assignmentSlice from "./subject/assignment/assignmentSlice"

import assignmentReducers from "./subject/assignment/assignmentReducers"
const studentReducers = combineReducers({
  dashboard: dashboardReducer,
  subject: subjectSlice,
  activity: assignmentReducers,
  schoolGrade: schoolGradeReducers,

  // Add other reducers as needed
})

export default studentReducers