import React, { useEffect } from "react"

import { Container, Row, Col, Card, CardBody } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"


import FilterSubjectByGrade from "components/Common/Filters/FilterSubjectByGrade"
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import {
  setSelectedGrade,
  setSubjectOptions,
  setSelectedSubject,
  onReadSubjects,
  onReadData,

  onFilterChange,
  onEditStart,
  onSaveConfirmationModalStart,
  onSaveConfirmationCancel,
  onToggleColumnVisibility,

} from "features/coordinator/schoolGrade/schoolGradeSlice"
import GradeBook from "components/Common/GradeBook/GradeBook"
import SaveConfirmationModal from "components/Common/SaveConfirmationModal"
import { useOutletContext } from "react-router-dom"


const selectSchoolGradeState = state => state.coordinator.schoolGrade
const selectSchoolGradeProperties = createSelector(
  [selectSchoolGradeState],
  schoolGradeState => ({
    dataTable: schoolGradeState.dataTable,
    filterby: schoolGradeState.filterby,
    isLoading: schoolGradeState.isLoading,
    selectedRow: schoolGradeState.selectedRow,
    isEditing: schoolGradeState.isEditing,
    isSaveConfirmationModal: schoolGradeState.isSaveConfirmationModal,
    visibleColumns: schoolGradeState.visibleColumns,
    //new
    selectedGrade: schoolGradeState.selectedGrade,
    gradeOptions: schoolGradeState.gradeOptions,
    subjectOptions: schoolGradeState.subjectOptions,
    selectedSubject: schoolGradeState.selectedSubject,
    subjects: schoolGradeState.subjects,
    subject: schoolGradeState.subject,
    //end new
  })
)

const SchoolGrade = () => {
   const { t, user } = useOutletContext()
   console.log(user)
const dispatch = useDispatch()
const schoolGradeState = useSelector(selectSchoolGradeProperties)
const { subjects, subject } = schoolGradeState
const handleSelectGrades = (selectedGrade) => {
  dispatch(setSelectedGrade(selectedGrade))

  const subjectsForGrade = subjects.filter(
    subject => subject.grade_id === selectedGrade.value
  )
  const options = subjectsForGrade.map(subject => ({
    value: subject.id,
    label: `${subject.name} - ${subject.teacher_name}`,
  }))
  dispatch(setSubjectOptions(options))

}
const handleChangeSelect = (selectedSubject) => {
const option ={value: selectedSubject.value,
      label: selectedSubject.label}
  dispatch(
    setSelectedSubject({
      option,
      subject: subjects.find(subject => subject.id === selectedSubject.value),
    })
  )

}
 useEffect(() => {
   dispatch(onReadSubjects({ id: user.user_id }))
 }, [user])

 useEffect(() => {
 
if (subject !== null ){

    dispatch(onReadData({ subjectId: subject.id }))
}
   
   

 }, [subject])

//**grdebook methods */
const handleDropdownHeader = (optionName, header) => {
  console.log(optionName, header)
}
const handleRowUpdate = (studentId, assignmentId, score) => {
  dispatch(onEdit({ subjectId: subject.id, studentId, assignmentId, score }))
}
const handleSelectedRow = row => {
  dispatch(onEditStart(row))
}

const handleConfimationModalStart = (actualValue, nota, assignmentId) => {
  dispatch(onSaveConfirmationModalStart({ actualValue, nota, assignmentId }))
}

const handleSaveConfirmationModal = () => {}

const handleCancelConfirmationModal = () => {
  dispatch(onSaveConfirmationCancel())
}

const handleFilter = e => {
  dispatch(onFilterChange(e.target.value))
}
const handleToggleColumnVisibility = columnId => {
  dispatch(onToggleColumnVisibility({ columnId }))
}
//**end gradebook methods */

  return (
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumb */}
        <Breadcrumbs
          title={t("Dashboards")}
          breadcrumbItem={t("School Grade")}
        />

        <FilterSubjectByGrade
          selectedGrade={schoolGradeState.selectedGrade}
          gradeOptions={schoolGradeState.gradeOptions}
          subjectOptions={schoolGradeState.subjectOptions}
          selectedSubject={schoolGradeState.selectedSubject}
          handleSelectGrades={handleSelectGrades}
          handleSelectSubjecs={handleChangeSelect}
          t={t}
        />

        <Row>
          <Card className="p-4">
            <div className="">
              <GradeBook
                schoolGradeState={schoolGradeState}
                handleDropdownHeader={handleDropdownHeader}
                handleRowUpdate={handleRowUpdate}
                handleSelectedRow={handleSelectedRow}
                handleConfimationModalStart={handleConfimationModalStart}
                handleFilter={handleFilter}
                handleToggleColumnVisibility={handleToggleColumnVisibility}
                t={t}
                editable={false}
              />
            </div>
            {/* isOpen, handleSave,handleCancel, t = key => key */}

            <SaveConfirmationModal
              isOpen={schoolGradeState.isSaveConfirmationModal}
              handleSave={handleSaveConfirmationModal}
              handleCancel={handleCancelConfirmationModal}
              t={t}
            />
          </Card>
        </Row>
      </Container>
    </div>
  )
}

export default  SchoolGrade