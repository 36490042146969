import React from "react"

const FormFieldError = ({ errors, t = error => error }) => {
  if (errors.length === 0) {
    return null // No errors, so don't render anything
  }

  return (
    <small className="text-danger font-weight-bold">
      <ul className="error-list">
        {errors.map((error, index) => (
          <li key={index} className="error-item">
            {t(error)}
          </li>
        ))}
      </ul>
    </small>
  )
}

export default FormFieldError
