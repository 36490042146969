export const Fields = {
  title: {
    label: "Title",
    value: "",
    errors: [],
    rules: {
      require: true,
      maxLength: 30,
    },
    type: "text",
  },
  
  description: {
    label: "Description",
    value: "",
    errors: [],
    rules: {
      require: true,
    },
    type: "text",
  },

 
  //   assignment_indicator_id: {
  //     label: "Indicator",
  //     selectLabel: "indicator_name",
  //     value: "",
  //     errors: [],
  //     rules: {
  //       require: true,
  //     },
  //     type: "select",
  //   },

  //   files: {
  //     label: "Archivos",
  //     rules: {},
  //     value: [],
  //     errors: [],
  //     type: "file",
  //   },
}
