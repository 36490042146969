import { takeLatest, call, put, delay, select } from "redux-saga/effects"
import {
  getEmailRequest,
  getEmailSuccess,
  getEmailFailure,
  onResetPassword,
  onResetPasswordError,
  onResetPasswordSuccess,
  startCountdown,
  decrementCountdown,
  cancelCountdown,
} from "./resetPassowrdSlice"

import axios from "../../../helpers/api_helper"
function* fetchEmailFromToken({ payload: { token, navigate } }) {
  try {
  
const response = yield call(axios.get, `get-email-from-token/${token}`);
    if (!response) {
      throw new Error("An error occurred")
    }

    yield put(getEmailSuccess(response.data.email))
  } catch (error) {
  
    if (error.isAxiosError) {
         yield put(startCountdown({navigate}))
      if (error.response) {
        const { status, data } = error.response
        if (status === 400) {
          yield put(onResetPasswordError(data.messages.error))
        } else {
          yield put(onResetPasswordError("An error occurred 46"))
        }
      }
    } else {
      yield put(onResetPasswordError("An error occurred"))
    }
   
  }
}

function* workerResetPassord({payload:{user,navigate}}) {
  try {
    
    const response = yield call(axios.post, "api/reset-password", user);
   


     if (!response) {
      throw new Error("An error occurred")
    }
    yield put(onResetPasswordSuccess(response.data.message))
    yield put(startCountdown({navigate}))
  } catch (error) {
    if (error.isAxiosError) {
      if (error.response) {   
        const { status, data } = error.response
        if (status === 401) {
          yield put(onResetPasswordError(data.messages.error))
        
        } else if (status === 400) { //fail validation
        
           
          yield put(onResetPasswordError(data.messages))
        }
        else {
         
          yield put(onResetPasswordError("An error occurred"))
        }
      }
    } else {
      yield put(onResetPasswordError("An error occurred"))
    }
   
  }
}
function* workerCountdownSaga({ payload:{navigate} }) {
  while (true) {
    yield put(decrementCountdown())
    yield delay(1000) // Wait for 1 second
    const { seconds } = yield select(state => state.ResetPassword)
    if (seconds === 0) {
      yield put(cancelCountdown()) // Cancel the countdown
       navigate("/login"); // Redirect to the login page
      break
    }
  }
}


function* ResetPasswordSaga() {
  yield takeLatest(getEmailRequest.type, fetchEmailFromToken)
yield takeLatest(onResetPassword.type, workerResetPassord)
yield takeLatest(startCountdown.type, workerCountdownSaga)
  
}

export default ResetPasswordSaga
