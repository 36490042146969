import React from 'react'
import {
  Outlet,
  useOutletContext,
  NavLink as RRNavLink,
} from "react-router-dom"
import {
    Container,
  Nav,
  NavItem,

  NavLink,
} from "reactstrap"
const MainSchoolGrade = () => {

    const { t, subject, SubjectTeacher } = useOutletContext()
const navLinkClassName = ({ isActive, isPending }) =>
  (isPending ? "pending " : "") + (isActive ? "active " : "")
   
  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            tag={RRNavLink}
            to={`/subjects/${SubjectTeacher?.subject?.id}/school-grades`}
            className={navLinkClassName}
          >
            {t("School grades")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag={RRNavLink}
            to={`/subjects/${SubjectTeacher?.subject?.id}/school-grades/pedagogical-recovery`}
            className={navLinkClassName}
          >
            {t("Pedagogical Recovery")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag={RRNavLink}
            to={`/subjects/${SubjectTeacher?.subject?.id}/school-grades/record`}
            className={navLinkClassName}
          >
            {t("Record")}
          </NavLink>
        </NavItem>
      </Nav>
      <Container fluid>
        <div className="m-4">
          <Outlet context={{ t, subject, SubjectTeacher }} />
        </div>
      </Container>
    </>
  )
}

export default MainSchoolGrade