
import React from "react"
import { Badge } from "reactstrap"


export const handleStatusAssignment = key => {
  let color
  let status
  switch (key) {
    case "0":
      color = "danger"
      status = "pendiente"
      break
    case "1":
      color = "warning"
      status = "por evaluar"
      break
    case "2":
      color = "success"
      status = "evaluada"
      break
    case "3":
      color = "secondary"
      status = "vencida"
      break
    default:
      break
  }
  return <Badge color={color}>{status}</Badge>
}
