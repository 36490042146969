import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux"
import { useEffect } from 'react'
import { Col, FormGroup, Label,Button } from 'reactstrap'
import Select from 'react-select'
import { createSelector } from "reselect"
import {
  setCurrentPeriod,
  onReadData,
  setOpen,
  onReadDataSuccess,
} from "features/student/subject/schoolGrade/schoolGradeSlice"
import AccordionSchoolGrades from "./AccordionSchoolGrades"
import { selectStyles } from "common/Constants"
// Create a selector for the assignment state
const selectSchoolGradeState = state => state.student.schoolGrade

// Create a memoized selector for specific assignment properties
const selectSchoolGradeProperties = createSelector(
  [selectSchoolGradeState],
  schoolGradeState => ({
    dataTable: schoolGradeState.dataTable,
    filterby: schoolGradeState.filterby,
    isLoading: schoolGradeState.isLoading,
    selectedRow: schoolGradeState.selectedRow,
    isEditing: schoolGradeState.isEditing,
    isSaveConfirmationModal: schoolGradeState.isSaveConfirmationModal,
    visibleColumns: schoolGradeState.visibleColumns,
    periodsOptions: schoolGradeState.periodsOptions,
    period: schoolGradeState.period,
    isLoadingPeriods: schoolGradeState.isLoadingPeriods,
    open: schoolGradeState.open,
    schoolGrades: schoolGradeState.schoolGrades,
    isLoading: schoolGradeState.isLoading,
    totalScore: schoolGradeState.totalScore
  })
)

const SchoolGrades = () => {

 const { t, SubjectStudent } = useOutletContext()
  const schoolGradeState = useSelector(selectSchoolGradeProperties)
  const { period, open, schoolGrades, isLoading ,totalScore} = schoolGradeState
 const dispatch = useDispatch()

useEffect(() => {
  
    
 
 
const currentDate = new Date()
const calculatedCurrentPeriod = SubjectStudent.periodsOptions.find(period => {
  const startDate = new Date(period.start_date)
  const endDate = new Date(period.end_date)

  return startDate <= currentDate && endDate >= currentDate
})
if (calculatedCurrentPeriod) {
  console.log(calculatedCurrentPeriod)
  dispatch(setCurrentPeriod(calculatedCurrentPeriod))
  dispatch(
    onReadData({
      subject: SubjectStudent.subject,
      periodId: calculatedCurrentPeriod.id,
    })
  )

  
} 


}, [dispatch, SubjectStudent])





const handlePeriodChange = option => {
  dispatch(setCurrentPeriod(option))
  dispatch(
    onReadData({
      subject: SubjectStudent.subject,
      periodId: option.value,
    })
  )

}
const handleSetOpen = id => {

  if (open === id) {
    dispatch(setOpen())
  } else {
    dispatch(setOpen(id))
  }
}

  return (
    <div>
      <Col sm="4">
        <FormGroup>
          <Label htmlFor="period">{t("Periods")}</Label>

          <Select
            name="period_id"
           
            value={period}
            options={SubjectStudent.periodsOptions}
            onChange={option => handlePeriodChange(option)}
            styles={selectStyles}
          />

          {/* <FormFieldError errors={fields.period_id.errors} t={t} /> */}
        </FormGroup>
      </Col>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <AccordionSchoolGrades
          open={open}
          setOpen={handleSetOpen}
          schoolGrades={schoolGrades}
          totalScore={totalScore}
          t={t}
        />
      )}
    </div>
  )
}

export default SchoolGrades