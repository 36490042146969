import { createSlice } from "@reduxjs/toolkit"


const recordSlice = createSlice({
  name: "teacher/record",
  initialState: {
    test: "test",
    data: null,

    students: [],
    isLoading: false,
    selectedRow: null,
    isEditing: false,
    isSaveConfirmationModal: false,
    unsavedData: null,
    visibleColumns: [],
    periodsOptions: [],
    period: null,
    isLoadingPeriods: false,
    filterby: "",
  },
  reducers: {
    onReadPeriod: state => {
      state.isLoadingPeriods = true
    },
    onReadPeriodSuccess: (state, action) => {
      state.periodsOptions = action.payload.periodsOptions
      state.isLoadingPeriods = false
    },
    setCurrentPeriod: (state, action) => {
      state.period = action.payload
    },
    onReadData: state => {
      state.isLoading = true
      state.filterby = ""
    },
    onReadDataSuccess: (state, action) => {
      state.isLoading = false
      state.data = action.payload
    },
    onFilterChange: (state, action) => {
      state.filterby = action.payload
    },
  },
})



export const {
  setCurrentPeriod,
  onReadPeriod,
  onReadPeriodSuccess,
  onReadData,
  onReadDataSuccess,

  onFilterChange,
  onEditStart,
  onEdit,
  onSaveConfirmationModalStart,
  onSaveConfirmationCancel,
  onToggleColumnVisibility,
} = recordSlice.actions
export default recordSlice.reducer



