import PropTypes from "prop-types"
import React from "react"
import { Container, Row, Col } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import MiniWidgets from "components/Common/MiniWidgets"
const widgetData = [
  {
    id: 1,
    title: "Profesores",
    count: 10,
    icon: "fas fa-chalkboard-teacher",
  },
  {
    id: 2,
    title: "Estudiantes",
    count: 20,
    icon: "fas fa-graduation-cap",
  },
  {
    id: 3,
    title: "Asignaturas",
    count: 30,
    icon: "fas fa-book",
  },
]
const CoordinatorDashboard = props => {
  //meta title
  document.title = "Dashboard | CCMB Tareas"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col xl={8}>
              <Row>
                {widgetData.map(widget => (
                  <MiniWidgets
                    key={widget.id}
                    title={widget.title}
                    count={widget.count}
                    icon={widget.icon}
                  />
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CoordinatorDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(CoordinatorDashboard)
