import { createSlice } from "@reduxjs/toolkit"
import { dataTable } from "./tableSchema";
import { Fields } from "./formSchema";
const privilegeRemovalSlice = createSlice({
  name: "teacher/privilegeRemoval",
  initialState: {
    mode: null,
    dataTable: { ...dataTable },
    isVisible: false,
    fields: { ...Fields },
    isSubmitting: false,
    isLoading: false,
  },
  reducers: {
    onCreateStart: state => {
      state.isVisible = true
      state.mode = "create"
    },
    onCreate: state => {
      state.isSubmitting = true
    },
    onCreateSuccess: (state, action) => {
      state.isSubmitting = false
      state.isVisible = false
      state.mode = null
      state.dataTable.rows = [action.payload, ...state.dataTable.rows]

      state.fields = { ...Fields }
    },
    /*____________________________________________________________________________________________________________________________________________________ */
    onRead: state => {
      state.isLoading = true
    },
    onReadSuccess: (state, action) => {
      state.isLoading = false
      state.dataTable.rows = action.payload
    },
    /*____________________________________________________________________________________________________________________________________________________ */

    onCancel: state => {
      state.isVisible = false
      state.mode = null
    },
    onFieldChange: (state, action) => {
      const { fieldName, newValue } = action.payload
      if (Array.isArray(state.fields[fieldName].value)) {
        // For checkboxes, toggle the checkbox value
        const updatedValue = state.fields[fieldName].value.includes(newValue)
          ? state.fields[fieldName].value.filter(val => val !== newValue)
          : [...state.fields[fieldName].value, newValue]

        state.fields[fieldName].value = updatedValue
      } else {
        // For other fields, directly set the new value
        state.fields[fieldName].value = newValue
      }
         state.fields[fieldName].errors = []
    },
    setFieldError: (state, action) => {
      const { fieldKey, errors } = action.payload
      state.fields[fieldKey].errors = errors
      state.isSubmitting = false
    },
  },
})

export const {
  onCreateStart,
  onCancel,
  onFieldChange,
  setFieldError,
  onCreate,
  onCreateSuccess,
  onRead,
  onReadSuccess,
} = privilegeRemovalSlice.actions
export default privilegeRemovalSlice.reducer
