// announcementSlice.js
import { createSlice } from "@reduxjs/toolkit"
import {Fields} from "./fieldSchema";


const announcementSlice = createSlice({
  name: "teacher/announcement",
  initialState: {
    subjects: [],
    isLoading: false,
    isModal: false,
    activeTab: 1,
    fields: { ...Fields },
    isSubmitting: false,
    announcements: [],

    pagination: {
      total_pages: 0,
      current_page: 1,
      per_page: 10,
    },

    periodsOptions: [],
    submissionTypesOptions: [],
    announcementTypesOptions: [],
    uploadPercentage: 0,
    searchTerm: "",
    isModalDelete: false,
    selectedTableRow: null,
    mode: null,
    isLoadingEdit: false,

    isEditingSubjectLetter: false,
    subjectLetterData: null,
  },
  reducers: {
    handleFieldChange: (state, action) => {
      const { fieldName, newValue } = action.payload

      state.fields[fieldName].value = newValue
      state.fields[fieldName].errors = []
    },
    onSubjectLettherChange: (state, action) => {
      state.subjectLetterData = action.payload
    },

    uploadProgress: (state, action) => {
      state.uploadPercentage = action.payload
    },

    setActiveTab: (state, action) => {
      state.activeTab = action.payload
    },
    setFieldError: (state, action) => {
      const { fieldKey, errors } = action.payload
      state.fields[fieldKey].errors = errors
      state.isSubmitting = false
    },
    setValidationErrors: (state, action) => {
      state.fields = { ...action.payload.fieldErrors }
    },
    setPageNumber: (state, action) => {
      state.pagination.current_page = action.payload
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload
    },
    /*___________________________________________________________region Create____________________________________________________________________________ */
    onCreateStart: state => {
      state.mode = "Create"
      state.isModal = true
    },
    onCreateCancel: state => {
      // use in edit and create

      state.mode = null
      state.isModal = false
      state.fields = { ...Fields }
     
    },

    onCreateIsSubmitting: state => {
      state.isSubmitting = true
    },
    onCreateSuccess: state => {
      state.isSubmitting = false
      state.fields = { ...Fields }
      state.isModal = false
      state.activeTab = 1
      state.uploadPercentage = 0
    },
    //end of create
    /*___________________________________________________________region Read____________________________________________________________________________ */
    onReadData: state => {
      state.isLoading = true
    },
    onReadDataSuccess: (state, action) => {
      state.isLoading = false
      state.dataTable.rows = action.payload.response.announcements
      state.pagination = action.payload.response.pagination
      state.periodsOptions = action.payload.periodsOptions
      state.submissionTypesOptions = action.payload.submissionTypesOptions
      state.announcementTypesOptions = action.payload.announcementTypesOptions
    },
    onReadDataError: (state, action) => {},

    /*______________________________________________________________region Edit______________________________________________________________________________ */
    onEditStart: (state, action) => {
      state.isEditing = true
    },
    onEditIni: (state, action) => {
      state.isLoadingEdit = false
      state.fields = action.payload.fields
    },

    onEditIsSubmitting: (state, action) => {
      state.isSubmitting = true
    },
    onEditSuccess: (state, action) => {
      state.isSubmitting = false
      state.isModal = false
      state.mode = null
      state.fields = { ...Fields }
      state.activeTab = 1
      state.uploadPercentage = 0
      state.dataTable.rows = state.dataTable.rows.map(row => {
        if (row.id === action.payload.announcement.id) {
          return action.payload.announcement
        }
        return row
      })
    }, 
    onEditError: (state, action) => {},
    /*___________________________________________________________region subjectLetter____________________________________________________________________________ */
    onEditStartSubjectLetter: state => {
      state.isEditingSubjectLetter = true
    },
    onEditCancelSubjectLetter: state => {
      state.isEditingSubjectLetter = false
    },
    onEditSubjectLetter: (state, action) => {}, //sagas call
    onEditSubjectLetterSuccess: (state) => {
      state.isEditingSubjectLetter = false
     
    },

    /*___________________________________________________________region Delete____________________________________________________________________________ */

    onDeleteStart: (state, action) => {
      state.selectedTableRow = action.payload.selectedTableRow
      state.isModalDelete = true
    },
    onDeleteCancel: state => {
      state.isModalDelete = false
      state.selectedTableRow = null
    },
    onDelete: (state, action) => {},
    onDeleteSuccess: (state, action) => {
      const { id } = action.payload
      state.isModalDelete = false
      state.selectedTableRow = null
      state.dataTable.rows = state.dataTable.rows.filter(row => row.id !== id)
    },
    onDeleteError: (state, action) => {},
  },
})

export const {
  onReadData,
  onReadDataSuccess,
  setActiveTab,
  handleFieldChange,
  onCreateIsSubmitting,
  setValidationErrors,
  onCreateSuccess,
  uploadProgress,
  setFieldError,
  setPageNumber,
  setSearchTerm,
  onCreateStart,
  onCreateCancel,
  onDelete,
  onDeleteCancel,
  onDeleteStart,
  onDeleteSuccess,
  onEditStart,
  onEditIni,
  onEditIsSubmitting,
  onEditSuccess,
  onEditStartSubjectLetter,
  onEditCancelSubjectLetter,
  onSubjectLettherChange,
  onEditSubjectLetter,
  onEditSubjectLetterSuccess,
} = announcementSlice.actions
export default announcementSlice.reducer

export const selectCurrentSubjects = state => state.teacher.announcement.subjects
export const selectCurrentIsloading = state => state.teacher.announcement.isLoading
