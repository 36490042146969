import { takeLatest, put, call,all } from "redux-saga/effects"
import {
  onReadData,
  onReadDataSuccess,
  onCreateIsSubmitting,
  onCreateSuccess,
  uploadProgress,
  setFieldError,
  onDelete,
  onDeleteSuccess,
  onEditStart,
  onEditIni,
  onEditIsSubmitting,
  onEditSuccess,
  onEditSubjectLetter,
  onEditSubjectLetterSuccess,
} from "./announcementSlice"
import { parseISO, format } from "date-fns"
import { apiClient } from "api/axios"
import { generateOptions } from "helpers/select_helper"
import { Fields } from "./fieldSchema"
import { parseAndFormatDateTime } from "helpers/time_helper"
import Moment from "moment"
import { take } from "lodash"
import { toast } from "react-toastify"
import { handleSelectSubjectChangeProperty } from "../subjectSlice"
function* workerFetchAnnouncements({ payload }) {
  try {
    const subject = payload.subject
    const pageNumber = payload.pageNumber
    const search = payload.searchTerm

    const [response, periods, submissionTypes, announcementTypes] = yield all([
      call(
        apiClient.get,
        `api/teacher/subjects/${subject?.id}/announcements?page=${pageNumber}&search=${search}`
      ),
      call(apiClient.get, `api/grades/${subject?.grade_id}/periods`),
      call(apiClient.get, `api/announcements/submission_types`),
      call(apiClient.get, `api/announcements/announcement_types`),
    ])

    const periodsOptions = generateOptions(
      periods?.data,
      "id",
      element => element.name
    )
    const submissionTypesOptions = generateOptions(
      submissionTypes?.data,
      "id",
      element => element.name
    )
    const announcementTypesOptions = generateOptions(
      announcementTypes?.data,
      "id",
      element => element.name
    )
    yield put(
      onReadDataSuccess({
        response: response.data,
        periodsOptions,
        submissionTypesOptions,
        announcementTypesOptions,
      })
    )
  } catch (error) {
    console.log(error)
    // Handle errors, e.g., dispatch an error action
  }
}




function* workerCreateAnnouncement({ payload: { fields, subject } }) {
  try {
    // const formData = new FormData()
    // formData.append("title", fields.title.value)
    // formData.append("description", fields.description.value)
    // formData.append("subject_id", subject.id)
    // formData.append("teacher_id", subject.teacher_id)
    // formData.append("submission_type_id", fields.submission_type.value.value)
    // formData.append("period_id", fields.period.value.value)
    // formData.append("announcement_type_id", fields.type.value.value)
    // formData.append("due_date",Moment(fields.due_date.value).format("YYYY-MM-DD H:mm:ss"))
    // formData.append("publication_date",Moment(fields.publication_date.value).format("YYYY-MM-DD H:mm:ss"))
    
    
    // //formData.append("announcement_files", "value2")

    // const response = yield call(
    //   apiClient.post,
    //   `api/teacher/subjects/${subject.id}/announcements`,
    //   formData,
    //   {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //     onUploadProgress: function (progressEvent) {
    //       console.log("Progress Event:", progressEvent)
    //     },
    //   }
    // )

    // yield put(onReadData({ subject, pageNumber: 1, searchTerm: "" }))
    // const announcement = response.data.announcement
    // yield put(onCreateSuccess({ announcement }))
         toast.success("Anuncio creado exitosamente");

  } catch (error) {
    // if (error.isAxiosError) {
    //   const { status, data } = error?.response
    //   const messages = data?.messages
    //   if (status === 400) {
    //     for (const fieldKey in messages) {
    //       if (messages.hasOwnProperty(fieldKey)) {
    //         yield put(setFieldError({ fieldKey, errors: [messages[fieldKey]] }))
    //       }
    //     }
    //   }
    // }
  }
}

function* workerDelAnnouncement({ payload: { selectedTableRow } }) {
  const SubjectId = selectedTableRow.subject_id
  const assignmentId = selectedTableRow.id

  try {
   const response = yield call(apiClient.delete, `api/teacher/subjects/${SubjectId}/announcements/${assignmentId}`);
 
   if (response.data.message === "Announcement deleted successfully") {
     yield put(onDeleteSuccess({ id:assignmentId }))
   }
  

 } catch (error) {
  console.log(error)
  
 }

}


function* workerEditAnnouncementStart({ payload: { selectedTableRow } }) {
  try {
    const { data } = yield call(apiClient.get, `api/teacher/subjects/${selectedTableRow.subject_id}/announcements/${selectedTableRow.id}`);
    
    const updatedFields = {
      title: { ...Fields.title, value: data?.title },
      description: { ...Fields.description, value: data?.description },
      due_date: { ...Fields.due_date, value: new Date(data?.due_date) },
      type: { ...Fields.type, value: { value: data?.announcement_type_id, label: data?.announcement_type_name } },
      period: { ...Fields.period, value: { value: data?.period_id, label: data?.period_name } },
      submission_type: { ...Fields.submission_type, value: { value: data?.submission_type_id, label: data?.submission_type_name } },
      publication_date: { ...Fields.publication_date, value: new Date(data?.publication_date) },
    };

    yield put(onEditIni({ fields: updatedFields }));
  } catch (error) {
    console.log(error);
  }
}


function* workerEditAnnouncement({ payload: { fields, subject,selectedTableRow } }) {
  try {
    

//Moment(value.value).format("YYYY-MM-DD H:mm:ss")
    const formData = new FormData()
    formData.append("id", selectedTableRow.id)
    formData.append("title", fields.title.value)
    formData.append("description", fields.description.value)
    formData.append("subject_id", subject.id)
    formData.append("teacher_id", subject.teacher_id)
    formData.append("submission_type_id", fields.submission_type.value.value)
    formData.append("period_id", fields.period.value.value)
    formData.append("announcement_type_id", fields.type.value.value)
    formData.append("due_date",Moment(fields.due_date.value).format("YYYY-MM-DD H:mm:ss"))
    formData.append("publication_date",Moment(fields.publication_date.value).format("YYYY-MM-DD H:mm:ss"))
      
      
    
    

const { data }= yield call(
      apiClient.post,
      `api/teacher/subjects/${subject.id}/announcements/${selectedTableRow.id}/update`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function (progressEvent) {
          console.log("Progress Event:", progressEvent)
        },
      })
      console.log(data)
      yield put(onEditSuccess({ announcement: data.announcement }))
  } catch (error) {
    





  }





}

function*  workerEditSubjectLetter ({ payload: { subjectId, description }}) {
  try {
    console.log("workerEditSubjectLetter")
    const {data} = yield call(apiClient.post,`api/teacher/subjects/${subjectId}/update`, {description: description})
    yield put(handleSelectSubjectChangeProperty({fieldName:"teacher_subject_description",newValue:data.teacher_subject_description}))
    yield put(onEditSubjectLetterSuccess())
    toast.success("Carta de la materia actualizada exitosamente");
       } catch (error) {
    
console.log(error)
  }
}




export function* watchTeacherAnnouncements() {
  yield takeLatest(onReadData.type, workerFetchAnnouncements)
  yield takeLatest(onCreateIsSubmitting.type, workerCreateAnnouncement)
  yield takeLatest(onDelete.type,workerDelAnnouncement )
  yield takeLatest(onEditStart.type,workerEditAnnouncementStart )
  yield takeLatest(onEditIsSubmitting.type,workerEditAnnouncement )
  yield takeLatest(onEditSubjectLetter.type,workerEditSubjectLetter )
  
}

export default watchTeacherAnnouncements
