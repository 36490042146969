import React, { useEffect } from "react"
import {
  UncontrolledTooltip,
  Row,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Col,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import classnames from "classnames"
import Benchmark from "./Benchmark/Benchmark"

import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import {
  setCurrentActiveTab,
  onReadData,
} from "features/teacher/academicStandars/academicStandarSlice"
import { useOutletContext } from "react-router-dom"
import { selectCurrentUser } from "store/auth/login/loginSlice"
import Competence from "./Competence/Competence"
import Indicator from "./Indicator/Indicator"
const selectAcadmicStandarState = state => state.teacher.academicStandar
const selectProperties = createSelector([selectAcadmicStandarState], state => ({
  isModal: state.isModal,
  mode: state.mode,
  isModalDelete: state.isModalDelete,
  currentActiveTab: state.currentActiveTab,
  subjectsOptions: state.subjectsOptions,
}))

const AcademicStandard = () => {

  const { t } = useOutletContext()
 const dispatch = useDispatch()
 const state = useSelector(selectProperties)
 const { isModal, mode, isModalDelete, currentActiveTab, subjectsOptions } =
   state
  const user = useSelector(selectCurrentUser)

   useEffect(() => {
     dispatch(onReadData({ id: user?.user_id }))
   }, [dispatch, user])

  const toggle = tab => {
    if (currentActiveTab !== tab) {
      dispatch(setCurrentActiveTab(tab))
    }
    
  
  }





  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Dashboard")}
          breadcrumbItem={t("Academic Standard")}
        />
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: currentActiveTab === "1",
              })}
              onClick={() => {
                toggle("1")
              }}
            >
              {t("Benchmark")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: currentActiveTab === "2",
              })}
              onClick={() => {
                toggle("2")
              }}
            >
              {t("Competence")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: currentActiveTab === "3",
              })}
              onClick={() => {
                toggle("3")
              }}
            >
              {t("Indicator")}
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={currentActiveTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <Benchmark subjectsOptions={subjectsOptions} t={t} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <Competence subjectsOptions={subjectsOptions} t={t} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <Indicator subjectsOptions={subjectsOptions} t={t} />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Container>
    </div>
  )
}

export default AcademicStandard
