import { takeLatest, put, call, all } from "redux-saga/effects"

import { apiClient } from "api/axios"
import {
  onReadData,
  onReadDataSuccess,


} from "./recordSlice"
import { toast } from "react-toastify"
import { subjectEndpoints, } from "api/endpoints"




function* workerFetchRecord({payload:{subject}}) {
    try {

        
        const response = yield call(apiClient.get, subjectEndpoints.records(subject.id))
        yield put(onReadDataSuccess(response.data))
     

    } catch (error) {
        
        toast.error(error.message)
    }
}

export function* watchRecord() {
 
   yield takeLatest(onReadData.type, workerFetchRecord)
  

}

export default watchRecord
