import React from 'react'
import { Row, Col, FormGroup, Label,Input } from "reactstrap"
import DatePicker from "react-datepicker"
import Select from "react-select"
import FormFieldError from 'components/Common/FormFieldError'
import MyCKEditor from 'components/Common/CKeditor/MyCKEditor'
const data = [
  {
    label: "Group 1",
    options: [
      { label: "Group 1, option 1", value: "value_1" },
      { label: "Group 1, option 2", value: "value_2" },
    ],
  },
  { label: "A root option", value: "value_3" },
  { label: "Another root option", value: "value_4" },
]
import {filterPassedTime} from 'helpers/time_helper'
const AssignmentFormTab1 = (props) => {

    const {
      t, 
      handleFieldChange, 
      selectStyles,
      assignmentState,
    } = props

const { fields, periodsOptions, activityOptions, submissionTypesOptions } =
  assignmentState

   // const {form} = assignmentState
  return (
    <Row>
      <Col md="6">
        <FormGroup>
          <Label for="title">{t("Title")}</Label>
          <Input
            type="text"
            id="title"
            name="title"
            onChange={e => handleFieldChange(e.target.name, e.target.value)}
            // onChange={form.handleChange}
            // onBlur={form.handleBlur}
            value={fields.title.value}
            // invalid={form.touched.title && !!form.errors.title}
          />
          <FormFieldError errors={fields.title.errors} t={t} />
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <Label for="due_date">{t("Due Date")}</Label>
          <DatePicker
            name="due_date"
            selected={fields.due_date.value}
            minDate={new Date()}
            filterTime={filterPassedTime}
            onChange={date => handleFieldChange("due_date", date)}
            showTimeSelect
            dateFormat="MM-dd-yyyy h:mm aa"
            className="form-control"
          />
          <FormFieldError errors={fields.due_date.errors} t={t} />
        </FormGroup>
      </Col>
      <Col md="3">
        <FormGroup>
          <Label htmlFor="activity_id">{t("Type of activity")}</Label>
          <Select
            styles={selectStyles}
            name="activity_id"
            className="form-controll"
            value={fields.activity_id.value}
            options={activityOptions}
            onChange={option => handleFieldChange("activity_id", option)}
          />
          <FormFieldError errors={fields.activity_id.errors} t={t} />
        </FormGroup>
      </Col>
      <Col md="3">
        <FormGroup>
          <Label htmlFor="period">{t("Periods")}</Label>
          <Select
            styles={selectStyles}
            name="period_id"
            // className="form-control"
            value={fields.period_id.value}
            options={periodsOptions}
           
            onChange={option => handleFieldChange("period_id", option)}
          />
          <FormFieldError errors={fields.period_id.errors} t={t} />
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <Label htmlFor="submission_type">{t("Method of Delivery")}</Label>
          <Select
            styles={selectStyles}
            name="submission_type_id"
            className="form-controll"
            value={fields.submission_type_id.value}
            options={submissionTypesOptions}
            onChange={option => handleFieldChange("submission_type_id", option)}
          />
          <FormFieldError errors={fields.submission_type_id.errors} t={t} />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Label htmlFor="productdesc">{t("Description")}</Label>

          <MyCKEditor
            editorData={fields.description.value}
            handleEditorChange={rawData =>
              handleFieldChange("description", rawData)
            }
          />

          <FormFieldError errors={fields.description.errors} t={t} />
        </FormGroup>
      </Col>
    </Row>
  )
}

export default AssignmentFormTab1