export const dataTable = {
  columns: [
    {
      label: "Competencia",
      field: "name",
    },
    
    {
      label: "Opciones",
      field: "options",
    },
  ],
  rows: [ ],
}
