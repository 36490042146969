import React from "react"
import { Outlet } from "react-router-dom"
import { withTranslation } from "react-i18next"
import HorizontalLayout from "components/HorizontalLayout"
const StudentMiddleware = props => {
    const {t} =props
  return (
    <>
      <HorizontalLayout>
        <Outlet context={{ t }} />
      </HorizontalLayout>
    </>
  )
}



export default withTranslation()(StudentMiddleware);