import { takeLatest, put,call } from "redux-saga/effects"
import { onReadData, onReadDataSuccess } from "./dashboardSlice"

import { apiClient } from "api/axios"
function* fetchDashboardData() {
  try {   
    const response = yield call(apiClient.get,'api/student/dashboard') 
      
    yield put(onReadDataSuccess(response.data))
  } catch (error) {
    console.log(error)
    // Handle errors, e.g., dispatch an error action
  }
}

export function* watchStudentDashboard() {
  yield takeLatest(onReadData.type, fetchDashboardData)
}

export default watchStudentDashboard