import React from 'react'
import { Col, Card, CardBody, Media, CardImg, CardTitle } from "reactstrap"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
const SubjectCard = (props) => {
    const {  name, id, grade_img_url } = props.data
     const { handleSelectSubject } = props
     
  return (
    
    <Col xl="3" sm="6">
      <Card className="">
        <div className="d-flex">
          <CardImg
            top
            width="100%"
            src={grade_img_url}
            alt="Card image cap"
            className="w-50"
          />
          <CardBody className="d-flex flex-column align-items-center justify-content-center w-50">
            <CardTitle tag="h5">
              <Link
                to={`/subjects/${id}/start`}
                // to={`/subjects/planning`}
                className="text-dark"
                onClick={e => {
                  e.stopPropagation()
                 
               handleSelectSubject(props.data)
                }}
              >
                <h5 className="text-truncate font-size-15">{name}</h5>
              </Link>
            </CardTitle>
          </CardBody>
        </div>
      </Card>
 
    </Col>
  )
}

SubjectCard.propTypes = {
  course_id: PropTypes.any,
  name: PropTypes.any,
  id: PropTypes.any,


}

export default SubjectCard