

import React,{
  useEffect
} from 'react'
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useOutletContext } from "react-router-dom"
import { FormGroup, Label, Row, Col, Button } from "reactstrap"
import DatePicker from "react-datepicker"

import {
  onReadData,
  setAttendanceDate,
  setStudents,
  onSaveData,
} from "features/teacher/subject/attendance/attendanceSlice"
import Moment from "moment"






const selectState = state => state.teacher?.["attendance"]

const selectProperties = createSelector([selectState], state => ({
  isModal: state.isModal,
  mode: state.mode,
  isModalDelete: state.isModalDelete,
  attendanceDate: state.attendanceDate,
  students: state.students,
  isEdit: state.isEdit,
  attendanceId: state.attendanceId,
  isLoading: state.isLoading,
}))

const Attendance = () => {
  const { SubjectTeacher,t } = useOutletContext()

  const State = useSelector(selectProperties)
  const { attendanceDate, students, isEdit, attendanceId, isLoading } = State
  const dispatch = useDispatch()

  useEffect(() => {
    if (SubjectTeacher?.students) {
      const updatedStudents = SubjectTeacher.students.map(student => ({
        ...student,
        status: 0,
      }))
      dispatch(setStudents(updatedStudents))
       dispatch(
         onReadData({
           SubjectTeacher: SubjectTeacher,
           attendanceDate: attendanceDate,
         })
       )
    }
  }, [SubjectTeacher, dispatch, attendanceDate])


  const handleDateChange = date => {
    dispatch(setAttendanceDate(date))

  }

  

  const getAttendance = () => {
    dispatch(onReadData({ subject: SubjectTeacher, attendanceDate: attendanceDate}))
   
  }
  const handleStatusButton = status => {
  let look
  switch (status) {
    case 0:
      look = {
        icon: <i className="fas fa-ban font-size-16 align-middle "></i>,
        color: "secondary",
      }
      break
    case 1:
      look = {
        icon: <i className="fas fa-user-check font-size-16 align-middle "></i>,
        color: "success",
      }
      break
    case 2:
      look = {
        icon: <i className="fas fa-user-times font-size-16 align-middle "></i>,
        color: "danger",
      }
      break
    case 3:
      look = {
        icon: <i className="fas fa-user-clock font-size-16 align-middle "></i>,
        color: "warning",
      }
      break
    case 4:
      look = {
        icon: <i className="fas fa-user-shield font-size-16 align-middle "></i>,
        color: "primary",
      }
      break
    default:
      look = {
        icon: <i className="bx bx-block font-size-16 align-middle "></i>,
        color: "secondary",
      }
      break
  }
  return look

  }


  const handleAttendance = student => {
  const updatedStudents = students.map(s => {
    if (s.id === student.id) {
      if (s.status === 4) {
        return { ...s, status: 0 }
      }
      return { ...s, status: parseInt(s.status) + 1 }
    }
    return s
  })

  dispatch(setStudents(updatedStudents))

  }
  const handleAllPresent = () => {
  const updatedStudents = students.map(s => ({ ...s, status: 1 }))
  dispatch(setStudents(updatedStudents))

  }
  const handleSave = () => {
 const formattedDate = Moment(attendanceDate).format("YYYY-MM-DD")
  const json = {
    date: formattedDate,
    students: students,
    subject_id: SubjectTeacher.subject.id,
   
  
  }
  dispatch(onSaveData({json: json ,t: t,isEdit: isEdit,attendanceId: attendanceId}))
  }



  return (
    <div>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="date">{t("Attendance Date")}</Label>
            <DatePicker
              placeholderText={t("Select attendance date")}
              utcOffset={0}
              selected={attendanceDate}
              onChange={handleDateChange}
              dateFormat="MM-dd-yyyy"
              className="form-control"
            />
          </FormGroup>
        </Col>
      
      </Row>

      <Row>
        <Col md="6">
          <div className="table-responsive">
            <table className="table table-bordered ">
              <thead>
                <tr>
                  {/* <td style={{ textAlign: "center" }}>Foto</td> */}
                  <th style={{ textAlign: "center", width: "75%" }}>
                    {t("Students")}
                  </th>
                  <th style={{ textAlign: "center" }}>{t("Status")}</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="2">{t("Loading...")}</td>
                  </tr>
                ) : (
                  students &&
                  students.map((student, tdindex) => {
                    let tData = [...Array(2)].map((header, hIndex, arr) => {
                      return hIndex === 0 ? (
                        <td key={hIndex}>
                          {/* <img
                            src={require(`../../../assets/images/users/${row.photo}`)}
                            alt="profile"
                            className="rounded-circle header-profile-user mr-1 "
                          /> */}
                          {student.name + " " + student.last_name}
                        </td>
                      ) : (
                        <td key={hIndex} style={{ textAlign: "center" }}>
                          <Button
                            color={handleStatusButton(parseInt(student?.status, 10)).color}
                            onClick={() => handleAttendance(student)}
                          >
                            {handleStatusButton(parseInt(student?.status, 10)).icon}
                          </Button>
                        </td>
                      );
                    });
                    return <tr key={student.id}>{tData}</tr>;
                  })
                )}
              </tbody>
            </table>
          </div>
        </Col>
        <Col md="6">
          <FormGroup>
            <Button color="success" onClick={() => handleAllPresent()}>
              {t("Mark all as Present")}
            </Button>
            <Button
              className="ms-2"
              color="primary"
              onClick={() => handleSave()}
            >
              {t("Save")}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

export default Attendance