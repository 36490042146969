import { all, fork } from "redux-saga/effects"
import dashboarSaga from "./dashboard/dashboardSaga"
import watchTeacherAssignments from "./subject/assignment/assignmentSaga"
import watchTeacherSubject from "./subject/subjectSaga"
import watchTeacherAnnouncements from "./subject/announcement/announcementSaga"
import watchTeacherSchoolGrade from "./subject/schoolGrade/schoolGradeSaga"
import watchAcadmicStandar from "./academicStandars/academicStandarSaga"
import watchBenchmark from "./academicStandars/benchmark/benchmarkSaga"
import watchCompetence from "./academicStandars/competence/competenceSaga"
import watchIndicator from "./academicStandars/indicator/indicatorSaga"
import watchPrivilegeRemoval from "./subject/behaviorTrack/privilegeRemoval/privilegeRemovalSaga"
import watchExhortationNote from "./subject/behaviorTrack/exhortationNote/exhortaionNoteSaga"
import watchTeacherPlanning from "./subject/planning/planningSaga"
import watchAttendance from "./subject/attendance/attendanceSaga"
import watchPedagogicalRecovery from "./subject/pedagogicalRecovery/pedagogicalRecoverySaga"
import watchPedagogicalRecoveryStudent from "./subject/pedagogicalRecoveryStudent/pedagogicalRecoveryStudentSaga"
import watchRecord from "./subject/record/recordSaga"



export default function* teacherSagas() {
  yield all([fork(dashboarSaga)])
  yield all([fork(watchTeacherAssignments)])
  yield all([fork(watchTeacherSubject)])
  yield all([fork(watchTeacherAnnouncements)])
  yield all([fork(watchTeacherSchoolGrade)])
  yield all([fork(watchAcadmicStandar)])
  yield all([fork(watchBenchmark)])
  yield all([fork(watchCompetence)])
  yield all([fork(watchIndicator)])
  yield all([fork(watchPrivilegeRemoval)])
  yield all([fork(watchExhortationNote)])
  yield all([fork(watchTeacherPlanning)])
  yield all([fork(watchAttendance)])
  yield all([fork(watchPedagogicalRecovery)])
   yield all([fork(watchPedagogicalRecoveryStudent)])
   yield all([fork(watchRecord)])
  
  

}
