
import React from 'react'
import SimpleBar from "simplebar-react"
import parse from "html-react-parser"
import { Link } from "react-router-dom"
import MyCKEditor from 'components/Common/CKeditor/MyCKEditor'
import { Button, ButtonGroup } from "reactstrap"
const SubjectLetter = ({
  subject,
  t,
  isEditing,
  handleStartEdit,
  handleCancel,
  subjectLetterData,
  handleChange,
  handleSubmit,
}) => {
  return (
    <>
      {isEditing ? (
        <div>
          <Link to="#" className="float-end p-2" onClick={handleCancel}>
            {t(`Cancel`)}
          </Link>
        </div>
      ) : (
        <div>
          <Link to="#" className="float-end p-2" onClick={handleStartEdit}>
            {t(`Edit`)}
          </Link>
        </div>
      )}

      <hr></hr>
      {isEditing ? (
        <>
          <div className="document-editor">
            <div className="document-editor__toolbar"></div>
            <div className="document-editor__editable-container">
              <MyCKEditor
                editorData={subjectLetterData}
                handleEditorChange={rawData => handleChange(rawData)}
              />
            </div>
          </div>

          <div className="text-center">
            <Button color="primary" onClick={handleSubmit}>
              {t("Save")}
            </Button>
            <Button className="ms-1" color="secondary" onClick={handleCancel}>
              {t("Cancel")}
            </Button>
          </div>
        </>
      ) : (
        <SimpleBar style={{ maxHeight: "310px" }}>
          <div className={`container`}>
            {subject?.teacher_subject_description
              ? parse(subject?.teacher_subject_description)
              : t(`No description`)}
          </div>
        </SimpleBar>
      )}

      <hr></hr>
    </>
  )
}

export default SubjectLetter