import FormFieldError from 'components/Common/FormFieldError'
import React from 'react'
import DatePicker from "react-datepicker"
import Select from "react-select"
import {  Col, FormGroup, Label, Button,Spinner,Progress, Row } from "reactstrap"

//  const selectStyles = {
//    menu: base => ({
//      ...base,
//      zIndex: 100,
//    }),
//  }
const AssignmentFormTab2 = (props) => {

 
    const {
      t,

      handleFieldChange,
      isSubmitting,
      handleCancel,
      handleSubmit,
      uploadPercentage,
      selectStyles,
      assignmentState,
      handleFetchIndicators,
    } = props
const {
  fields,
  competencesOptions,
  specificCompetencesOptions,
  indicatorsOptions,
  isIndicatorOptionsLoading,
} = assignmentState

    
  return (
    <>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="competence">{t("Competences-CCMB")}</Label>
            <Select
              // menuPortalTarget={document.querySelector(".my-modal")}
              styles={selectStyles}
              name="competence"
              className="form-controll"
              value={fields.competence_id.value}
              options={competencesOptions}
              onChange={option => {
                handleFieldChange("competence_id", option)
                handleFetchIndicators(option.value)
              }}
            />
            <FormFieldError errors={fields.competence_id.errors} t={t} />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="specific_competence">
              {t("Competences-MINERD")}
            </Label>
            <Select
              styles={selectStyles}
              name="specific_competence_id"
              className="form-controll"
              value={fields.specific_competence_id.value}
              options={specificCompetencesOptions}
              onChange={option =>
                handleFieldChange("specific_competence_id", option)
              }
            />
            <FormFieldError
              errors={fields.specific_competence_id.errors}
              t={t}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="indicator">{t("Indicators")}</Label>
            <Select
              loadingMessage={() => t("Loading") + "..."}
              isLoading={isIndicatorOptionsLoading}
              isDisabled={isIndicatorOptionsLoading}
              styles={selectStyles}
              name="indicator_id"
              className="form-controll"
              value={fields.indicator_id.value}
              options={indicatorsOptions}
              onChange={option => handleFieldChange("indicator_id", option)}
            />
            <FormFieldError errors={fields.indicator_id.errors} t={t} />
          </FormGroup>
          <FormGroup>
            <Label for="publication_date">{t("Publication date")}</Label>
            <DatePicker
              style={selectStyles}
              selected={fields.publication_date.value}
              maxDate={
                fields.due_date.value ? fields.due_date.value : new Date()
              }
              onChange={date => {
                if (date < fields.due_date.value) {
                  handleFieldChange("publication_date", date)
                } else {
                  alert(
                    "La fecha de publicacion debe ser menor que la fecha de entrega"
                  )
                }
              }}
              showTimeSelect
              dateFormat="MM-dd-yyyy h:mm aa"
              className="form-control"
            />
            <FormFieldError errors={fields.publication_date.errors} t={t} />
          </FormGroup>
        </Col>
      </Row>

      <div className="mb-10">
        <Progress color="primary" value={uploadPercentage}></Progress>
      </div>
      <div className="text-center mt-4">
        <Button
          color="primary"
          type="submit"
          className="mr-2 waves-effect waves-light"
          onClick={() => {
            handleSubmit()
          }}
          disabled={isSubmitting}
        >
          {!isSubmitting ? (
            t("Save")
          ) : (
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              {t("Sending...")}
            </>
          )}
        </Button>
        <Button
          disabled={isSubmitting}
          color="light"
          type="submit"
          className="waves-effect ms-1"
          onClick={() => handleCancel()}
        >
          Cancelar
        </Button>
      </div>
    </>
  )
}

export default AssignmentFormTab2