import Moment from "moment"


export const validator = eachEntry => {
  const fieldErrors = {}
  const validationErrorSummary = []

  for (const fieldName in eachEntry) {
    const { value, rules, label, type, options } = eachEntry[fieldName]
    const errors = []

    // Check for required field
    if (
      rules &&
      rules.require &&
      (value === null || (typeof value === "string" && value.trim() === ""))
    ) {
      const requiredError = `is required`
      errors.push(requiredError)
      validationErrorSummary.push(requiredError)
    }

    // Validate checkbox type
    if (type === "checkbox" && rules && rules.require && options && options.length > 0) {
      const selectedOptions = value || []
      if (selectedOptions.length === 0) {
        const checkboxError = `At least one option must be selected for ${label}`
        errors.push(checkboxError)
        validationErrorSummary.push(checkboxError)
      } else if (selectedOptions.includes("Other")) {
       
        const otherFieldName = fieldName+"_other"
        console.log(otherFieldName)
        const otherValue = eachEntry[otherFieldName].value.trim()
        if (otherValue === "") {
          const otherError = `${label} is required when "Other" is selected`
          errors.push(otherError)
          validationErrorSummary.push(otherError)
        }
      }
    }

    // Add more validation rules as needed
    if (
      rules &&
      rules.maxLength &&
      typeof value === "string" &&
      value.length > rules.maxLength
    ) {
      const maxLengthError = `exceeds maximum length of ${rules.maxLength} characters.`
      errors.push(maxLengthError)
      validationErrorSummary.push(maxLengthError)
    }

    // Check for errors in fields without rules
    if (!rules && value === "") {
      const error = `${label} has an error.`
      errors.push(error)
      validationErrorSummary.push(error)
    }

    // Always build the fieldError object, even if there are no errors
    fieldErrors[fieldName] = {
      ...eachEntry[fieldName],
      errors: errors,
    }
  }

  const hasErrors = Object.keys(fieldErrors).some(
    fieldName => fieldErrors[fieldName].errors.length > 0
  ) // Calculate the boolean

  return { fieldErrors, validationErrorSummary, hasErrors }
}

export const handleData = eachEntry => {
  const formData = new FormData()
  Object.entries(eachEntry).forEach(([key, value]) => {
    if (value.type === "text") {
      formData.append(key, value.value)
    } else if (value.type === "select") {
      if (value?.isMulti) {
        formData.append(key, JSON.stringify(value.value))
      } else {
        formData.append(key, value.value.value)
      }
    } else if (value.type === "date") {
      let date = new Date(value.value)
        .toISOString()
        .slice(0, 19)
        .replace("T", " ")

      formData.append(key, date)
    } else if (value.type === "datetime") {
      let datetime = Moment(value.value).format("YYYY-MM-DD H:mm:ss")

      formData.append(key, datetime)
    } else if (value.type === "radio") {
      formData.append(key, value.value)
    } else if (value.type === "file") {
      value.value.length > 0 &&
        value.value.forEach(file => {
          formData.append(key, file)
        })
      // formData.append(key, value.value);
    }
  })
  return formData
}

// Helper function to format field values
export const formatFieldValues = fields => {
  const valuesToSend = {}

  Object.keys(fields).forEach(key => {
    switch (fields[key].type) {
      case "datetime":
        valuesToSend[key] = Moment(fields[key].value).format(
          "YYYY-MM-DD H:mm:ss"
        )
        break
      case "select":
        valuesToSend[key] = fields[key].value.value
        break
      default:
        valuesToSend[key] = fields[key].value
    }
  })

  return valuesToSend
}
