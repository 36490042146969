import React, { useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { onLogoutUser } from "../../store/auth/login/loginSlice"

//redux
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onLogoutUser({ navigate }))
  }, [dispatch, navigate])

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);