import React from 'react'
import logoBlue from "assets/images/logoBlueCCMB.png"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"
import Moment from "moment"
import  "./ExhortationNoteView.css"
const ExhortationNoteView = (props) => {
    
    const { isModalView, handleCloseView, t, currentExortationNote } = props
  

 
        return (
          <Modal isOpen={isModalView} className="modal-xl">
            <ModalHeader>
              <button className="close" onClick={() => handleCloseView()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody>
              <div class="table-responsive-xl">
                <table id="tableExortationNotes">
                  <tbody>
                    <tr className="show-border">
                      <td>
                        Nombre del alumno: <br />
                        <span className="bold-text-black">
                          {currentExortationNote?.student_name}{" "}
                          {currentExortationNote?.student_last_name}
                        </span>
                      </td>
                      <td
                        rowSpan={3}
                        style={{
                          textAlign: "center",
                          border: "2px solid #263796",
                        }}
                      >
                        <h3 style={{ marginBottom: "10px" }}>
                          Nota de Exhortación
                        </h3>
                        <img
                          src={logoBlue}
                          alt="CCMB Logo"
                          width="150"
                          height="150"
                        />
                      </td>
                      <td>
                        Fecha:
                        <br />
                        <span className="bold-text-black">
                          {Moment(currentExortationNote?.date).format(
                            "DD/MM/YYYY"
                          )}
                        </span>
                      </td>
                    </tr>
                    <tr className="show-border">
                      <td>
                        Curso: <br />
                        <span className="bold-text-black">
                          {currentExortationNote?.grade_name}
                        </span>
                      </td>
                      <td>
                        Profesor (a):
                        <br />
                        <span className="bold-text-black">
                          {currentExortationNote?.teacher_name}{" "}
                          {currentExortationNote?.teacher_last_name}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Asignatura:
                        <br />
                        <span className="bold-text-black">
                          {currentExortationNote?.subject_name}
                        </span>
                      </td>
                      <td>Consejero (a):</td>
                    </tr>
                    <tr className="show-border">
                      <td colSpan={3}>
                        <h3
                          style={{ marginBottom: "10px", textAlign: "center" }}
                        >
                          Aviso a los Padres o Tutores
                        </h3>
                        <ol>
                          <li className="bold-text">
                            Este alumno está presentando dificultades académicas
                            y/o conductuales en esta asignatura.
                          </li>
                          <li className="bold-text">
                            Es necesario que se asegure de entender las razones
                            por las que este reporte se emite y pueda seguir las
                            recomendaciones de lugar.
                          </li>
                          <li className="bold-text">
                            Asegúrese de familiarizarse con el sistema de
                            calificaciones y de asignaciones/tareas establecidas
                            para cada materia.
                          </li>
                        </ol>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <h5
                          style={{ marginBottom: "10px", textAlign: "center" }}
                        >
                          {t("Academic Difficulties")}:
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul className="ul-none">
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox1"
                              disabled
                              checked={currentExortationNote?.academic_difficulties?.includes(
                                "Inappropriate attitude"
                              )}
                            />
                            <label htmlFor="checkbox1">
                              {t("Inappropriate attitude")}
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox2"
                              disabled
                              checked={currentExortationNote?.academic_difficulties?.includes(
                                "Poor foundation"
                              )}
                            />
                            <label htmlFor="checkbox2">
                              {t("Poor foundation")}
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox3"
                              disabled
                              checked={currentExortationNote?.academic_difficulties?.includes(
                                "Lack of effort/Motivation"
                              )}
                            />
                            <label htmlFor="checkbox3">
                              {t("Lack of effort/Motivation")}
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox4"
                              disabled
                              checked={currentExortationNote?.academic_difficulties?.includes(
                                "Does not finish class assignments"
                              )}
                            />
                            <label htmlFor="checkbox4">
                              {t("Does not finish class assignments")}
                            </label>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <ul className="ul-none">
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox5"
                              disabled
                              checked={currentExortationNote?.academic_difficulties?.includes(
                                "Pending assignments"
                              )}
                            />
                            <label htmlFor="checkbox5">
                              {t("Pending assignments")}
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox6"
                              disabled
                              checked={currentExortationNote?.academic_difficulties?.includes(
                                "Low-quality work"
                              )}
                            />
                            <label htmlFor="checkbox6">
                              {t("Low-quality work")}
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox7"
                              disabled
                              checked={currentExortationNote?.academic_difficulties?.includes(
                                "Difficulty following instructions"
                              )}
                            />
                            <label htmlFor="checkbox7">
                              {t("Difficulty following instructions")}
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox8"
                              disabled
                              checked={currentExortationNote?.academic_difficulties?.includes(
                                "Low test grade"
                              )}
                            />
                            <label htmlFor="checkbox8">
                              {t("Low test grade")}
                            </label>
                          </li>
                        </ul>
                      </td>

                      <td className="tdTop">
                        <ul className="ul-none">
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox9"
                              disabled
                              checked={currentExortationNote?.academic_difficulties?.includes(
                                "Difficulty copying notes/tasks"
                              )}
                            />
                            <label htmlFor="checkbox9">
                              {t("Difficulty copying notes/tasks")}
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox10"
                              disabled
                              checked={currentExortationNote?.academic_difficulties?.includes(
                                "Does not bring materials/books to class"
                              )}
                            />
                            <label htmlFor="checkbox10">
                              {t("Does not bring materials/books to class")}
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox11"
                              disabled
                              checked={currentExortationNote?.academic_difficulties?.includes(
                                "Others"
                              )}
                            />
                            <label htmlFor="checkbox11">
                              {t("Other(s)")}:{" "}
                              {currentExortationNote?.academic_difficulties_other ? (
                                <span className="bold-text-black">
                                  {
                                    currentExortationNote.academic_difficulties_other
                                  }
                                </span>
                              ) : (
                                "_________________"
                              )}
                            </label>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr className="show-border-top">
                      <td colSpan={3}>
                        <h5
                          style={{ marginBottom: "10px", textAlign: "center" }}
                        >
                          {t("Behavioral Difficulties")}:
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td className="tdTop">
                        <ul className="ul-none">
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox9"
                              disabled
                              checked={currentExortationNote?.behavioral_difficulties?.includes(
                                "Talks excessively"
                              )}
                            />
                            <label htmlFor="checkbox9">
                              {t("Talks excessively")}
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox10"
                              disabled
                              checked={currentExortationNote?.behavioral_difficulties?.includes(
                                "Distracts/bothers classmates"
                              )}
                            />
                            <label htmlFor="checkbox10">
                              {t("Distracts/bothers classmates")}
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox11"
                              disabled
                              checked={currentExortationNote?.behavioral_difficulties?.includes(
                                "Lack of attention/cooperation"
                              )}
                            />
                            <label htmlFor="checkbox11">
                              {t("Lack of attention/cooperation")}
                            </label>
                          </li>
                        </ul>
                      </td>
                      <td className="tdTop">
                        <ul className="ul-none">
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox9"
                              disabled
                              checked={currentExortationNote?.behavioral_difficulties?.includes(
                                "Does not respond/ignores correction"
                              )}
                            />
                            <label htmlFor="checkbox9">
                              {t("Does not respond/ignores correction")}
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox10"
                              disabled
                              checked={currentExortationNote?.behavioral_difficulties?.includes(
                                "Disrespects authority"
                              )}
                            />
                            <label htmlFor="checkbox10">
                              {t("Disrespects authority")}
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox11"
                              disabled
                              checked={currentExortationNote?.behavioral_difficulties?.includes(
                                "Excessive absences"
                              )}
                            />
                            <label htmlFor="checkbox11">
                              {t("Excessive absences")}
                            </label>
                          </li>
                        </ul>
                      </td>
                      <td className="tdTop">
                        <ul className="ul-none">
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox9"
                              disabled
                              checked={currentExortationNote?.behavioral_difficulties?.includes(
                                "Excessive tardiness"
                              )}
                            />
                            <label htmlFor="checkbox9">
                              {t("Excessive tardiness")}
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox10"
                              disabled
                              checked={currentExortationNote?.behavioral_difficulties?.includes(
                                "Constantly interrupts"
                              )}
                            />
                            <label htmlFor="checkbox10">
                              {t("Constantly interrupts")}
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox11"
                              disabled
                              checked={currentExortationNote?.behavioral_difficulties?.includes(
                                "Others"
                              )}
                            />
                            <label htmlFor="checkbox11">
                              {t("Other(s)")}:{" "}
                              {currentExortationNote?.behavioral_difficulties_other ? (
                                <span className="bold-text-black">
                                  {
                                    currentExortationNote.behavioral_difficulties_other
                                  }
                                </span>
                              ) : (
                                "_________________"
                              )}
                            </label>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr className="show-border-top">
                      <td colSpan={3}>
                        <h5
                          style={{ marginBottom: "10px", textAlign: "center" }}
                        >
                          {t("Recommendations")}:
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td className="tdTop">
                        <ul className="ul-none">
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox9"
                              disabled
                              checked={currentExortationNote?.recommendations?.includes(
                                "Talk with the student"
                              )}
                            />
                            <label htmlFor="checkbox9">
                              {t("Talk with the student")}
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox10"
                              disabled
                              checked={currentExortationNote?.recommendations?.includes(
                                "Put in more effort"
                              )}
                            />
                            <label htmlFor="checkbox10">
                              {t("Put in more effort")}
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox11"
                              disabled
                              checked={currentExortationNote?.recommendations?.includes(
                                "Meeting with the counselor"
                              )}
                            />
                            <label htmlFor="checkbox11">
                              {t("Meeting with the counselor")}
                            </label>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <ul className="ul-none">
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox9"
                              disabled
                              checked={currentExortationNote?.recommendations?.includes(
                                "Meeting with the teacher"
                              )}
                            />
                            <label htmlFor="checkbox9">
                              {t("Meeting with the teacher")}
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox12"
                              disabled
                              checked={currentExortationNote?.recommendations?.includes(
                                "Meeting with the Principal"
                              )}
                            />
                            <label htmlFor="checkbox12">
                              {t("Meeting with the Principal")}
                            </label>
                          </li>
                        </ul>
                      </td>
                      <td className="tdTop">
                        <ul className="ul-none">
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox13"
                              disabled
                              checked={currentExortationNote?.recommendations?.includes(
                                "Afternoon assistance"
                              )}
                            />
                            <label htmlFor="checkbox13">
                              {t("Afternoon assistance")}
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="checkbox9"
                              disabled
                              checked={currentExortationNote?.recommendations?.includes(
                                "Tutoring"
                              )}
                            />
                            <label htmlFor="checkbox9">{t("Tutoring")}</label>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr className="show-border-top">
                      <td>
                        <hr
                          style={{
                            border: "none",
                            borderTop: "1px solid black",
                            marginTop: 25,
                          }}
                        />
                        <div style={{ textAlign: "center" }}>
                          {t("Signature of the father, mother, or guardian")}
                        </div>
                      </td>
                      <td> </td>
                      <td>
                        <hr
                          style={{
                            border: "none",
                            borderTop: "1px solid black",
                            marginTop: 25,
                          }}
                        />
                        <div style={{ textAlign: "center" }}>
                          {t("Signature of the teacher")}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ModalBody>
          </Modal>
        )
                    }

                    export default ExhortationNoteView
