import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { onReadData,
  handleStatusChange

} from "features/student/subject/assignment/assignmentListSlice"
import {  useNavigate, useOutletContext } from "react-router-dom"
import { Button } from "reactstrap"


import DataTableSimple from "components/Common/DataTableSimple/DataTableSimple"
import { handleStatusAssignment } from "helpers/table_helper"






const selectState = state => state.student?.activity?.assignmentList

const selectProperties = createSelector([selectState], state => ({
  isModal: state.isModal,
  mode: state.mode,
  isModalDelete: state.isModalDelete,
  searchTerm: state.searchTerm,
  pageNumber: state.pageNumber,
  isLoading: state.isLoading,
  dataTable: state.dataTable,
  status: state.status,
}))





const ActivitiesList = () => {
  const { t, subject, user } = useOutletContext()
  const State = useSelector(selectProperties)
  const { pageNumber, mode, searchTerm, isLoading, dataTable, status } = State
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(
      onReadData({
        subject,
        user,
        pageNumber: 1,
        searchTerm: searchTerm,
      })
    )
    //  if (
    //    assignmentState.searchTerm.length === 0 ||
    //    assignmentState.searchTerm.length > 2
    //  ) {

    //  }
  }, [dispatch, subject, searchTerm])

  const handleFilter = filter => {
    dispatch(handleStatusChange(filter))
  }
  const rowsWithActions = dataTable?.rows
    .filter(item => item.status === status)
    .map(item => ({
      ...item,
      options: (
        <>
          <div className="tooltip-container">
            <button
              className="icon-button"
              onClick={() =>
                navigate(`/subjects/${subject.id}/activities/${item.id}`)
              }
            >
              <i className="fas fa-edit"></i>
            </button>
            <span className="tooltip">{t("Edit")}</span>
          </div>

          {/* <div className="tooltip-container">
        <button
          className="icon-button danger-button ms-1"
          //onClick={() => handleDelete(item)}
        >
          <i className="fas fa-trash"></i>
        </button>
        <span className="tooltip"> {t("Delete")} </span>
      </div> */}
        </>
      ),
      status: handleStatusAssignment(item.status),
    }))

  return (
    <>
      <div className="btn-group d-flex" role="group" aria-label="Basic example">
        <Button
          color="danger"
          className="btn"
          onClick={() => handleFilter("0")}
        >
          {t("Pendings")}
        </Button>
        <Button
          color="warning"
          className="btn"
          onClick={() => handleFilter("1")}
        >
          {t("To evaluate")}
        </Button>
        <Button
          color="success"
          className="btn"
          onClick={() => handleFilter("2")}
        >
          {t("Evaluated")}
        </Button>
        <Button
          color="secondary"
          className="btn"
          onClick={() => handleFilter("3")}
        >
          {t("Expired")}
        </Button>
      </div>

      <DataTableSimple
        isLoading={isLoading}
        data={{ ...dataTable, rows: rowsWithActions }}
      />
    </>
  )
}

export default ActivitiesList