import { takeLatest, put, call, all } from "redux-saga/effects"

import { apiClient } from "api/axios"
import {
  onReadData,
  onReadDataSuccess,


} from "./pedagogicalRecoverySlice"
import { toast } from "react-toastify"
import { subjectEndpoints, } from "api/endpoints"




function* workerFetchPedagogicalRecovery({payload:{subject,periodId}}) {
    try {

        
        const response = yield call(apiClient.get, subjectEndpoints.pedagogical_recovery(subject.id,`period=${periodId}`))
        yield put(onReadDataSuccess(response.data))
     

    } catch (error) {
        
        toast.error(error.message)
    }
}

export function* watchPedagogicalRecovery() {
 
   yield takeLatest(onReadData.type, workerFetchPedagogicalRecovery)
  

}

export default watchPedagogicalRecovery
