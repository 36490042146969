import { takeLatest, put, call,all } from "redux-saga/effects"
import { onReadData, onReadDataSuccess } from "./subjectSlice"

import { apiClient } from "api/axios"
import { subjectEndpoints } from "api/endpoints"
import { generateOptions } from "helpers/select_helper"

function* fetchSubjectData(action) {
  try {
 
    const id = action.payload
    
 const [subject, students,periods] = yield all([
      call(apiClient.get, subjectEndpoints.read(id)),
      call(apiClient.get, subjectEndpoints.students(id)),
      call(apiClient.get, subjectEndpoints.periods(id))
    
    ])

const periodsOptions = generateOptions(
  periods?.data,
  "id",
  element => element.name,
  true
)

    yield put(onReadDataSuccess({subject:subject.data,students: students.data,periodsOptions : periodsOptions}))
  } catch (error) {
    console.log(error)
    // Handle errors, e.g., dispatch an error action
  }
}

export function* watchTeacherSubject() {
  yield takeLatest(onReadData.type, fetchSubjectData)
}

export default watchTeacherSubject
