import React from "react"
import { Button, Modal, ModalHeader, ModalBody ,Row,Col, FormGroup, Label,Input,ModalFooter } from "reactstrap"
import AssignmentForm from "./AssignmentForm/AssignmentForm"
import DataTable from "../DataTable"

//import SweetAlert from "react-bootstrap-sweetalert"

const Assignment = (props) => {
  
const {
  t,
  handlePageChange,
  handleSearchTerm,
  getRow,
  handleAssignmentCreateStart,
  handleCancelDelete,
  handleConfirmToDelete,
  handleCancel,
  assignmentState,
} = props



const {
  isModal,
  isLoading,
  dataTable,
  pagination,
  searchTerm,
  isModalDelete,
  mode,
} = assignmentState


  return (
    <>
      <Row>
        <Col>
          <FormGroup className="mb-3">
            <Input
              className="form-control"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={event => handleSearchTerm(event.target.value)}
            />
          </FormGroup>
        </Col>
        <Col>
          <Button
            type="button"
            color="success"
            className=" float-end waves-effect waves-light mb-2 mr-2"
            onClick={() => handleAssignmentCreateStart()}
          >
            <i className="mdi mdi-plus mr-1"></i>
            {t("Add homework")}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <DataTable
            data={dataTable}
            isLoading={isLoading}
            //customLoader={<TableSkeleton/>}
            paginationState={pagination}
            onPageChange={handlePageChange}
            getRow={getRow}
          />
        </Col>
      </Row>

      <Modal
        size={"xl"}
        isOpen={isModal}
        toggle={handleCancel}
        autoFocus={true}
        backdrop={"static"}
      >
        <ModalHeader toggle={handleCancel}> {t(mode)}</ModalHeader>
        <ModalBody>
          <AssignmentForm {...props} />
        </ModalBody>
      </Modal>

      <Modal
        size={"s"}
        isOpen={isModalDelete}
        toggle={handleCancelDelete}
        autoFocus={true}
        backdrop={"static"}
      >
        <ModalHeader toggle={handleCancelDelete}>{t("Delete")}</ModalHeader>
        <ModalBody>
          <p>
            {t(
              "Do you really want to delete these records? This process cannot be undone."
            )}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              handleConfirmToDelete()
            }}
          >
            {t("Delete")}
          </Button>
          <Button color="secondary" onClick={handleCancelDelete}>
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default Assignment
