import React from "react"
import Pagination from "./Pagination"
import TableBody from "./TableBody"
import TableHeader from "./TableHeader"

const DataTable = ({
  isLoading,
  customLoader,
  data,
  getRow,
  onSubmit,
  onChange,
  onPageChange,
  paginationState,
}) => {
  let tableContent

  if (isLoading) {
    tableContent = customLoader ? (
      customLoader
    ) : (
      <tr>
        <td colSpan={data?.columns.length} className="text-center">
          Loading...
        </td>
      </tr>
    )
  } else if (data && data.rows && data.rows.length > 0) {
    tableContent = (
      <TableBody
        columns={data?.columns}
        rows={data?.rows}
        onSubmit={onSubmit}
        onChange={onChange}
        getRow={getRow}
      />
    )
  } else {
    tableContent = (
      <tr>
        <td colSpan={data?.columns.length} className="text-center">
          No matching records found
        </td>
      </tr>
    )
  }

  return (
    <>
      <div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <TableHeader columns={data?.columns} />
              </tr>
            </thead>
            <tbody>{tableContent}</tbody>
          </table>
        </div>
        <Pagination
          paginationState={paginationState}
          onPageChange={onPageChange}
        />
      </div>
    </>
  )
}

export default DataTable
