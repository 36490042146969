import { createSlice } from "@reduxjs/toolkit"


const schoolGradeSlice = createSlice({
  name: "teacher/schoolGrade",
  initialState: {
    dataTable: null,
    filterby: "",
    isLoading: false,
    selectedRow: null,
    isEditing: false,
    isSaveConfirmationModal: false,
    unsavedData: null,
    visibleColumns: [],
    periodsOptions: [],
    period: null,
    isLoadingPeriods: false,
  },
  reducers: {
    onReadPeriod: state => {
      state.isLoadingPeriods = true
    },
    onReadPeriodSuccess: (state, action) => {
      state.periodsOptions = action.payload.periodsOptions
      state.isLoadingPeriods = false
    },
    setCurrentPeriod: (state, action) => {
      state.period = action.payload
      },
    onReadData: state => {
      state.isLoading = true
    },
    onReadDataSuccess: (state, action) => {
      state.dataTable = action.payload
      state.visibleColumns = action.payload.columns.map(column => column.id)
      state.isLoading = false
      state.isEditing = false
    },
    onFilterChange: (state, action) => {
      state.filterby = action.payload
    },
    onEditStart: (state, action) => {
      state.selectedRow = action.payload
    },
    onEdit: (state, action) => {
      state.isEditing = true
    },
    onSaveConfirmationModalStart: (state, action) => {
      state.unsavedData = action.payload
      state.isSaveConfirmationModal = true
    },
    onSaveConfirmationCancel: (state, action) => {
      ;(state.unsavedData = null), (state.isSaveConfirmationModal = false)
    },
    onToggleColumnVisibility: (state, action) => {
      const { columnId } = action.payload
      if (state.visibleColumns.includes(columnId)) {
        // Column is currently visible, hide it
        state.visibleColumns = state.visibleColumns.filter(
          id => id !== columnId
        )
      } else {
        // Column is currently hidden, show it
        state.visibleColumns = [...state.visibleColumns, columnId]
      }
    },
  },
})



export const {
  setCurrentPeriod,
  onReadPeriod,
  onReadPeriodSuccess,
  onReadData,
  onReadDataSuccess,

  onFilterChange,
  onEditStart,
  onEdit,
  onSaveConfirmationModalStart,
  onSaveConfirmationCancel,
  onToggleColumnVisibility,
} = schoolGradeSlice.actions
export default schoolGradeSlice.reducer



